import React from 'react';
import ActiveMenuMonitor from '../../../components/Nav/ActiveMenuMonitor/ActiveMenuMonitor';
import RegularPayments from '../../../containers/RegularPayments/RegularPayments';

const RegularPaymentsPage = () => {
    return (
        <div className='regular-payments-page-container'>
            <ActiveMenuMonitor menuId={5}/>
            <RegularPayments/>
        </div>  );
};

export default RegularPaymentsPage;