import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { dayAsStringShort, getShortDate } from '../../../store/actions/calendar';
import { getScheduleBundleItems } from '../../../store/lrnAPI/scheduleBundles';
import EventTool from '../../common/EventTool/EventTool';
import NewSchedule from '../../NewSchedule/NewSchedule';

const NewScheduleDetailsBundledItems = ({ scheduleId, setSchedulesFromBundle, setBundleLoadingFinished}) => {

    const dispatch = useDispatch();

    // const testData = [
    //     {
    //         Id: 1,
    //         BundleId: 1,
    //         BundleName: 'test',
    //         ScheduleId: 1,
    //         PurchaseMain: false,
    //         PurchaseSelected: true,
    //         PurchaseSelectDisabled: true,
    //     },
    //     {
    //         Id: 2,
    //         BundleId: 1,
    //         BundleName: 'test',
    //         ScheduleId: 2,
    //         PurchaseMain: false,
    //         PurchaseSelected: false,
    //         PurchaseSelectDisabled: false,
    //     },
    // ];

    const [bundledItems, setBundledItems] = useState([]);

    const loadData = () => {
        dispatch(getScheduleBundleItems(scheduleId))
            .then((r)=>{
                setBundledItems(r);
                setSchedulesFromBundle(r);
                setBundleLoadingFinished(true);
            });
        
    };

    const onBundleItemSelectChange = (val, id) => {
        const itemsCpy= [...bundledItems];
        const item = itemsCpy.filter(x => x.Id === id)[0];
        item.ScheduleBundles.PurchaseAutoSelect = val;
        setBundledItems(itemsCpy);
        setSchedulesFromBundle(itemsCpy);
    };

    const eventTool = (opDisabled, opSelected, scheduleId) => 
        <EventTool
            title={'Dodaj'}
            id={scheduleId}
            allowAction={!opDisabled}
            statusId={''}
            toggleChecked={opSelected}
            onChange={(val)=>onBundleItemSelectChange(val,scheduleId)}
        />;    
    

    const renderItems = () => {
        const list = [];	
        //console.log('props.items', props.items)
        bundledItems.map((item) =>
            list.push(
                <NewSchedule
                    id={'newScheduleItem-bundle-'+item.Id}
                    key={'newScheduleItem-bundle-'+item.Id}
                    name={item.EventName}
                    nameDetails={item.EventDetails}
                    time={item.EventHour}
                    dayName={dayAsStringShort(item.DayId)}
                    room=''
                    desc={item.Description}
                    levelId={item.LevelId}
                    level={item.Level}					
                    startDate={getShortDate(item.StartDate)}					
                    statusId={item.StatusId}
                    teachers={item.Teacher1 + ' & '+ item.Teacher2}										
                    bgColorClass='new-event-bg-light'
                    actionTool={eventTool(item.ScheduleBundles.PurchaseSelectDisabled,item.ScheduleBundles.PurchaseAutoSelect,item.Id)}                    
                ></NewSchedule>
            )
        );
        return list;    
    };


    useEffect(() => {
        loadData();
    }, []);
    


    return (<div className='newSchedule-details-bundleItems-container'>
        {bundledItems.length>0 ?
            <>
                <div className='newSchedule-details-bundleItems-title app-text-title'>
            Powiązane zajęcia
                </div>
                <div className='newSchedule-details-bundleItems'>
                    {renderItems()}
                </div>
            </>
            : null
        }

    </div>);
};

export default NewScheduleDetailsBundledItems;
