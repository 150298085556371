import { logEvent } from 'firebase/analytics';
import { acceptMailing, acceptPrivacyPolicy, acceptTermsOfUse, getMailingAgreement, getPrivacyPolicy, getTermsOfUse } from '../../utils/agreements';
import { login, sendRequest } from '../actions/lrnAPI';
import { setError, setWarning, showProgressBar } from '../actions/ui';
import { analytics } from '../../firebase/Firebase';
import { updateFirebasePassword } from '../actions/auth';

export const registerNewUser = ({ Password, ...newUserData }) => {
    return (dispatch, getState) => {
        //save token to storage
        if (!newUserData) {
            dispatch(setError({ message: 'Missing customer data' }));
            return false;
        }
        dispatch(showProgressBar(true));
        const state = getState();
        sendRequest('Auth/register', newUserData, '', 'post')
            .then((response) => {
                switch (response.status) {
                case 200:
                    dispatch(login(newUserData.Token)).then((customerData) => {

                        const userId = customerData.id;
                        const apiConfigId = state.firebaseStore.selectedAPIConfigId;

                        if (userId) {
                            getPrivacyPolicy(apiConfigId).then(({ id }) => {
                                acceptPrivacyPolicy(userId, id, apiConfigId);
                            });

                            getTermsOfUse(apiConfigId).then(({ id }) => {
                                acceptTermsOfUse(userId, id, apiConfigId);
                            });

                            getMailingAgreement(apiConfigId).then(({ id }) => {
                                acceptMailing(userId, id, apiConfigId);
                            });
                            if(Password)
                                dispatch(updateFirebasePassword(Password));
                        }

                    });

                    logEvent(analytics, 'sign_up', { method: 'backend_user_success' });
                    break;
                case 208:
                    dispatch(setWarning(response.data.message));
                    logEvent(analytics, 'sign_up', {
                        method: 'backend_user_manual',
                    });
                    break;
                default:
                    dispatch(setError(response));
                    break;
                }
                dispatch(showProgressBar(false));
            })
            .catch((err) => {
                console.log('err', err);
                switch (err.status) {
                default:
                    dispatch(setError(err));
                    break;
                }
                dispatch(showProgressBar(false));
            });
    };
};