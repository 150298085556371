import React, { Fragment, useEffect } from 'react';
import CardDetailsWithPic from '../../UI/CardDetailsWithPic/CardDetailsWithPic';

import './AccountDetailsCard.css';
import Barcode from 'react-barcode';
import { useSelector, useDispatch } from 'react-redux';
import { getCustomerDetails } from '../../../store/actions/lrnAPI';

const AccountDetailsCard = () => {
    const dispatch = useDispatch();

    const userId = useSelector((store) => store.customerStore.customerData.id);
    const apiToken = useSelector((store) => store.uiStore.apiToken);
    const custDetails = useSelector(
        (state) => state.customerStore.customerDetails
    );
    const userName = custDetails.FirstName + ' ' + custDetails.LastName;

    const loadData = () => {
        dispatch(getCustomerDetails(userId, apiToken));
    };

    /*  eslint-disable */
	useEffect(() => {
		loadData();
		return () => {};
	}, []);

	return (
		<Fragment>
			<div className='account-card'>
				<CardDetailsWithPic
					textLine1={custDetails.Id ? userName : ""}
					textLine2={custDetails.Id ?
						<div>
							Kaucja: {custDetails.Deposit <0 ? 0: custDetails.Deposit} zł
							<div>Do odrobienia: {custDetails.BlockedDeposit*-1} zł</div>
						</div>
						: "Brak danych - odśwież aplikację"
					}					
					barcode={
						<Barcode
							format='CODE39'
							value={custDetails?.Id?.toString()}
							displayValue={false}
							text-align='center'
							height={35}></Barcode>
					}></CardDetailsWithPic>
			</div>
		</Fragment>
	);
};

export default AccountDetailsCard;
