import React from 'react';

import './DataLabel.css';

const DataLabel = (props) => {
    return (
        <div className='data-label-container'>
            <div className='data-label-text'>            
                {props.text}
            </div>            
        </div>
    );
};

export default DataLabel;
