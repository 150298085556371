import React, { useState } from 'react';
import CategoryBar from './CategoryBar/CategoryBar';
import { useLocation } from 'react-router-dom';
import Items from './Items/Items';
import './CategoryItemsList.css';

const getInitialCategory = (focusedItemId, categories, items, itemsGroupBy, itemsToCatLinkKey) => {
    const focusedItem = items.find(({ Id }) => Id === focusedItemId);

    if (!focusedItemId || !focusedItem) {
        return -1;
    }

    const focusedCategory = categories.find((category) => category[itemsToCatLinkKey] === focusedItem[itemsGroupBy]);

    return focusedCategory.id ?? -1;
};

const CategoryItemsList = (props) => {
    const { state } = useLocation();
    const [selectedCatId, setselectedCatId] = useState(
        getInitialCategory(state?.focusedItemId, props.categories, props.items, props.itemsGroupBy, props.itemsToCatLinkKey),
    );

    const onCatClick = (catId) => {
        if (catId === selectedCatId) {
            setselectedCatId(-1);
        } else {
            setselectedCatId(catId);
        }
    };

    const getFilteredItems = (cat) => {
		
        var catEl = props.categories.filter((x) => x.id === cat.id)[0];
        if (catEl.id === selectedCatId) {
            //var filteredList = props.items.filter((x) => x.Day === catName);
            var allItems = props.items;
            var filteredList = [];
            allItems.forEach((element) => {
                Object.keys(element).forEach((c) => {
                    if (c === props.itemsGroupBy) {
                        // //console.log('element[c]', element[c])
                        if (element[c] === cat[props.itemsToCatLinkKey]) filteredList.push(element);
                    }
                });
            });

            return filteredList;
        } else {
            return [];
        }
    };

    const renderCat = () => {
        const cats = [];
        if (props.items && props.items.length > 0) {
            props.categories.forEach((cat) => {
                cats.push(
                    <CategoryBar
                        key={cat.id}
                        id={`category-${cat.id}`}
                        className='catlist-list-bar'
                        isSelectedCat={
                            props.categories.filter((x) => x.id === cat.id).id ===
							selectedCatId
                                ? true
                                : false
                        }
                        text={cat.name}
                        onClick={() => onCatClick(cat.id)}>
                        <Items
                            id={cat.id}
                            listType={props.listType}
                            items={getFilteredItems(cat)}
                            onClick={(id) => props.onItemClick(id)}
                        />
                    </CategoryBar>
                );
            });
        }
        return cats;
    };

    return <div className='catlist-list-container'>{renderCat()}</div>;
};

export default CategoryItemsList;
