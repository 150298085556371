
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const useNewScheduleAutoSignup = (rec,setAutoSelectedPaymentId,setAutoSelectedPriceTypeId,createNewSchedule,bundleLoadingDone) => {
  
    const [searchParams] = useSearchParams();
    const [autoCreate] = useState(searchParams.get('autoCreate'));
    const [paymentTypeId] = useState(searchParams.get('paymentTypeId'));
    const [priceTypeId] = useState(searchParams.get('priceTypeId'));

    useEffect(() => {
        
        if(rec && autoCreate && paymentTypeId && bundleLoadingDone)
        {
            setAutoSelectedPaymentId(paymentTypeId);
            setAutoSelectedPriceTypeId(priceTypeId);
            createNewSchedule(parseInt(paymentTypeId),isNaN(parseInt(priceTypeId))?0:parseInt(priceTypeId));
        }
    
    }, [rec,bundleLoadingDone]);
    


    return {result:null,autoCreate};
};

export default useNewScheduleAutoSignup;