import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setError } from '../../store/actions/ui';
import { getRegularPayments, removePayment } from '../../store/lrnAPI/salesPayments';
import {getShortDate} from '../../utils/helpers/dateTimeHelper';
import './RegularPayments.css';

import ListElementContainer from '../../components/UI/ListElementContainer/ListElementContainer';
import ButtonWithSpinner from '../../components/UI/ButtonWithSpinner/ButtonWithSpinner';
import PayUCardForm from '../../components/PayUCardForm/PayUCardForm';
import ConfirmButton from '../../components/UI/ConfirmButton/ConfirmButton';

const RegularPayments = () => {
    
    const dispatch = useDispatch();
    
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [authActionClicked, setAuthActionClicked] = useState(false);
    const [showPayUFromCVV, setShowPayUFromCVV] = useState(false);
    const [paymentUrlForCVV, setPaymentUrlForCVV] = useState();
    const [cvvFormId, setCvvFormId] = useState(0);
    const [cancelClicked, setCancelClicked] = useState(false);

    const loadData = () => { 
        setLoading(true);
        setShowPayUFromCVV(false);
        
        dispatch(getRegularPayments())
            .then((r)=>{
                setList(r.data);
                setLoading(false);
                setAuthActionClicked(false);
            })
            .catch((e)=>{
                dispatch(setError(e));
                setLoading(false);
                setAuthActionClicked(false);
            });    
    };

    const onAuth3DSActionClick = (url) => { 
        setAuthActionClicked(true);
        if(url && url !== '')
            window.open(url, '_self');    
    };

    const onAuthCVVActionClick = (url, id) => { 
        setAuthActionClicked(true);
        setShowPayUFromCVV(true);
        setPaymentUrlForCVV(url);
        setShowPayUFromCVV(true);  
        setCvvFormId(id);
    };

    const getPayUActionBtn = (item) => { 
        switch (item.LastPaymentStatus) {
        case 'WARNING_CONTINUE_3DS':
            return <ButtonWithSpinner
                id={'regularPayments-item-btn-3ds-'+ item.Id}
                inProgress={authActionClicked}
                show={true}
                disabled={false}
                className='second'
                onClick={()=>onAuth3DSActionClick(item.PaymentURL)}
            >
            Wymagana autoryzacja
            </ButtonWithSpinner>;
            
        case 'WARNING_CONTINUE_CVV':
            return <ButtonWithSpinner
                id={'regularPayments-item-btn-cvv-'+ item.Id}
                inProgress={authActionClicked}
                show={true}
                disabled={false}
                className='second'
                onClick={()=>onAuthCVVActionClick(item.PaymentURL, item.Id)}
            >
        Wymagany kod CVV
            </ButtonWithSpinner>;
            
        default:
            break;
        } 
    };

    const getPayUForm = () => { 
        return <div className='regularPayments-cvv-modal'>                
            <PayUCardForm 
                onSuccessCallback={loadData}
                onFailureCallback={loadData}
                cvvOnly={true} 
                redirectURL={paymentUrlForCVV}/>
        </div>;

    };

    const onCancelClick = (id) => { 
        setLoading(true);
        setCancelClicked(false);
        dispatch(removePayment(id))
            .then(()=>{
                loadData();
                setLoading(false);
            })
            .catch((err)=>{
                console.log('err', err);
                dispatch(setError(err));
            });
    };

    const renderList = () => { 
        const res = [];
        console.log('list', list);
        if(list && list.length > 0)
        {
            list.map((item)=>{
                console.log('item', item);
                res.push(<ListElementContainer id={'regularPayments-item-'+ item.Id}
                    key={'regularPayments-item-'+ item.Id}
                >
                    <div 
                        className='regularPayments-list-item'>
                        <div className='regularPayments-list-item-desc regularPayments-list-item-border '>
                            {item.ScheduleName}
                        </div>
                        <div className='regularPayments-list-item-amount regularPayments-list-item-border'>
                            <b>{item.Amount} PLN</b>
                        </div>
                        <div className='regularPayments-list-item-date '>
                            { item.LastPaymentStatus === 'SUCCESS' ?
                                'Następna płatność: ' + getShortDate(item.NextPaymentDate)
                                : (showPayUFromCVV && cvvFormId === item.Id 
                                    ? getPayUForm() 
                                    : getPayUActionBtn(item) 
                                )}
                        </div>
                        <div className='regularPayments-list-item-actions '>
                            {cancelClicked ?
                                <ConfirmButton 
                                    onConfirm={()=>onCancelClick(item.Id)}
                                    onCancel={()=>setCancelClicked(false)} />
                                :
                                <ButtonWithSpinner
                                    id={'regularPayments-item-btn-cancel-'+ item.Id}
                                    className='btn btn-danger'
                                    inProgress={loading}
                                    show={true}
                                    onClick={()=>setCancelClicked(true)}
                                >
                                Anuluj
                                </ButtonWithSpinner>
                            }
                        </div>                        
                    </div>                    
                </ListElementContainer>);
            });
            return res;
        }
        else
        {
            return false;
        }

    };

    useEffect(() => {
        loadData();
    }, []);
    

    return (
        <div className='regularPayments-container'>
            <div className='regularPayments-title app-text-lg '>
                Lista automatycznych płatności
            </div>
            <div className='regularPayments-list'>
                {loading ? 
                    <Spinner
                        id={'regularPayments-spinner'}
                        animation="border"
                        role="status"
                    />
                    : null
                }
                {renderList()}    
            </div>
            <div className='regularPayments-sub app-text-sm '>
                Uwaga! Aktualizacja statusu płatności może zająć do kilkunastu minut. 
            </div>
        </div>
    );
};

export default RegularPayments;