import React from 'react';
import ActiveMenuMonitor from '../../components/Nav/ActiveMenuMonitor/ActiveMenuMonitor';

const PaymentsPage = () => {
    return (
        <div>
            <ActiveMenuMonitor menuId={5}/>
        </div>  );
};

export default PaymentsPage;