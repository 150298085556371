import React, { useEffect, useState } from 'react';
import LoadingBox from '../LoadingBox/LoadingBox';
import './CardInforGraphic.css';

const CardInforGraphic = (props) => {
    const [loaded, setloaded] = useState(false);
    const [url, seturl] = useState();

    const wrapWithLink = (link, img) => {
        let res;
        if (link) {
            res = (
                <a href={link} target='_blank' rel='noreferrer' >
                    {img}
                </a>
            );
        } else {
            res = img;
        }
        return (
            <div className=''>
                {res}
            </div>
        );
    };

    const getUrl = () =>{       
        props.picLoadingFun(props.filePath)
            .then((url)=>{
            
                setloaded(true);
                seturl(url);
            })
            .catch(()=>{
            });
    };

    /* eslint-disable */
    useEffect(() => {
        getUrl()
        return () => {            
        }
    }, [])

    const getElement = () =>{
        let gmsg = <img src={url} alt={props.title} />;
        let linked = wrapWithLink(props.link, gmsg);
        return linked;        
    }
    return (
        <div className='CardInforGraphic-container'>
            {loaded ? getElement():
            <LoadingBox/>}
        </div>
    )
}

export default CardInforGraphic
