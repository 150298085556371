import React, { useEffect } from 'react';
import Barcode from 'react-barcode';
import { useSelector, useDispatch } from 'react-redux';
import './MyNextEventCard.css';
import CardPriceTag from '../../UI/CardPriceTag/CardPriceTag';
import {
    dayAsStringShort,
    getShortDate,
} from '../../../store/actions/calendar';
import { getCustomerEvents } from '../../../store/lrnAPI/custEvents';
import StatusPill from '../../UI/StatusPill/StatusPill';


const MyNextEventCard = () => {
    const dispatch = useDispatch();

    const events = useSelector((state) => state.eventsStore.events);
    const myNextEvent = events.sort((x) => x.NewEventDate)[0];
    const userId = useSelector((store) => store.customerStore.customerData.id);
    const apiToken = useSelector((store) => store.uiStore.apiToken);

    const loadData = () => {
        dispatch(getCustomerEvents(userId, new Date(), apiToken, 1));
    };

    /* eslint-disable */
	useEffect(() => {
		loadData();
		return () => {};
	}, []);

	const statusPill = () => {
		return <StatusPill statusId={myNextEvent.StatusId} itemType='event' />;
	};

	const showMyNextEvent = () => {
		if (myNextEvent) {
			return (
				<CardPriceTag
					colorClass='text-light app-bg-color app-card'
					textLine1={
						dayAsStringShort(myNextEvent.NewEventDate) +
						' ' +
						myNextEvent.EventHour +
						' ' +
						myNextEvent.EventName +
						' ' +
						myNextEvent.Level
					}
					textLine2={myNextEvent.Room + ' ' + myNextEvent.InstructorsNames}
					textLine3={getShortDate(myNextEvent.NewEventDate)}
					barCode={
						<Barcode
							value={myNextEvent.BarCode}
							displayValue={true}
							text-align='center'
							format='CODE128'
							width={1}
							height={50}></Barcode>
					}
					mid={statusPill()}></CardPriceTag>
			);
		}
	};

	return <div onClick={loadData}>{showMyNextEvent()}</div>;
};

export default MyNextEventCard;
