import { isProd } from '../../utils/environment';

const usePayUJsSdk = (callback) => {

    const getScriptUrl = () => { 
        const sandBox = 'https://secure.snd.payu.com/javascript/sdk';
        const prod = 'https://secure.payu.com/javascript/sdk';
        
        
        if (isProd())
            return prod;
        else
            return sandBox;
    };
    
    const existingScript = document.getElementById('payUJsSdk');
    if (!existingScript) {
        const script = document.createElement('script');
        script.src = getScriptUrl();
        script.id = 'payUJsSdk';
        document.body.appendChild(script);
        script.onload = () => { 
            if (callback) callback();
        };
    }
    if (existingScript && callback) callback(); 
};

export default usePayUJsSdk;