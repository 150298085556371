import React, { useEffect, useState } from 'react';
import './PayUCardForm.css';
import { useDispatch } from 'react-redux';
import ButtonWithSpinner from '../UI/ButtonWithSpinner/ButtonWithSpinner';
import PayURegInfo from '../PayURegInfo/PayURegInfo';
import loadPayUJsSdk from './loadPayUJsSdk';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { setError, setMessage } from '../../store/actions/ui';

import { PAYU_POSID } from '../../firebase/remoteConfig/remoteConfigParams';
import { isProd } from '../../utils/environment';
import { remoteConfigReader_getNumber } from '../../store/actions/firebase';

const PayUCardForm = ({onSuccessCallback, cvvOnly, redirectURL, onFailureCallback}) => {    
    const dispatch = useDispatch();
    const fpPromise = FingerprintJS.load();
    
    const [payUPosId] = useState(
        dispatch(remoteConfigReader_getNumber(PAYU_POSID))
    );

    const [jsSdkLoaded, setJsSdkLoaded] = useState(false);
    const [payBtnClicked, setPayBtnClicked] = useState(false);
    const [cardInputError, setCardInputError] = useState();

    var optionsForms = {
        style: {
            basic: {
                fontSize: '18px'
            }
        },
        lang: 'pl'
    };
    
    var renderError = function(errors) {
        
        console.log('errors', errors);
        // element.className = 'response-error';
        var messages = [];
        errors.forEach(function(error) {
            messages.push(error.message);
        });
        const msg = messages.join(', ');
        // element.innerText = messages.join(', ');
        setCardInputError(msg);
        setPayBtnClicked(false);
    };
    
    var renderSuccess = function(token) {
        // element.className = 'response-success';
        // element.innerText = msg;
        setCardInputError('');
        getDeviceFingerprint()
            .then((res)=>{
                onSuccessCallback?.(token, res);
            });
    };

    const getPayUSdk = () => { 
        //inicjalizacja SDK poprzez podanie POS ID oraz utworzenie obiektu secureForms 
        console.log('jsSdkLoaded', jsSdkLoaded);           
        var opt = {dev:!isProd()};
        console.log('opt', opt);
        var payuSdk = window['PayU'](payUPosId,opt);
        return payuSdk;
    };

    const renderPayUForm = (payuSdk, cvvOnly) => { 
        var secureForms = payuSdk.secureForms();    
        
        //utworzenie formularza podając typ oraz opcje
        // var card = secureForms.add('card', optionsForms);
        //create the forms by providing type and options
        if(!cvvOnly)
        {
            var cardNumber = secureForms.add('number', optionsForms);
            cardNumber.render('#payu-card-number');
        }
        if(!cvvOnly)
        {
            var cardDate = secureForms.add('date', optionsForms);
            cardDate.render('#payu-card-date');
        }

        var cardCvv = secureForms.add('cvv', optionsForms);
        cardCvv.render('#payu-card-cvv');
    
        //renderowanie formularza
        // card.render('#payu-card');
    };
    
    const createForm = (showCvvOnly) => { 
        const payuSdk = getPayUSdk();
        console.log('showCvvOnly', showCvvOnly);
        renderPayUForm(payuSdk, showCvvOnly);
        
        var tokenizeButton = document.getElementById('tokenizeButton');
        // var responseElement = document.getElementById('responseTokenize');
    
        if(!showCvvOnly)
        {
            //get token            
            tokenizeButton.addEventListener('click', function() {
                // responseElement.innerText = '';
                setPayBtnClicked(true);
                
                try {
                    payuSdk.tokenize('MULTI').then(function(result) { // przykład dla tokenu typu SINGLE
                        result.status === 'SUCCESS'
                            ? renderSuccess(result.body.token) //tutaj wstaw przekazanie tokena do back-endu
                            : renderError(result.error.messages); //sprawdź typ błędu oraz komunikaty i wyświetl odpowiednią informację użytkownikowi
                    });
                } catch(e) {
                    console.log(e); // błędy techniczne
                }
            });
        }
        else{
            //send cvv            
            tokenizeButton.addEventListener('click', function() {
                // responseElement.innerText = '';
                setPayBtnClicked(true);
                if(!redirectURL)
                    dispatch(setError({message:'Redirect URL is empty.'}));
                    
                try {
                    const refReqId = payuSdk.extractRefReqId(redirectURL);
                    console.log('refReqId', refReqId);
                    payuSdk.sendCvv(refReqId)
                        .then(function(result) {
                            console.log('result', result);
                            if(result.status === 'SUCCESS')
                            {
                                dispatch(setMessage(
                                    {message:'Operacja powiodła się, poczekaj na aktualizację statusu płatności.'}
                                )
                                );
                                onSuccessCallback();
                                
                            }
                            else
                            {
                                const errMsg = result.error.messages[0].message;
                                console.log('errMsg', errMsg);
                                dispatch(setError({message: errMsg}));
                                onFailureCallback();
                            }
                        })
                        .catch((err)=>{
                            console.log('err', err);                            
                            dispatch(setError({message: 'Błąd podczas przetwarzania polecenia PayU CVV.'}));
                        });
                } catch(e) {
                    console.log(e); // błędy techniczne
                }
            });
        }
    };


    const getDeviceFingerprint = async () => { 
        // Get the visitor identifier when you need it.
        const fp = await fpPromise;
        const result = await fp.get();
        console.log(result.visitorId);
        return result.visitorId;
    };

    useEffect(() => {
        loadPayUJsSdk(() => {
            setJsSdkLoaded(true);
        });
    });

    useEffect(() => {
        if(payUPosId && jsSdkLoaded)
            createForm(cvvOnly);    
    }, [payUPosId, jsSdkLoaded]);
    
    return (
        <>
            {jsSdkLoaded ?
                <div className="usePayUCardForm-container">
                    <div className="usePayUCardForm-card-container">
                        {!cvvOnly ?
                            <>
                                <aside>Numer karty</aside>
                                <div className="payu-card-form" id="payu-card-number"></div>
                            </>
                            : null
                        }
     
                        <div className="card-details clearfix">
                            {!cvvOnly ?

                                <div className="expiration">
                                    <aside>Data ważności</aside>
                                    <div className="payu-card-form" id="payu-card-date"></div>
                                </div>
                                :null    
                            }
     
                            <div className="cvv">
                                <aside>CVV</aside>
                                <div className="payu-card-form" id="payu-card-cvv"></div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="usePayUCardForm-card-error response-error">
                        {cardInputError}
                    </div>
                    <ButtonWithSpinner
                        id="tokenizeButton" 
                        className='main'
                        inProgress={payBtnClicked}
                        disabled={payBtnClicked}
                        show={true}
                    >
                        Zapłać
                    </ButtonWithSpinner>
                </div>
                : null
            }
            <div>
                <PayURegInfo/>
            </div>
        </>
    );
    
};

export default PayUCardForm;