import React from 'react';
import './CardDetailsWithPic.css';

const CardDetailsWithPic = (props) => {
    return (
        <div className='card-details-pic-container'>
            <div className='card-details-pic-text card-details-pic-text-one '>
                {props.textLine1}
            </div>
            <div className='card-details-pic-text card-details-pic-text-two'>
                {props.textLine2}
            </div>
            <div className='card-details-pic-text card-details-pic-text-three'>
                {props.barcode}
            </div>
			
        </div>
    );
};

export default CardDetailsWithPic;
