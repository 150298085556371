import React, { useState } from 'react';
import arrow from './SocialScoreResources/arrow.svg';

import './SocialScore.css';
import { setInfo } from '../../store/actions/ui';
import { useDispatch } from 'react-redux';

const SocialScore = ({ open, scores, label, sentByCount }) => {
    const [isExpanded, setIsExpanded] = useState(open);
    const dispatch = useDispatch();
    const onToggle = () => {
        setIsExpanded((val) => !val);
    };

    const onSocialScoreContentClick = () => {
        dispatch(
            setInfo('Podgląd kto wysłał Ci punkty dostępny w wersji premium.')
        );
    };

    return (
        <div className='social-score'>
            <div className='social-score-row' onClick={onToggle}>
                {scores.toFixed(0) > 0 ? (
                    <div className={`social-score-arrow ${isExpanded ? 'expanded' : ''}`}>
                        <img src={arrow} alt='toggle' />
                    </div>
                ) : null}
                <div className='social-score-label'>{label}</div>
                <div className='social-score-scores'>
                    <span className='social-score-scores-points'>
                        <strong>{scores.toFixed(0)}</strong> pkt.
                    </span>
                    <span className='social-score-scores-marks'>{sentByCount} osoby</span>
                </div>
            </div>
            {isExpanded && (
                <div
                    className='social-score-content'
                    onClick={onSocialScoreContentClick}>
                    <div className='social-score-content-row'>
                        <div className='social-score-content-name'>Johnathan Johnson</div>
                        <span className='social-score-scores-points'>
                            <strong>{(5).toFixed(0)}</strong> pts
                        </span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SocialScore;
