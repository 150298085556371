import React, { useEffect, useState } from 'react';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../../../firebase/Firebase';


import './BuyEventButton.css';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';


import CreateCCSEMobileAppReq from '../../../../models/requests/CreateCCSEMobileAppReq';
import { createCustContractsSalesEntry } from '../../../../store/lrnAPI/custContractsSalesEntries';

import ConfirmModal from '../../../UI/ConfirmModal/ConfirmModal';
import Modal from '../../../UI/Modal/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListUl,  faShoppingBasket } from '@fortawesome/free-solid-svg-icons';
import PurchaseOptionDetails from './PurchaseOptionDetails/PurchaseOptionDetails';
import { isDateAfterDateOnly } from '../../../../utils/helpers/dateTimeHelper';
import { getItemFromCustSignup, LOG_EVENT_ITEM_LIST_IDS_NEW_CLASSES_PURCHASED  } from '../../../../analytics/events';
import PayUCardForm from '../../../PayUCardForm/PayUCardForm';




const BuyEventButton = ({ id, 
    children, 
    event, 
    purchase, 
    disabled, 
    reloadFunction, 
    monthlyPayments,
    selectedPurchaseOptionId,
    setSelectedPurchaseOptionId    
}) => {
    const dispatch = useDispatch();
    const [btnInProgress, setBtnInProgress] = useState(false);
    const custId = useSelector((store) => store.customerStore.customerData.id);
    const [purchaseConfirmed, setPurchaseConfirmed] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showCharges, setShowCharges] = useState(false);
    const [btnDisabled] = useState(false);
    const [showPayUCardForm, setShowPayUCardForm] = useState(false);

    const onCancel = () => { 
        setPurchaseConfirmed(false); 
        setShowConfirmation(false);
    };
     
    const onConfirm = () => { 
        setPurchaseConfirmed(true);        
        setShowConfirmation(false);
        setSelectedPurchaseOptionId(id);
    };

    const logPurchase = () => logEvent(analytics, 'purchase',{
        currency:'PLN',        
        items: [
            getItemFromCustSignup(event, 
                purchase.AdditinalDiscountId, 
                LOG_EVENT_ITEM_LIST_IDS_NEW_CLASSES_PURCHASED,
                purchase.Price, 
                purchase.Quantity)
        ]
    });

    const onBuy = (purchaseConfirmedOverride, purchaseOptionId) => {      
        if(purchaseConfirmed || purchaseConfirmedOverride)
        {
            if(purchaseOptionId)
            {
                setSelectedPurchaseOptionId(purchaseOptionId);
            }              
            if(!monthlyPayments)
            {
                setBtnInProgress(true);
                var req = new CreateCCSEMobileAppReq(custId, event, purchase);        
                dispatch(createCustContractsSalesEntry(custId, req))
                    .then((resp) => {
                        const url = resp.RedirectUri;
                        logPurchase();
                        if(url && url !== '')
                            window.open(url, '_self');
                        else
                            reloadFunction();
                    })
                    .catch(() => {
                        setBtnInProgress(false);
                    });
            }
            else{
                console.log('show payu from');
                setShowPayUCardForm(true);
            }
        }
    };

    const processPaymentByToken = (token, fingerprint) => { 
        setBtnInProgress(true);
        const currentUrl = window.location.href;
        var req = new CreateCCSEMobileAppReq(custId, event, purchase, token, fingerprint, currentUrl, true);

        dispatch(createCustContractsSalesEntry(custId, req))
            .then((resp) => {
                logPurchase();
                if(resp && resp.Status.StatusCode === 'WARNING_CONTINUE_3DS')
                {
                    setShowPayUCardForm(false);
                    setBtnInProgress(false);                    
                    if(resp.RedirectUri && resp.RedirectUri !== '')
                        window.open(resp.RedirectUri, '_self');
                    else
                        reloadFunction();
                }
                else
                {
                    setShowPayUCardForm(false);
                    setBtnInProgress(false);
                    reloadFunction();
                }
            })
            .catch(() => {
                setBtnInProgress(false);
            });
    };

    const chargesToSettleExists = () => { 
        return purchase.ChargesToSettle && purchase.ChargesToSettle.length > 0;
    };

    const noAvailablePass = () => { 
        if((event.NoOfEntriesOnPassLeft === 0 
            || isDateAfterDateOnly(new Date(),event.ValidTill))
            //deposit
            || !event.PaymentTypeRequiresQuant
        )
            return true;
        else
            return false;
    };

    const buttonContent = () => { 
        return <div className='buyEventButton-btn-content-container' >
            
            <div className='buyEventButton-btn-content-left' >
                   
            </div>
            <div className='buyEventButton-btn-content-center' >
                {children}
            </div>
            <div className='buyEventButton-btn-content-right' onClick={()=>setShowCharges(!showCharges)}>
                {chargesToSettleExists()
                    ?
                    <div className='buyEventButton-btn-content-left-icon'> 
                        <FontAwesomeIcon icon={faListUl} size='1x'  />
                        
                    </div>
                    : 
                    noAvailablePass() ? 
                        <div className='buyEventButton-btn-content-left-icon'>
                            <FontAwesomeIcon icon={faShoppingBasket} size='1x' /> 
                                ZAKUP
                        </div>
                        : null}
            
            </div>           
        </div>;
    };

    const renderCharges = () => { 
        let list = [];
        purchase.ChargesToSettle.forEach(c => {
            let cid = 'butEventCharges-'+c.Id;
            list.push(<PurchaseOptionDetails id={cid} key={cid} 
                chargeId={c.Id}
                purchaseOptionId={id}
                amount={c.Amount} 
                entryDate={c.EntryDate}
                payments={c.Payments}
                amountLeft={c.AmountLeft} 
                custContractsEntryId={c.CustContractsEntryId??event.Id}
                custContractsSalesEntryId={c.CustContractsSalesEntryId}
                scheduleId={c.ScheduleId}
                monthlyPayments={monthlyPayments}
                onBuy={onBuy}
            />
            );
        });
        return list;
    };


    const onClick = () => { 
        if(purchase.OpenPaymentExists)
        {
            return setShowCharges(!showCharges);
        }
        else{
            if(!disabled && noAvailablePass())
                return setShowConfirmation(true);
        }
    };

    useEffect(() => {
        onBuy();
    }, [purchaseConfirmed]);

    

    return (    
        <div className='buyEventButton-container' >            
            <Modal show={showConfirmation} onModalClosed={()=>setShowConfirmation(false)} >
                <ConfirmModal onConfirm={onConfirm} onCancel={onCancel} 
                    title='Potwierdzenie zakupu'
                    message={'Czy dokonać zakupu ' + children + ' i rozpocząć płatność?' }
                >
                    {monthlyPayments ? 
                        <div className='buyEventButton-important'>
                            Wyrażam zgodę na okresowe obciążenia mojej karty płatniczej.
                            Zlecenie wykonuje PayU S.A. 
                        </div>: null}
                </ConfirmModal>
            </Modal>             
            <div id={id} key={id} 
                className={'buyEventButton-container-btn btn ' + ((btnDisabled||disabled)?'disabled':'') + ' ' + (!noAvailablePass()?' payed':'') } 
                onClick={onClick}
            >
                {btnInProgress ?
                    <Spinner animation='border' role='status'  />
                    : buttonContent()}
            </div>

            {showPayUCardForm && selectedPurchaseOptionId == id ?
                <div className='buyEventButton-cardForm'>
                    <PayUCardForm onSuccessCallback={processPaymentByToken} />
                </div>
                :null
            }                                                                                                                                                                                                                       
            {showCharges ?
                <div className='buyEventButton-charges'>
                    {renderCharges()}
                </div>
                : null
            }
        </div>
    );
};

export default BuyEventButton;
