import React from 'react';
import './Tabs.css';

const Tabs = (props) => {
	
    const renderTabs = () => {		
        let tabs = [];
        props.tabs.map((t) =>
            tabs.push(
                <div id={t.id} key={t.id} className={t.id === props.activeTab ? 'tabs-tab-active' : 'tabs-tab'} onClick={() => onTabClick(t.id)}>
                    {t.name}
                </div>
            )
        );
        return tabs;
    };

    const onTabClick = (id) =>{		
        // const falseFlag = {active: false};
        // let clearedTabs = props.tabs;
        // clearedTabs.map(x => x.active = false);
        // const selTabIdx = clearedTabs.findIndex(x => x.id === id);
        // let selTab = clearedTabs[selTabIdx];
        // selTab.active = true;
        // clearedTabs[selTabIdx] = selTab;		
        props.setActiveTab(id);		
    };



    return (
        <div className='tabs-container'>
            <div className='tabs-header'>{renderTabs()}</div>
            <div className='tabs-content'>{props.children}</div>
        </div>
    );
};

export default Tabs;
