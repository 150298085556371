import React, { useEffect } from 'react';
import './EntryPassesSummary.css';

import { useDispatch, useSelector } from 'react-redux';
// import { remoteConfigReader_getBoolean } from '../../store/actions/firebase';

import { getCustomerEntryPassesData } from '../../store/actions/lrnAPI';
import EntryPassesElement from './EntryPassesElement/EntryPassesElement';

const EntryPassesSummary = () => {

    const dispatch = useDispatch();

    const entryPasses = useSelector((state) => state.customerStore.entryPasses);
    // const [balancePaymentBtnShow] = useState(
    //     dispatch(remoteConfigReader_getBoolean('payu_enable_pay_balance'))
    // );

    // const [balancePaymentBtnEnabled] = useState(
    //     dispatch(remoteConfigReader_getBoolean('payu_enable_pay_balance'))
    // );
    
    const loadData = () => {        
        dispatch(getCustomerEntryPassesData());
    };


    const renderElements = () => {         
        let el = [];        
        if(!entryPasses ||  entryPasses.length === 0)
            return el;
        let key = 1;
        entryPasses.map(ep=>{            
            el.push(<EntryPassesElement key={key} item={ep} />);
            key++;
        });
        return el;
    };

    useEffect(() => {
        loadData();
    }, []);
    


    return (
        <div className='entryPassesSummary-container'>            
            {renderElements()}
        </div>
    );
};

export default EntryPassesSummary;