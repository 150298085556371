import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormControl, Spinner } from 'react-bootstrap';
import PinField from 'react-pin-field';

import './EditableField.css';

const EditableField = ({
    name,
    type,
    inputMode,
    value,
    label,
    updateFunction,
    pinField,
    validateFunction,
    id
}) => {
    const dispatch = useDispatch();
    const [isEditing, setIsEditing] = useState(false);
    const [inputValue, setInputValue] = useState(value);
    const [disableControls, setDisableControls] = useState(false);
    const [disableOkBtn, setDisableOkBtn] = useState(true);

    const onToggleEdit = () => {
        setIsEditing((value) => !value);
    };

    const onInputChange = (event) => {
        setInputValue(event.target.value);	
        setDisableOkBtn(false);	
    };

    const onInputPinChange = (val) => {	
        setInputValue(val);	
        if (pinField) {				
            if (val.length < 6) {
                setDisableOkBtn(true);
            } else {
                setDisableOkBtn(false);
            }
        }
    };

    const onCloseEdit = () => {
        setInputValue(value);
        onToggleEdit();
    };

    const onSubmit = () => {
        const isValid = validateFunction ? validateFunction(inputValue) : true;

        if (!isValid) {
            return;
        }

        setDisableControls(true);
        setDisableOkBtn(true);
        dispatch(updateFunction(inputValue))
            .then(() => {
                onToggleEdit();
                setDisableControls(false);
            })
            .catch(() => {
                setDisableControls(false);
            });
    };

    return (
        <div className='editable-field-container'>
            <div className='app-text-label'>{label}:</div>

            {isEditing ? (
                <div className='editable-input-field-value-col'>
                    {pinField ? (
                        <PinField
                            id={id+'-pinField'}
                            name={name}
                            className='form-control form-control-sm pin-field'
                            length={6}
                            onChange={onInputPinChange}
                            validate={/^[a-zA-Z0-9]$/}
                            inputMode='numeric'
                            pattern='[0-9]*'
                            type='text'
                            autoFocus
                        />
                    ) : (
                        <FormControl
                            id={id+'-editFiled'}
                            name={name}
                            type={type}
                            inputMode={inputMode}
                            size='sm'
                            onChange={onInputChange}
                            value={inputValue}
                            disabled={disableControls}
                            autoFocus
                        />
                    )}
                    <button
                        id={id+'-btnOk'}
                        className=''
                        disabled={!inputValue || disableControls || disableOkBtn}
                        onClick={onSubmit}>
                        {disableControls ? (
                            <Spinner
                                animation='border'
                                role='status'
                                style={{ height: '20px', width: '20px' }}
                            />
                        ) : (
                            '✔'
                        )}
                    </button>
                    <button
                        id={id+'-btnCancel'}                        
                        disabled={disableControls}
                        onClick={onCloseEdit}>
						✖
                    </button>
                </div>
            ) : (
                <div className='editable-field-value-col'>
                    <div id={id+'-data'} className='app-text-data'>{value}</div>
                    <button
                        id={id+'-editModeBtn'}                        
                        className=''
                        onClick={onToggleEdit}
                        disabled={disableControls}>
						✎
                    </button>
                </div>
            )}
        </div>
    );
};

export default EditableField;
