import React from 'react';
import './NewSchedule.css';
import StatusPill from '../UI/StatusPill/StatusPill';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

const NewSchedule = (props) => {

    return (
        <div
            id={props.id}
            key={props.id}
            onClick={props.onClick}
            className={'new-event-container ' + props.bgColorClass ?? null}>
            <div className='new-event-edge-left'></div>
            <div className='new-event-left'>
                <div className='new-event-container-left'>
                    <div className='new-event-time-text'>
                        {!props.endDate ?
                            props.dayName +' ' +props.time
                            : null}
                    </div>
                    <div className='new-event-time-status'>
                        <StatusPill statusId={props.statusId} itemType='signup' />
                    </div>                    
                    <FontAwesomeIcon icon={faCalendarAlt} />                    
                    {' ' + props.startDate} {props.endDate ? ' - ' + props.endDate : null}                    
                </div>
            </div>
            <div className='new-event-center'>
                <div className='new-event-center-title color-main'>
                    {props.name + ' ' + props.level}{' '}
                </div>
                
                <div className='new-event-desc'>
                    <div className='cal-event-desc-line'>
                        {props.nameDetails}
                    </div>
                    <div className='cal-event-desc-line'>
                        {props.teachers} <span className='new-event-desc-mark'>•</span>{' '}
                        {props.room}
                    </div>
                </div>
            </div>
            <div className='new-event-right'>
				
            </div>
            <div className='new-event-details-icon'>{props.actionTool}</div>
            <div className='new-event-bottom'>{props.details}</div>
			
        </div>
    );
};

export default NewSchedule;
