import React, { useEffect } from 'react';
import './MySignupsDaysList.css';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerSignups } from '../../store/lrnAPI/custContractsEntries';
import CategoryItemsList from '../../components/CategoryItemsList/CategoryItemsList';
import { getUniquValues } from '../../store/actions/utils';
import { daysOfWeekDefinition } from '../../store/actions/calendar';

const MySignupsDaysList = () => {
    const dispatch = useDispatch();
    const userId = useSelector((store) => store.customerStore.customerData.id);
    const apiToken = useSelector((store) => store.uiStore.apiToken);
    const signups = useSelector((state) => state.eventsStore.signups);
    const loading = useSelector((state) => state.uiStore.inProgress);

    const loadData = () => {
        dispatch(getCustomerSignups(userId, apiToken));
    };

    const getActiveDays = () => {
        const uniqueExistingDays = getUniquValues(
            signups.sort((x) => x.DayId),
            'DayId'
        );
        let resObj = [];
        const daysDef = daysOfWeekDefinition;
        uniqueExistingDays.sort();
        uniqueExistingDays.forEach((dayId) => {
            const d = daysDef.find((x) => x.id === dayId);
            if (d) {
                resObj.push(d);
            }
        });
        return resObj;
    };

    /* eslint-disable */
	useEffect(() => {
        loadData();        
	}, []);

	return (
		<div className='signups-list-container'>
			{loading ? null : signups && signups.length > 0 ? (
				<div>
					<CategoryItemsList
						listType='signups'
						categories={getActiveDays()}
						items={signups}
						itemsGroupBy={'Day'}
						itemsToCatLinkKey={'shortName'}></CategoryItemsList>
				</div>
			) : (
				<div className='signups-list-empty'>Nie masz żadnych zapisów</div>
			)}
		</div>
	);
};

export default MySignupsDaysList;
