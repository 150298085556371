import React, { Fragment } from 'react';
import './CategoryBar.css';
import { DropDownIcon } from '../../Icons/Icons';

const CategoryBar = (props) => {
    return (
        <Fragment>
            <div
                className='catlist-category-bar-container'
                id={props.id}
                onClick={props.onClick}>
                <div className='catlist-category-bar-text'>{props.text}</div>
                <div
                    className={
                        props.isSelectedCat
                            ? 'catlist-category-bar-icon rotate-180'
                            : 'catlist-category-bar-icon'
                    }>
                    {' '}
                    <DropDownIcon />
                </div>
            </div>
            <div className='catlist-category-bar-items'>{props.children}</div>
        </Fragment>
    );
};

export default CategoryBar;
