import React from 'react';
import MySignupsDaysList from '../../containers/MySignupsDaysList/MySignupsDaysList';
import ActiveMenuMonitor from '../../components/Nav/ActiveMenuMonitor/ActiveMenuMonitor';

import './MySignupsPage.css';

const MySignupsPage = () => {
    return (
        <div className='mysignups-page'>
            <ActiveMenuMonitor menuId={3}/>
            <MySignupsDaysList />
        </div>
    );
};

export default MySignupsPage;
