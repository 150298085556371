import React from 'react';
import classes from './Modal.module.css';
import Backdrop from '../Backdrop/Backdrop';

const Modal =({show, onModalClosed, message, children })=>
{

    return (
        <div>
            {show ?
                <>
                    <Backdrop show={show} clicked={onModalClosed} />
                    <div
                        className={classes.Modal}
                        style={{
                            transform: show ? 'translateY(0)' : 'translateY(-100vh)',
                            opacity: show ? '1' : '0'
                        }}>
                        <div className={classes.Message} >
                            {message}
                        </div>

                        <div className={classes.Content}>
                            {children}
                        </div>
                    </div>
                </>
                : null
            }
        </div>
    );

};

export default Modal;