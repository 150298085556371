import React from 'react';

import './MySignupsItem.css';
import StatusPill from '../UI/StatusPill/StatusPill';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';


const MySignupsItem = (props) => {
    const className = [
        'signup-event-containter',
        props.bgColorClass,
        props.onClick && 'clickable',
    ].filter((item) => !!item).join(' ');

    return (
        <div
            id={props.id}
            key={props.id}
            onClick={props.onClick}
            className={className}>
            <div className='signup-event-edge-left'></div>
            <div className='signup-event-left'>
                <div className='new-event-time-text'>
                    {!props.endDate ?
                        props.dayName +' ' +props.time
                        : null}
                </div>
                <div className='new-event-time-status'>
                    <StatusPill statusId={props.statusId} itemType='signup' />
                </div>                    
                <FontAwesomeIcon icon={faCalendarAlt} />                    
                {' ' + props.startDate} {props.endDate ? ' - ' + props.endDate : null}
            </div>
            <div className='signup-event-center'>
                <div className='signup-event-center-title'>{props.name + ' ' + props.level} </div>
                
                <div className='signup-event-desc'>
                    <div className='signup-event-desc-line'>
                        {props.nameDetails}
                    </div>
                    <div className='signup-event-desc-line'>
                        {props.teachers} <span className='signup-event-desc-mark'>•</span> {props.room}
                    </div>
                    
                </div>
            </div>
            <div className='signup-event-right'>{props.children}</div>
            <div className='signup-event-bottom'>{props.details}</div>

        </div>
    );
};

export default MySignupsItem;
