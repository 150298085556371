export const REGEX_ONLY_LETTERS = /^(?!\s)(?![\s\S]*\s$)[a-zA-Z\- ĄąĆćĘęŁłŃńÓóŚśŹźŻż]{1,20}$/;   
export const REGEX_PHONE_NUMBER = /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{5})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/;
// eslint-disable-next-line no-useless-escape
export const REGEX_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isPhoneNumberValid = (phoneNo) => { 
    const r = REGEX_PHONE_NUMBER;
    return r.test(phoneNo);
};
export const isEmailValid = (email) => { 
    const r = REGEX_EMAIL;
    return r.test(email);
};