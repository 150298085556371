import React from 'react';
import './PictureAvatar.css';

const PictureAvatar = (props) => {
    return (
        <div className='picture-avatar-container'>
            <div className='picture-avatar-picture'>{props.children}</div>
            <div className='picture-avatar-picture-overlay'>{props.overlay}</div>
        </div>
    );
};

export default PictureAvatar;
