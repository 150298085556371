import React from 'react';
import './SocialContact.css';
import { setInfo } from '../../../../store/actions/ui';
import { analytics } from '../../../../firebase/Firebase';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { Fragment } from 'react';
import PictureAvatar from '../../../../components/UI/Pictures/PictureAvatar/PictureAvatar';
import manpic from './SocialContactResources/avatar-pic-blur-man.png';
import womanpic from './SocialContactResources/avatar-pic-blur-woman.png';
import DataLabel from '../../../../components/UI/DataLabel/DataLabel';
import DataButton from '../../../../components/UI/DataButton/DataButton';
import fbIcon from './SocialContactResources/f_logo_RGB-Blue_58.png';
import { useDispatch } from 'react-redux';
import { logEvent } from 'firebase/analytics';

const SocialContact = (props) => {
    const dispatch = useDispatch();

    const renderAchievements = () => {
        let items = [];
        if (props.data.Achievements) {
            props.data.Achievements.map((r) =>
                items.push(
                    <DataLabel key={r.EventId} text={r.EventName + ' ' + r.LevelName} />
                )
            );
        }
        return items;
    };

    const defaultAvatar = () => {
        const picture =
      props.data.Gender === 'M' ? (
          <img
              className="rounded-corners"
              src={manpic}
              alt="Zdjęcie"
              width="90px"
          />
      ) : (
          <img
              className="rounded-corners"
              src={womanpic}
              alt="Zdjęcie"
              width="100px"
          />
      );
        const lock = (
            <FontAwesomeIcon
                icon={faLock}
                size="3x"
                onClick={onAvatarPictureLockClick}
            />
        );
        return <PictureAvatar overlay={lock}>{picture}</PictureAvatar>;
    };

    const fbIconDiv = () => {
        return (
            <div className="social-contact-fb">
                {props.data.FacebookName ? (
                // <a
                // 	href={
                // 		'https://www.facebook.com/' +
                // 		props.data.FacebookName.toString().replace(/ /g, '')
                // 	}
                // 	target='_blank'
                // 	rel="noopener noreferrer">
                // 	{' '}
                // </a>
                    <img
                        src={fbIcon}
                        width="20px"
                        alt={props.data.FacebookName}
                        onClick={onFacebookIconClick}
                    />
                ) : null}
            </div>
        );
    };

    const onFacebookIconClick = () => {
        dispatch(
            setInfo(
                'Nie masz odpowiedniego dostępu, aby otworzyć profil Facebook tej osoby.'
            )
        );
        analytics.logEvent('select_content', {
            content_type: 'features',
            content_id: 'facebook_link',
        });
    };

    const onAvatarPictureLockClick = () => {
        dispatch(
            setInfo('Nie masz odpowiedniego dostępu, aby zobaczyć zdjęcie tej osoby.')
        );
        logEvent(analytics, 'select_content', {
            content_type: 'features',
            content_id: 'contact_picture',
        });
    };

    const renderContact = () => {
        return (
            <Fragment>
                <div className="social-contact-picture">
                    <PictureAvatar>{defaultAvatar()}</PictureAvatar>
                </div>
                <div className="social-contact-name">
                    {props.data.FirstName + ' ' + props.data.LastName}
                </div>
                <div className="social-contact-name-sub">{props.data.UserName}</div>

                <div className="social-contact-info">{renderAchievements()}</div>
                <div className="social-contact-actions">
                    {props.data.Scores ? (
                        <Fragment>
                            <DataButton
                                key="tachPill"
                                text="Technika"
                                data={props.data.Scores.ScoreCategory1}
                                onClick={() => props.onSocialScoreClick(1)}
                            />
                            <DataButton
                                key="flowPill"
                                text="Flow"
                                data={props.data.Scores.ScoreCategory2}
                                type="flow"
                                size="md"
                                onClick={() => props.onSocialScoreClick(2)}
                            />
                            <DataButton
                                key="emotionsPill"
                                text="Emocje"
                                data={props.data.Scores.ScoreCategory3}
                                type="emotions"
                                size="md"
                                onClick={() => props.onSocialScoreClick(3)}
                            />
                        </Fragment>
                    ) : null}
                </div>
                {props.data.FacebookName ? fbIconDiv() : null}
            </Fragment>
        );
    };

    const renderInvite = () => {
        return (
            <Fragment>
                <div className="social-contact-picture">
                    <PictureAvatar>{defaultAvatar()}</PictureAvatar>
                </div>
                <div className="social-contact-name">
                    {props.data.FirstName + ' ' + props.data.LastName}
                </div>
                <div className="social-contact-name-sub">{props.data.UserName}</div>
                <div className="social-contact-actions">
                    <button
                        className="confirm"
                        onClick={() => props.onAcceptClick(props.data.Id)}
                    >
            Akceptuj
                    </button>
                    <button
                        className="cancel"
                        onClick={() => props.onDeclineClick(props.data.Id)}
                    >
            Odrzuć
                    </button>
                </div>
            </Fragment>
        );
    };

    return (
        <div className="social-contact-container">
            {props.data ? (
                props.invite ? (
                    renderInvite()
                ) : (
                    renderContact()
                )
            ) : (
                <div className="social-contact-noresults-label">Brak wyników</div>
            )}
        </div>
    );
};

export default SocialContact;
