class CreateCCSEMobileAppReq {
    constructor(CustomerId, 
        CustSignups, 
        PurchasePriceOption, 
        CardToken,
        DeviceFingerprint,
        ContinueUrl,
        RecurringFirst
    ) {
        this.CustomerId = CustomerId;
        this.CustSignups = CustSignups;
        this.PurchasePriceOption = PurchasePriceOption;
        this.CardToken = CardToken;
        this.DeviceFingerprint = DeviceFingerprint;
        this.ContinueUrl = ContinueUrl;
        this.RecurringFirst = RecurringFirst;
    }

    obj() {
        const obj = {
            CustomerId:  this.CustomerId,
            CustSignups: this.CustSignups,
            PurchasePriceOption: this.PurchasePriceOption,
            CardToken: this.CardToken,
            DeviceFingerprint: this.DeviceFingerprint,
            ContinueUrl: this.ContinueUrl,
            RecurringFirst: this.RecurringFirst
        };
        return obj;
    }
}
export default CreateCCSEMobileAppReq;
