import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Barcode from 'react-barcode';
import { remoteConfigReader_getBoolean } from '../../store/actions/firebase';
import { useNavigate } from 'react-router';
import './AccountDetails.css';

import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';

import ManIconSvg from '../Icons/resources/svg/ManIconSvg';
import WomanIconSvg from '../Icons/resources/svg/WomanIconSvg';
import PictureAvatar from '../UI/Pictures/PictureAvatar/PictureAvatar';
import EditableField from '../EditableField/EditableField';
import EntryPassesSummary from '../EntryPassesSummary/EntryPassesSummary';
import ButtonWithSpinner from '../UI/ButtonWithSpinner/ButtonWithSpinner';

import {
    customerDetailsUpdate,
} from '../../store/actions/lrnAPI';
import { setInfo, setError, logout } from '../../store/actions/ui';
import { updateFirebasePassword } from '../../store/actions/auth';
import { addPaymentWithPayU, createDepositPayment } from '../../store/lrnAPI/payU';
import CreatePaymentRequest from '../../models/requests/CreatePaymentRequest';
import { PAYU_ENABLE_PAY_BALANCE } from '../../firebase/remoteConfig/remoteConfigParams';
import { REGEX_ONLY_LETTERS } from '../../utils/regexPatterns';


const AccountDetails = () => {
    //Hooks
    const dispatch = useDispatch();
    const navigate = useNavigate();

    //Load data
        
    //Store data
    const authCredential = useSelector((state) => state.customerStore.authData);    
    const custDetails = useSelector(
        (state) => state.customerStore.customerDetails
    );
    const userId = useSelector((store) => store.customerStore.customerData.id);
    const apiToken = useSelector((store) => store.uiStore.apiToken);

    //UI State
    const [balancePaymentBtnEnabled, setBalancePaymentBtnEnabled] = useState(
        dispatch(remoteConfigReader_getBoolean(PAYU_ENABLE_PAY_BALANCE))
    );
    const [depositPaymentBtnShow] = useState(
        dispatch(remoteConfigReader_getBoolean('payu_show_pay_deposit'))
    );
    const [depositPaymentBtnEnabled, setDepositPaymentBtnEnabled] = useState(
        dispatch(remoteConfigReader_getBoolean('payu_enable_pay_deposit'))
    );
    const [depositPaymentBtnInProgress, setDepositPaymentBtnInProgress] = useState(false);
    const [balancePaymentBtnInProgress, setBalancePaymentBtnInProgress] = useState(false);

    //Business logic
    const defaultAvatar = () => {    
        const picture =
      custDetails.Gender === 'M' ? (
          <ManIconSvg width="80" />
      ) : (
          <WomanIconSvg width="80" />
      );
        return <PictureAvatar>{picture}</PictureAvatar>;
    };

    const passwordChangeHandle = (password) => {
        if (signInMethodSupportsPin) {
            return updateFirebasePassword(password);
        } else {
            dispatch(
                setInfo(
                    'Brak informacji o zalogowaniu za pomocą email. Spróbuj później.'
                )
            );
        }
    };

    const signInMethodSupportsPin = () => {
        let supported = false;
        if (
            authCredential.signInMethod &&
      (authCredential.signInMethod === 'emailLink' ||
        authCredential.signInMethod === 'google.com' ||
        authCredential.signInMethod === 'backend')
        ) {
            supported = true;
        }
        return supported;
    };

    const emailValidate = (value) => {
        const emailRegex =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!emailRegex.test(value)) {
            dispatch(setError({ message: 'Podany email ma nieprawidłowy format.' }));
            return false;
        }

        return true;
    };

    const nameValidate = (value) => {
        const nameRegex = REGEX_ONLY_LETTERS;

        if (!nameRegex.test(value)) {
            if(value.length > 20)
                dispatch(setError({ message: 'Dozwolone tylko 20 liter.' }));
            else
                dispatch(setError({ message: 'Dozwolone są tylko litery.' }));
            return false;
        }

        return true;
    };

    const telValidate = (value) => {
        const nameRegex = /\+48[0-9]{9}/;

        if (!nameRegex.test(value)) {
            dispatch(
                setError({
                    message:
            'Nr telefonu ma nieprawidłowy format. Podaj nr w formacie +48111222333',
                })
            );
            return false;
        }

        return true;
    };


    // const buyPassWithSchedule = () => {};

    const onClickDepositPayment = () => {
        setDepositPaymentBtnEnabled(false);
        setDepositPaymentBtnInProgress(true);
        dispatch(createDepositPayment(userId, apiToken))
            .then((url) => {
                console.log('url', url);
                window.open(url, '_self');
            })
            .catch((err) => {
                setDepositPaymentBtnEnabled(true);
                setDepositPaymentBtnInProgress(false);
                dispatch(
                    setError({
                        message: 'Wystąpił problem z otwarciem strony PayU. ' + err,
                    })
                );
            });
    };

    const onClickBalancePayment = () => {
        if(userId && custDetails.Balance)
        {
            setBalancePaymentBtnEnabled(false);
            setBalancePaymentBtnInProgress(true);


            const req = new CreatePaymentRequest(custDetails.Balance, 'Opłata ujemnego balansu DSA Mobile'  );

            dispatch(addPaymentWithPayU(userId, req))
                .then((url) => {
                    console.log('url', url);
                    window.open(url, '_self');
                })
                .catch(() => {
                    setBalancePaymentBtnEnabled(true);
                    setBalancePaymentBtnInProgress(false);
                });
        }
        else
        {
            dispatch(setError({message:'Brak wystarczających informacji, aby utworzyć wpłatę'}));
        }
    };

    
    const logoutUser = () => {
        dispatch(logout());
        navigate('/');
    };
    // const buyPassWithSchedule = () => {};

    //UI Updates
    useEffect(() => {
        if(!custDetails || !custDetails.Id)
        {
            navigate('/');
        }
    }, []);

    return (
        <div className="account-details-container">
            <div className="account-details-userdata-head">
                <Row>
                    <Col xs={3}>
                        <div className="account-details-avatar">{defaultAvatar()}</div>
                    </Col>
                    <Col>
                        <div className="account-details-col">
                            <div className="app-text-label">Nazwa użytkownika</div>
                            <div className="app-text-data">
                                {custDetails.UserName ?? 'Brak'}
                            </div>
                        </div>
                        <div className="account-details-col">
                            <div id='account-details-id' className="app-text-data">Id: {custDetails.Id}</div>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="account-details-userdata">
                <div className="account-details-userdata-row-field">
                    <EditableField
                        id='account-details-firstName'
                        type="text"
                        value={custDetails.FirstName}
                        label="Imię"
                        name="FirstName"
                        updateFunction={(value) =>
                            customerDetailsUpdate('FirstName', value)
                        }
                        validateFunction={nameValidate}
                    />
                </div>
                <div className="account-details-userdata-row-field">
                    <EditableField
                        id='account-details-lastName'
                        type="text"
                        value={custDetails.LastName}
                        label="Nazwisko"
                        name="LastName"
                        updateFunction={(value) => customerDetailsUpdate('LastName', value)}
                        validateFunction={nameValidate}
                    />
                </div>
                <div className="account-details-userdata-row">
                    <div className="app-text-label">Facebook</div>

                    <div id='account-details-facebook' className="app-text-data">{custDetails.FacebookName}</div>
                </div>
                <div className="account-details-userdata-row-field">
                    <EditableField
                        id='account-details-phoneNo'
                        value={custDetails.PhoneNo}
                        label="Nr telefonu"
                        name="PhoneNo"
                        type="tel"
                        pattern="\+48[0-9]{9}"
                        updateFunction={(value) => customerDetailsUpdate('PhoneNo', value)}
                        validateFunction={telValidate}
                    />
                </div>
                <div className="account-details-userdata-row-field">
                    <EditableField
                        id='account-details-email'
                        value={custDetails.Email}
                        label="Email"
                        name="Email"
                        type="email"
                        updateFunction={(value) => customerDetailsUpdate('Email', value)}
                        validateFunction={emailValidate}
                    />
                </div>
                <div className="account-details-userdata-row">
                    <div className="account-details-userdata-row-sub-col">
                        <div className="app-text-label">Saldo</div>
                    </div>
                    <div className="account-details-userdata-row-sub-col">
                        <div id='account-details-balance' className="app-text-lg">{custDetails.Balance ?? ''} zł</div>
                    </div>
                    <div className="account-details-userdata-row-sub-col">
                        <ButtonWithSpinner id={'account-details-btnPayBalance'} 
                            className='btn main'
                            disabled={!balancePaymentBtnEnabled} 
                            inProgress={balancePaymentBtnInProgress} 
                            onClick={onClickBalancePayment} 
                            show={true}                                        
                        >
                            Opłać
                        </ButtonWithSpinner>
                    </div>
                </div>
                <div className="account-details-userdata-row">
                    <div className="account-details-userdata-row-sub-col">
                        <div className="app-text-label">Kaucja</div>
                    </div>
                    <div className="account-details-userdata-row-sub-col">
                        <div id='account-details-deposit' className="app-text-lg">{custDetails.Deposit ?? ''} zł</div>
                        <div className="app-text-title-sm">
              (wpłata kaucji zgodnie z regulaminem)
                        </div>
                    </div>
                    <div className="account-details-userdata-row-sub-col">                        
                        <ButtonWithSpinner id={'account-details-btnPayDeposit'}
                            className='main'
                            disabled={!depositPaymentBtnEnabled} 
                            inProgress={depositPaymentBtnInProgress} 
                            onClick={onClickDepositPayment} 
                            show={depositPaymentBtnShow}                                     
                        >
                            Wpłać
                        </ButtonWithSpinner>
                    </div>
                </div>
                <div className="account-details-userdata-row">
                    <div className="account-details-userdata-row-grid-title app-text-label">
                        Karnety 
                    </div>
                    <div className="account-details-userdata-row-sub">
                        {custDetails.Id ? <EntryPassesSummary/> : null}
                    </div>
                </div>
                <div className="account-details-userdata-row">
                    {signInMethodSupportsPin() ? (
                        <EditableField
                            id='account-details-pin'
                            value={custDetails.PasswordIsSet ? '******' : 'Ustaw PIN'}
                            label="PIN"
                            name="PIN"
                            updateFunction={(value) => passwordChangeHandle(value)}
                        />
                    ) : null}
                </div>
            </div>
            <div className="account-details-userdata-footer">
                <div className="app-text-label">Kod kreskowy</div>
                <Barcode
                    id='account-details-barcode'
                    value={custDetails?.Id?.toString()}
                    displayValue={false}
                    text-align="center"
                ></Barcode>
            </div>
            <div className="account-details-logout">
                <button id='account-details-btnLogout' className='second' onClick={logoutUser}>
                    Wyloguj się
                </button>
            </div>
        </div>
    );
};

export default AccountDetails;
