//Libs
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//CSS
import './PurchaseOptionDetails.css';

//Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faPlus, faRecycle } from '@fortawesome/free-solid-svg-icons';
import ButtonWithSpinner from '../../../../UI/ButtonWithSpinner/ButtonWithSpinner';

//Functions
import { getDateTimeString, getOnlyDateString } from '../../../../../utils/helpers/dateTimeHelper';
import { getStatusDetails, STATUS_DIC_TYPE_PAYMENTS } from '../../../../../models/Statuses';
import CreatePaymentRequest from '../../../../../models/requests/CreatePaymentRequest';
import { addPaymentWithPayU } from '../../../../../store/lrnAPI/payU';
import { setError } from '../../../../../store/actions/ui';
import ListElementContainer from '../../../../UI/ListElementContainer/ListElementContainer';

const PurchaseOptionDetails = ({id, 
    chargeId, 
    entryDate, 
    amount, 
    payments, 
    amountLeft, 
    scheduleId, 
    custContractsEntryId, 
    custContractsSalesEntryId,
    monthlyPayments,
    onBuy,
    purchaseOptionId
}) => {

    const dispatch = useDispatch();
    const custId = useSelector((store) => store.customerStore.customerData.id);
    const [inProgress, setInProgress] = useState(false);

    const retryPayment = (url, ceId) => { 
        if(url)
        {
            window.open(url, '_self');
        }
        if(monthlyPayments)
        {
            onBuy(true, purchaseOptionId);
        }
        else if (ceId)
        {
            newPayment(ceId);
        }
    };
    const newPayment = (ceId) => { 
        
        if(!custContractsEntryId || !custContractsSalesEntryId || !scheduleId || !chargeId)
        {
            const msg = {message:'Brak wystarczających informacji, aby utworzyć płatność.'
                .concat('custContractsEntryId:'+custContractsEntryId, 
                    'custContractsSalesEntryId:'+ custContractsSalesEntryId, 
                    'chargeId:'+chargeId)};
            console.log('msg.message', msg.message);
            dispatch(setError(msg));
            return false;
        }
        setInProgress(true);
        if(monthlyPayments)
        {
            onBuy(true, purchaseOptionId);
        }
        else{
            const payReq = new CreatePaymentRequest(amountLeft,
                null,
                custContractsEntryId,
                scheduleId,
                custContractsSalesEntryId,
                null,
                false,
                chargeId,
                ceId);
            
            dispatch(addPaymentWithPayU(custId, payReq))
                .then((url) => {
                    if (url && url !== '') {
                        window.open(url, '_self');
                    } else {
                        setInProgress(false);
                    
                    }
                })
                .catch(() => {
                    setInProgress(false);
                });
        }
    };

    const allowRetryPayment = (p) => { 
        if((p.PaymentUrl || p.Id ) && p.Status === 8)
            return true;
        else
            return false;
    };

    const checkIfRetryPaymentActive = () => { 
        let active = false;
        payments.forEach(p => {
            active = allowRetryPayment(p);
        });
        return active;
    };

    const renderPayments = () => { 
        let list = [];
        let i = 0;
        payments.forEach(p => {
            i++;
            let pid = id+'payment'+i;
            list.push(<div id={pid} key={pid} className='purchaseOptionsDetails-payments-details' >
                <div className='purchaseOptionsDetails-payments-details-status' >
                    <div className='purchaseOptionsDetails-payments-details-status-icon' >
                        <FontAwesomeIcon icon={faCircle} color={getStatusDetails(p.Status, STATUS_DIC_TYPE_PAYMENTS, 'color')} />
                    </div>
                    <div className='purchaseOptionsDetails-payments-details-status-text1' >
                        <div className='purchaseOptionsDetails-payments-details-status-text' >
                            {'Płatność Id:' + p.Id +' ' + getDateTimeString(p.EntryDate) }
                        </div>
                        <div className='purchaseOptionsDetails-payments-details-status-text2' >
                            { ' ' + getStatusDetails(p.Status, STATUS_DIC_TYPE_PAYMENTS) +' ' + p.Amount + ' zł ' + ' ' + (p.PaymentStatus??'')}
                        </div>
                        
                    </div>
                </div>
                {allowRetryPayment(p) ?
                    <div className='purchaseOptionsDetails-payments-details-btn' >
                        <ButtonWithSpinner id={'purchaseOptionsDetails-payments-details-btn-retry'+p.Id}
                            className='main' 
                            inProgress={inProgress}
                            onClick={()=>retryPayment(p.PaymentUrl, p.Id)} 
                            show={true}
                        >
                            <FontAwesomeIcon icon={faRecycle} /> {' '}
                            Ponów płatność
                        </ButtonWithSpinner>
                    </div>
                    : null}
            </div>); 
        });

        return list;
    };

    return (
        <ListElementContainer color={amountLeft !== 0 ? 'danger' : 'success'}>
            <div id={id} key={id} className='app-text-main purchaseOptionsDetails-container'>
                <div className='purchaseOptionsDetails-charge'>
                    <div className='purchaseOptionsDetails-title'></div>
                    <div className='purchaseOptionsDetails-details'>
                        <div>
                            <FontAwesomeIcon icon={faCircle} color={amountLeft !== 0 ? 'red' : 'green'} />
                            {' '} Obciążenie: {getOnlyDateString(entryDate)} Kwota: {amount} zł</div>
                    </div>
                </div>
                <div className='purchaseOptionsDetails-payments'>
                    {renderPayments()}
                    {/* Disabling as this causes payments not connected to right ccse */}
                    {amountLeft < 0 && !checkIfRetryPaymentActive() ?
                        <div className='purchaseOptionsDetails-payments-add' >
                            <ButtonWithSpinner id={'purchaseOptionsDetails-payments-add'}
                                inProgress={inProgress}
                                onClick={()=>newPayment()}
                                show={true}
                                className='main'
                            >
                                <FontAwesomeIcon icon={faPlus} /> 
                                Dodaj płatność
                            </ButtonWithSpinner>
                        </div>
                        : null}
                </div>
            </div>
        </ListElementContainer>
    );
};

export default PurchaseOptionDetails;