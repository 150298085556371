export const quantityMapping = (quantity) => { 
    if(!isNaN(quantity) && quantity === 0)
        return 'BRAK';
    if(!quantity)
        return '';
    if(typeof quantity === 'number' && quantity > 100)
        return 'OPEN';
    
    return quantity;
    
};