//Libs
import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import useFirebaseMessaging from '../../hooks/useFirebaseMessaging';

import './Layout.css';

//Components
import Dashboard from '../../pages/Dashboard/Dashboard';
import CalendarPage from '../../pages/CalendarPage/CalendarPage';
import MessagesPage from '../../pages/MessagesPage/MessagesPage';
import Navigation from '../../components/Nav/Navigation/Navigation';
import Topbar from '../../components/Topbar/Topbar';
import AccountPage from '../../pages/AccountPage/AccountPage';
import Auth from '../../components/Auth/Auth';
import Toastify from '../../components/Notifications/Toastify/Toastify';
import MySignupsPage from '../../pages/MySignupsPage/MySignupsPage';
import NewSchedulesPage from '../../pages/NewSchedulesPage/NewSchedulesPage';
import NewScheduleDetailsPage from '../../pages/NewSchedulesPage/NewScheduleDetailsPage/NewScheduleDetailsPage';
import EmailLink from '../../components/Auth/EmailLink/EmailLink';
import MyAchievements from '../../pages/MyAchievementsPage/MyAchievementsPage';
import FeedbackPage from '../../pages/FeedbackPage/FeedbackPage';
import SocialSearchPage from '../../pages/SocialPortalPage/SocialSearchPage/SocialSearchPage';
import SelectSchoolPage from '../../pages/SelectSchoolPage/SelectSchoolPage';
import SocialContactsPage from '../../pages/SocialPortalPage/SocialContactsPage/SocialContactsPage';
import SocialUserProfile from '../../pages/AccountPage/SocialUserProfilePage/SocialUserProfilePage';
import MySignupPage from '../../pages/MySignupsPage/MySignupPage/MySignupPage';
import FacebookSDK from '../../facebook/FacebookSDK';
import Cookies from '../../containers/Cookies/Cookies';
import SelectedEndpointTag from '../../components/SelectedEndpointTag/SelectedEndpointTag';

//Utils
import { downloadPublicKeys } from '../../store/actions/settings';
import { remoteConfigReader_getBoolean } from '../../store/actions/firebase';
import PaymentsPage from '../../pages/PaymentsPage/PaymentsPage';
import RegularPaymentsPage from '../../pages/PaymentsPage/RegularPaymentsPage/RegularPaymentsPage';
import DepositsPage from '../../pages/PaymentsPage/DepositsPage/DepositsPage';

const Layout = () => {
    const isSignedInToAPI = useSelector((state) => state.uiStore.apiSignedIn);
    const apiConfiguration = useSelector((state) => state.firebaseStore.selectedAPIConfig);
    const navigate = useNavigate();
    const isSchoolSelected = !!apiConfiguration?.apiUrl;
    const isAllowedToUse = isSignedInToAPI && isSchoolSelected;

    useFirebaseMessaging();

    const dispatch = useDispatch();
    const socialModuleEnabled = dispatch(remoteConfigReader_getBoolean('func_social_enabled'));
    const loadPublicKeys = () => {
        dispatch(downloadPublicKeys());
        //const conf = remoteConfig.getAll();
    };

    const isAllowedToAccess = (element) => {
        if (isAllowedToUse) {
            return element;
        }
        
        if (!isSchoolSelected) {
            return <SelectSchoolPage />;
        }

        return <Auth/>;
    };

    /* eslint-disable */
	useEffect(() => {
		loadPublicKeys();
	}, []);

	useEffect(() => {
		if (isAllowedToUse && localStorage.getItem('dsa:internal:url')) {
			navigate(localStorage.getItem('dsa:internal:url'));
			localStorage.removeItem('dsa:internal:url');
		}
	}, [isAllowedToUse]);

	return (
		<React.Fragment>
			<Cookies />
			<FacebookSDK />
			<div className='layout-container'>
				<div className='layout-top'>
					<Topbar />
				</div>
				<div className='layout-content'>
					<Routes>
						<Route path='/' element={isSchoolSelected ? isSignedInToAPI ? <Dashboard /> : <Auth /> : <SelectSchoolPage />} exact />

						<Route path='/' element={isAllowedToAccess(<Dashboard />)} exact />
						<Route path='/calendar' element={isAllowedToAccess(<CalendarPage />)} />
						<Route path='/myevents' element={isAllowedToAccess(<MySignupsPage />)} />
						<Route path='/myevents/:id' element={isAllowedToAccess(<MySignupPage />)} />
						<Route path='/messages' element={isAllowedToAccess(<MessagesPage />)} />
						<Route path='/account' element={isAllowedToAccess(<AccountPage />)} />
						<Route path='/newschedules' element={isAllowedToAccess(<NewSchedulesPage />)} exact />
						<Route path='/myachievements' element={isAllowedToAccess(<MyAchievements />)} />
						<Route path='/feedback' element={isAllowedToAccess(<FeedbackPage />)} />
						<Route path='/socialsearch' element={isAllowedToAccess(<SocialSearchPage />)} />
						<Route path='/socialcontacts' element={isAllowedToAccess(<SocialContactsPage />)} />
						<Route path='/userprofile' element={isAllowedToAccess(<SocialUserProfile />)} />
						<Route path='/payments' element={isAllowedToAccess(<PaymentsPage />)} />
						<Route path='/regularpayments' element={isAllowedToAccess(<RegularPaymentsPage />)} />
						<Route path='/deposits' element={isAllowedToAccess(<DepositsPage />)} />
						<Route
							path='/newschedules/:id'
							element={isAllowedToAccess(<NewScheduleDetailsPage />)}
							exact
						/>
						<Route path='/emaillink' element={isSchoolSelected ? <EmailLink /> : <SelectSchoolPage />} exact />
					</Routes>
				</div>
				<Toastify />
				<div className='layout-bottom'>
					<Navigation socialModuleEnabled={socialModuleEnabled} />
					<SelectedEndpointTag name={apiConfiguration?.name} />
				</div>
			</div>
		</React.Fragment>
	);
};

export default Layout;
