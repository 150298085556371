import React from 'react';
import ActiveMenuMonitor from '../../components/Nav/ActiveMenuMonitor/ActiveMenuMonitor';
import CalendarDisplay from '../../components/Calendar/CalendarDisplay/CalendarDisplay';
import './CalendarPage.css';

const CalendarPage = () =>{
    return(
        <div className='cal-page'>
            <ActiveMenuMonitor menuId={2}/>
            <CalendarDisplay/>
        </div>
    );
};


export default CalendarPage;