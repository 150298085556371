import React from 'react';

const DropDownIconSvg = () => 
    <svg width='17' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M8.77216 8.1318L1.5463 0.905942C1.36859 0.72823 1.08358 0.72823 0.905867 0.905942C0.728155 1.08365 0.728155 1.36866 0.905867 1.54638L8.45362 9.09413C8.63133 9.27185 8.91635 9.27185 9.09406 9.09413L16.6385 1.54638C16.7256 1.4592 16.7726 1.34184 16.7726 1.22784C16.7726 1.11383 16.729 0.996474 16.6385 0.909294C16.4607 0.731582 16.1757 0.731582 15.998 0.909294L8.77216 8.1318Z'
            fill='#383336'
        />
    </svg>;

export default DropDownIconSvg;
