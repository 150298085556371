export const STATUS_DIC_TYPE_EVENTS = 'statusDicEvents';
export const STATUS_DIC_TYPE_SIGNUPS = 'statusDicSignups';
export const STATUS_DIC_TYPE_PAYMENTS = 'statusDicPayments';

export const getEventStatusText = (statusId) => {
    var rec = statusDicEvents.filter((x) => x.id === statusId);
    return rec[0].text;
};

export const statusDicEvents = [
    { id: 1, name: 'proposal', text: 'Propozycja', variant: 'light', color: 'light' },
    { id: 2, name: 'postponed_plain', text: 'Nieobecność', variant: 'warning', color: 'light' },
    { id: 3, name: 'replacement', text: 'Zastępstwo', variant: 'info', color: 'light' },
    { id: 4, name: 'closed', text: 'Zamknięte', variant: 'secondary', color: 'light' },
    { id: 5, name: 'active', text: 'Aktywne', variant: 'success', color: 'light' },
    { id: 6, name: 'waiting', text: 'List rezerw.', variant: 'secondary', color: 'light' },
    { id: 7, name: 'planned', text: 'Planowane', variant: 'light', color: 'dark' },
    { id: 8, name: 'verification', text: 'Weryfikacja', variant: 'light', color: 'dark' },
    { id: 9, name: 'confirmation', text: 'Potwierdzanie', variant: 'light', color: 'dark' },

    {
        id: 102,
        name: 'postponed_todo',
        text: 'Do odrobienia',
        variant: 'warning',
    },
    { id: 104, name: 'postponed_noa', text: 'Nieob. ok', variant: 'secondary', color: 'light' },
    { id: 108, name: 'used', text: 'Wykorzystane', variant: 'light', color: 'dark' },
    { id: 109, name: 'replacement_done', text: 'Odrobione', variant: 'light', color: 'dark' },
    { id: 200, name: 'payment_needed', text: 'Wymagana opłata', variant: 'danger', color: 'light' },
    { id: 201, name: 'future_entry', text: 'Przyszły', variant: 'light', color: 'light' },
];

export const statusDicSignups = [
    { id: 4, name: 'closed', text: 'Zamknięty', variant: 'secondary', color: 'light' },
    { id: 5, name: 'active', text: 'Aktywne', variant: 'success', color: 'light' },
    { id: 6, name: 'waiting', text: 'List rezerw.', variant: 'secondary', color: 'light' },
    { id: 7, name: 'planned', text: 'Planowane', variant: 'light', color: 'dark' },
    { id: 8, name: 'verification', text: 'Weryfikacja', variant: 'light', color: 'dark' },
    { id: 9, name: 'confirmation', text: 'Potwierdzanie', variant: 'light', color: 'dark' },
    { id: 200, name: 'payment_needed', text: 'Wymagana opłata', variant: 'danger', color: 'light' },
    { id: 201, name: 'future_entry', text: 'Przyszły', variant: 'light', color: 'dark' },
    { id: 300, name: 'contract_error', text: 'Błąd kontraktu', variant: 'danger', color: 'light' },
];


export const statusDicPayments = [
    { id: 5, name: 'payment_ok', text: 'Ok', variant: 'success', color: 'green' },
    { id: 6, name: 'payment_inactive', text: 'Anulowana', variant: 'dark', color: 'red' },
    { id: 8, name: 'payment_verification', text: 'Weryfikacja', variant: 'warning', color: 'orange' },
];


export const getSignupsStatusText = (statusId) => {
    var rec = statusDicEvents.filter((x) => x.id === statusId);
    return rec[0].text;
};

export const getSignUpsStatusId = (statusName) => {
    var rec = statusDicEvents.filter((x) => x.name === statusName);
    return rec[0].id;
};

export const getStatusDetails = (statusId, type, field) => {
    var rec = null;
    switch (type) {
    case STATUS_DIC_TYPE_EVENTS:
        rec = statusDicEvents.filter((x) => x.id === statusId);
        break;
    case STATUS_DIC_TYPE_SIGNUPS:
        rec = statusDicSignups.filter((x) => x.id === statusId);
        break;
    case STATUS_DIC_TYPE_PAYMENTS:
        rec = statusDicPayments.filter((x) => x.id === statusId);
        break;
    default:
        break;
    }

    if(!field)
        return rec[0]?.text;
    else
        return rec[0]?.[field];
};

