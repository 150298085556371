import { sendRequest } from '../actions/lrnAPI';
import { setError, setInfo } from '../actions/ui';

export const getScheduleBundleItems = (scheduleId) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {            
            if (!scheduleId) {
                dispatch(setError({ message: 'Missing schedule id' }));
                return false;
            }
            const state = getState();
            const apiToken = state?.uiStore?.apiToken;
            const custId = state?.customerStore.customerData.id;
            if (!apiToken) {
                dispatch(setError({ message: 'Missing token. Please login again.' }));
                reject();
                return false;
            }
            if (!custId) {
                dispatch(setError({ message: 'Missing token. Please login again.' }));
                return false;
            }

		
            const endpoint = 'schedules/bundle/'+scheduleId+'/'+custId  ;
            sendRequest(endpoint, null, apiToken, 'get')
                .then((response) => {
                    switch (response.status) {
                    case 200:
                        resolve(response.data);
                        break;	
                    case 204:
                        resolve([]);
                        break;
                    case 208:
                        dispatch(setInfo(response.data.message));
                        break;					
                    default:
                        dispatch(setError(response));
                        break;
                    }
                })
                .catch((err) => {				
                    dispatch(setError(err));
                    reject();
                });
        });
    };
};
