import React from 'react';
import './CardPriceTag.css';
import header from './resources/Header.png';
import BorderSvg from './resources/BorderSvg';

const CardPriceTag = (props) => {
    return (
        <div className='card-price-tag-container'>
            <div className='card-price-tag-background'>
                <BorderSvg />
            </div>
            <div className='card-price-tag-header'>
                {/* <HeaderSvg /> */}
                <img src={header} alt='Następne wydarzenie' />;
            </div>
            <div className='card-price-tag-text card-price-tag-text-one '>
                {props.textLine1}
            </div>
            <div className='card-price-tag-text card-price-tag-text-two'>
                {props.textLine2}
            </div>
            <div className='card-price-tag-text card-price-tag-text-three'>
                {props.textLine3}
            </div>
            <div className='card-price-tag-mid'>{props.mid}</div>

            <div className='card-price-tag-barcode'>{props.barCode}</div>
			
            <div className='card-price-tag-footer'></div>
        </div>
    );
};

export default CardPriceTag;
