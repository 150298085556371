import React, { Fragment } from 'react';
import { Spinner } from 'react-bootstrap';
import Switch from 'react-switch';

import './ToggleButton.css';

const ToggleButton = (props) => {

    const renderSwitch = () => {
        return (
            <Switch
                key={'custom-switch-' + props.id}
                id={'custom-switch-' + props.id}
                type='switch'
                disabled={props.readOnly}
                checked={props.checked}
                onChange={props.onChange}
                //onClick={onClick}
                readOnly={true}
                //handleDiameter={props.height}
                //height={props.height}
                //width={props.width}
            />
        );
    };

    return (
        <Fragment>
            {props.inProgress ? (
                <Spinner
                    id='toggle-btn-spinner'
                    className='toggle-btn-spinner'
                    animation='border'
                    role='status'
                    size='sm'
                />
            ) : (
                <div className='toggle-btn-switch'>
                    {props.label ? (
                        <label>
                            <span className='toggle-btn-label'>{props.label}</span>
                            <div className='app-text-title-sub'>{props.comment}</div>
                            {renderSwitch()}
                        </label>
                    ) : (
                        <span>{renderSwitch()}</span>
                    )}
                </div>
            )}
        </Fragment>
    );
};

export default ToggleButton;
