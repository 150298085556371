import { sendRequest } from '../actions/lrnAPI';
import { setError, setInfo } from '../actions/ui';

export const createCustContractsSalesEntry = (custId, request) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {            
            if (!custId || !request) {
                dispatch(setError({ message: 'Missing custId or request' }));
                return false;
            }
            const state = getState();
            const apiToken = state.uiStore.apiToken;
            
            const endpoint = 'CustContractsSalesEntries/entrypasses/'+custId+'/create';
            sendRequest(endpoint, request, apiToken, 'post')
                .then((response) => {		
                    switch (response.status) {
                    case 200:
                        resolve(response.data);
                        break;	
                    case 208:
                        dispatch(setInfo(response.data.message));
                        break;					
                    default:
                        dispatch(setError(response));
                        break;
                    }
                })
                .catch((err) => {				
                    dispatch(setError(err));
                    reject();
                });
        });
    };
};
