import React from 'react';
import { getShortDate } from '../../../store/actions/calendar';
import './EntryPassesElement.css';

const EntryPassesElement = ({item}) => {

    const getDesc = () => { 
        return item.ServiceName 
			+ ' ' + (item.ScheduleName??'');
    };
    const getOpenPassDesc = (pass) => {        
        return getShortDate(new Date(pass.ValidTill));
    };

    const getQuant = () => { 
        let quant = '';
        if(item.Quantity < 100)
            quant = item.Quantity;
        else quant = ' do ' + getOpenPassDesc(item);
        return quant;
    };

	

    return (
        <div className="entryPassesSummary-element-container">
            <div className="entryPassesSummary-element-name">
                {getDesc()}:{' '}
            </div>
            <div className="entryPassesSummary-element-data">
                {getQuant()}
            </div>                
        </div>
    );
};

export default EntryPassesElement;