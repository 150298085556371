// Libs
import { collection, query, where, orderBy, limit, getDocs, doc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { firestore } from '../../firebase/Firebase';

//Get data helpers
export const collectionMainQuery = (collectionName) => {
    const coll = collection(firestore, collectionName);
    const currTimestamp = new Date();
    const q = query(coll, 
        where('validFrom', '<=', currTimestamp), 
        orderBy('validFrom', 'desc'), 
        limit(1));
    return q;
};

export const getAgreement = async (apiConfigId, collectionName) => {
    let id = 0;
    let agreement;
    const q = collectionMainQuery(apiConfigId+collectionName);
    await getDocs(q)
        .then(snap => {
            id = snap?.docs[0]?.id;
            agreement = snap?.docs[0]?.data();
        })
        .catch(e => {
            console.log('agreement get error ', e);
        });

    return { agreement, id };
};

//Operations helpers
export const updateDocWithResponse = async (userId, agreementId, collectionName, accept) => {
    const docToUpdate = doc(firestore, collectionName, agreementId);
    //Save userId
    if (accept) {
        await updateDoc(docToUpdate, {
            acceptedBy: arrayUnion(userId)
        });
    }
    else {
        await updateDoc(docToUpdate, {
            acceptedBy: arrayRemove(userId)
        });
    }

    //Save history
    const newRec = { userId: userId, accepted: accept, timestamp: new Date() };
    await updateDoc(docToUpdate, {
        acceptedByHistory: arrayUnion(newRec)
    });
};
export const isAccepted = async (collectionName, userId) => {    
    const q = collectionMainQuery(collectionName);
    let accepted = false;
    const qUser = query(q, where('acceptedBy', 'array-contains', userId));
    accepted = !(await getDocs(qUser)).empty;
    return accepted;
};