import React, { useState, useEffect, useMemo } from 'react';
import './NewScheduleDetails.css';
import { useParams, useNavigate } from 'react-router-dom';
import Select from 'react-dropdown-select';
import { useSelector, useDispatch } from 'react-redux';
import CreateCustContractsEntriesRequest from '../../models/requests/CreateCustContractsEntriesRequest';
import { analytics } from '../../firebase/Firebase';
import { Table } from 'react-bootstrap';
// import topPic from './resources/newScheduleTopPi2c.jpg';
import { logEvent } from 'firebase/analytics';
import { createScheduleSignup, getCustomerSignupByScheduleId } from '../../store/lrnAPI/custContractsEntries';
import { getNewSchedules } from '../../store/actions/lrnAPI';
import NewScheduleDetailsBundledItems from './NewScheduleDetailsBundledItems/NewScheduleDetailsBundledItems';
import useNewScheduleAutoSignup from '../../hooks/useNewScheduleAutoSignup';
import { setError, setInfo } from '../../store/actions/ui';
import CustomerSearch from '../../containers/CustomerSearch/CustomerSearch';
import { getItemFromCustSignup, LOG_EVENT_ITEM_LIST_IDS_NEW_CLASSES_PURCHASED } from '../../analytics/events';

const NewScheduleDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

	
    let { id } = useParams();
    const rec = useSelector((state) => state.eventsStore.newSchedules).filter(
        (x) => x.Id === parseInt(id)
    )[0];
    const userData = useSelector((store) => store.customerStore.customerData);
    const apiToken = useSelector((store) => store.uiStore.apiToken);
    const userId = useSelector((store) => store.customerStore.customerData.id);
    const [partnerName, setPartnerName] = useState('Zapisz partnera');

    const loadData = () => {
        dispatch(getNewSchedules(userId, apiToken));
    };

    useEffect(() => {
        if (!rec) {
            loadData();
        }
    }, [rec]);

    const analyticsItems = useMemo(
        () =>
            rec
                ? getItemFromCustSignup(rec,0,LOG_EVENT_ITEM_LIST_IDS_NEW_CLASSES_PURCHASED,0,1)                 
                : [],
        [rec]
    );

    const [schedulesFromBundle, setSchedulesFromBundle] = useState([]);
    const [bundleLoadingDone, setBundleLoadingDone] = useState(false);
    const [autoSelectedPaymentId, setAutoSelectedPaymentId] = useState(null);
    const [autoSelectedPriceTypeId, setAutoSelectedPriceTypeId] = useState(null);

    const [form, setForm] = useState({
        paymentType: 0,
        preferedStartDate: new Date().toISOString().slice(0, 10),
    });

    const [formErrors, setformErrors] = useState({
        preferedStartDate: false,
        paymentType: false,
    });

    const [showPartnerSearch, setShowPartnerSearch] = useState(false);




    const cancel = () => {
        logEvent(analytics, 'remove_from_cart', {
            items: analyticsItems,
            value: 1,
        });
        navigate(-1);
    };

    const onChangeHandler = (event) => {
        const { name, value } = event.currentTarget;
        setForm((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const onPartnerChange = (res) => { 
        if(res.id)
        {
            setForm({...form,partnerId:res.id});
            setPartnerName(res.name);
            setShowPartnerSearch(false);
        }
    };

    const setPaymentType = (selData) => {
        logEvent(analytics, 'add_payment_info', {
            items: analyticsItems,
            payment_type: selData[0].label,
        });
        setForm((prev) => ({ ...prev, paymentType: selData[0].value }));
    };

    const validateForm = (paymentIdOverride) => {
        let isValid = false;
        //let keyRegex = new RegExp(/[\d]/);
        //let dateRegex = new RegExp(/[\d]{4}-[\d]{2}-[\d]{2}/);

        //console.log(
        // 	'keyRegex.test(form.paymentType',
        // 	keyRegex.test(form.paymentType)
        // );

        //console.log('form.paymentType', form.paymentType)
        //console.log('form.preferedStartDate ', form.preferedStartDate )
        
        if ((!form.paymentType || Number(form.paymentType) === 0) && (!autoSelectedPaymentId && !paymentIdOverride)){
            setformErrors((prev) => ({ ...prev, paymentType: true }));
        }
        else {
            setformErrors((prev) => ({ ...prev, paymentType: false }));
            isValid = true;
        }
        return isValid;
        // if (!form.preferedStartDate || dateRegex.test(form.preferedStartDate))
        // 	setformErrors((prev) => ({ ...prev, preferedStartDate: true }));
        // else setformErrors((prev) => ({ ...prev, preferedStartDate: false }));
    };

    const createNewSchedule = (paymentIdOverride, priceTypeIdOverride) => {
        
        if(validateForm(paymentIdOverride))
        {
            const startDate = new Date(form.preferedStartDate).toISOString();
            const paymentTypeId =  autoSelectedPaymentId ?? (!form.paymentType ? paymentIdOverride : parseInt(form.paymentType));
            const partnerId = !form.partnerId ? userData.id : form.partnerId;

            var req = new CreateCustContractsEntriesRequest(
                userData.id,
                paymentTypeId,
                autoSelectedPriceTypeId ?? priceTypeIdOverride ?? 0,
                rec.Id,
                startDate,
                null,
                partnerId
            );

          
            logEvent(analytics, 'add_to_cart', {
                items: analyticsItems,
                value: 1,
            });
            
            dispatch(createScheduleSignup(req, apiToken))
                .then((id) => {                    
                    createNewScheduleForPartner(req);
                    createNewScheduleForBundle(paymentIdOverride, priceTypeIdOverride);
                    navigate('/myevents/'+id);
                })
                .catch((errMsg) => {
                    dispatch(setError({message:errMsg.message}));
                });
        }
    };

    const createNewScheduleForPartner = (createCustContractsEntriesRequest) => {
        
        if(createCustContractsEntriesRequest.partnerId !== createCustContractsEntriesRequest.customerId)
        {
            var ccceReq = {...createCustContractsEntriesRequest};
            ccceReq.customerId = createCustContractsEntriesRequest.partnerId;
            ccceReq.partnerId = createCustContractsEntriesRequest.customerId;

            logEvent(analytics, 'add_to_cart', {
                items: analyticsItems,
                value: 1,
            });
 
            dispatch(createScheduleSignup(ccceReq, apiToken))
                .then(() => {                    
                    dispatch(setInfo('Zapis partnera został wykonany'));
                })
                .catch((errMsg) => {
                    dispatch(setError({message:errMsg}));
                });
        }
    };

    const createNewScheduleForBundle = (paymentIdOverride, priceTypeIdOverride) => { 
        
        const bundleSched = schedulesFromBundle.filter(x => x.ScheduleBundles.PurchaseAutoSelect === true);
        
        if(bundleSched.length > 0){
            const partnerId = !form.partnerId ? userData.id : form.partnerId;
            bundleSched.forEach((b)=>{
                
                const startDate = new Date(form.preferedStartDate).toISOString();
                var req = new CreateCustContractsEntriesRequest(
                    userData.id,
                    Number(autoSelectedPaymentId ?? paymentIdOverride ?? form.paymentType),
                    Number(autoSelectedPriceTypeId ?? priceTypeIdOverride ?? 0),
                    b.Id,
                    startDate,
                    null,
                    partnerId

                );
                dispatch(createScheduleSignup(req, apiToken))
                    .then(()=>{
                        createNewScheduleForPartner(req);
                    });     
            });
        }
    };
    
    
    useNewScheduleAutoSignup(rec, setAutoSelectedPaymentId, setAutoSelectedPriceTypeId, createNewSchedule, bundleLoadingDone);
    
    
    
    


    useEffect(() => {
        //if signup for the schedule already exists go to signup
        if(id && userId)
        {
            dispatch(getCustomerSignupByScheduleId(userId, id))
                .then((cceId)=>{
                    
                    if(cceId.data)
                    {
                        navigate('/myevents/'+cceId.data);

                    }
                })
                .catch((err)=>{
                    console.log('err', err);
                });
        }
    }, [id, userId]);
    
    if (!rec) {
        return null;
    }

    return (
        <div className='newscheduledetils-container'>
            <div className='newscheduledetils-top-picture'>
                {/* <img src={topPic} alt={'Pic'} /> */}
            </div>
            <div className='newscheduledetils-title'></div>
            <div className='newscheduledetils-table'>
                <Table>
                    <thead>
                        <tr>
                            <th colSpan='3'>
                                {rec.EventName +
									' ' +
									rec.Day +
									' ' +
									rec.EventHour +
									' ' +
									rec.Level + 
                                    ' ' +
                                    rec.EventDetails}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* <tr>
							<td>Data rozpoczęcia</td>
							<td>{getShortDate(rec.StartDate)}</td>
						</tr> */}
                        <tr>
                            <td>Poziom</td>
                            <td>{rec.Level}</td>
                        </tr>
                        <tr>
                            <td>Nauczyciele</td>
                            <td>{rec.Teacher1Long}</td>
                            <td>{rec.Teacher2Long}</td>
                        </tr>
                        {!autoSelectedPaymentId ? 
                            <tr>
                                <td>Typ płatności</td>
                                <td colSpan={2}>
                                    <Select
                                        name='paymentType'
                                        placeholder='Typ płatności'
                                        options={rec.PaymentTypes}
                                        onChange={(data) => setPaymentType(data)}
                                    />
                                    {formErrors.paymentType && <p>Wprowadź poprawną wartość</p>}
                                </td>
                            </tr>
                            :null
                        }
                        <tr>
                            <td>Partner</td>
                            <td colSpan={2}>                                
                                <button className='btn main' onClick={()=>setShowPartnerSearch(!showPartnerSearch)} >{partnerName}</button>                                
                            </td>                            
                        </tr>
                        {showPartnerSearch ? 
                            <tr>
                                <td colSpan={3}>
                                    <CustomerSearch onPartnerIdFound={onPartnerChange}/>
                                </td>
                            </tr>
                            :null}
                        {formErrors.partnerId && (
                            <p>Wprowadź poprawną wartość</p>
                        )}
                        <tr>
                            <td>Preferowana data</td>
                            <td colSpan={2}>
                                <input
                                    type='date'
                                    name='preferedStartDate'
                                    onChange={(event) => onChangeHandler(event)}
                                    value={form.preferedStartDate}></input>

                                {formErrors.preferedStartDate && (
                                    <p>Wprowadź poprawną wartość</p>
                                )}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
            <div className='newscheduledetils-buttons'>
                <div className='newscheduledetils-buttons-cancel cancel'>
                    <button className='cancel' onClick={cancel}>
						Anuluj
                    </button>
                </div>
                <div className='newscheduledetils-buttons-ok'>
                    <button className='main confirm' onClick={()=>createNewSchedule()}>
						Zapisz mnie
                    </button>
                </div>
            </div>
            <div className='newSchedule-details-bundle'>
                <NewScheduleDetailsBundledItems 
                    scheduleId={id} 
                    setSchedulesFromBundle={setSchedulesFromBundle}
                    setBundleLoadingFinished={setBundleLoadingDone} />
            </div>
            
        </div>
    );
};

export default NewScheduleDetails;
