import React from 'react';
import './RadioButtons.css';
import RadioButton from '../RadioButton/RadioButton';
const RadioButtons = (props) => {
    return (
        <div
            id={props.id}
            className={
                props.border
                    ? 'radiobtns-containner radiobtns-border'
                    : 'radiobtns-containner'
            }>
            {props.title && props.title !== '' ? (
                <div className='radiobtns-title'>{props.title}</div>
            ) : null}
            <div className='radiobtns-buttons'>
                {props.selectList && props.selectList.length > 0 ? (
                    props.selectList.map((x) => (
                        <RadioButton
                            id={x.value}
                            key={x.value}
                            name={props.id}
                            value={x.value}
                            onChange={(id) => props.onChange(id)}
                        >
                            {x.label}
                        </RadioButton>
                    ))
                ) : (
                    <div> Brak danych </div>
                )}
            </div>
        </div>
    );
};

export default RadioButtons;
