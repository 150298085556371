import React from 'react';

const Wallet = () => <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.6152 7.50121L8.53345 0.418709C8.25443 0.139689 7.88887 0 7.52331 0C7.15774 0 6.79182 0.139689 6.51316 0.418709L2.6349 4.29625C2.07686 4.85429 2.07686 5.77646 2.6349 6.3345L3.27768 6.99488H4.7604L3.37644 5.59296C3.27948 5.49564 3.26512 5.37391 3.26512 5.31502C3.26512 5.25612 3.27948 5.1387 3.37644 5.04174L3.88851 4.54547L6.32212 6.99488H8.65662L5.05558 3.37588L7.25434 1.16851C7.3513 1.07155 7.46441 1.05324 7.52331 1.05324C7.5822 1.05324 7.69531 1.06544 7.79227 1.16204L14.8744 8.24346C14.9717 8.34078 14.9864 8.45282 14.9864 8.51207C14.9864 8.57096 14.9613 8.68372 14.864 8.78103L14.4625 9.17281V10.6555L15.6059 9.52257C16.1636 8.96346 16.1736 8.05925 15.6152 7.50121Z" fill="currentColor"/>
    <path d="M12.2925 7.65881H2.27619C1.48725 7.65881 0.8125 8.29442 0.8125 9.08336V12.8718H13.7034V9.08336C13.7034 8.29442 13.0815 7.65881 12.2925 7.65881ZM4.31299 11.582C4.03325 11.582 3.78009 11.4706 3.59336 11.2911C3.40663 11.4706 3.1531 11.582 2.87408 11.582C2.30024 11.582 1.83449 11.1169 1.83449 10.542C1.83449 9.96782 2.29989 9.50243 2.87408 9.50243C3.15346 9.50243 3.40699 9.61339 3.59336 9.79294C3.78009 9.61339 4.03325 9.50243 4.31299 9.50243C4.88719 9.50243 5.35258 9.96782 5.35258 10.542C5.35258 11.1169 4.88719 11.582 4.31299 11.582Z" fill="currentColor"/>
    <path d="M0.8125 14.5672C0.8125 15.3561 1.48689 16 2.27619 16H12.2925C13.0815 16 13.7038 15.3561 13.7038 14.5672V14.4832H0.8125V14.5672Z" fill="currentColor"/>
</svg>;


export default Wallet;
