import React, { useState } from 'react';
import { status } from '../Utils';
import './Email.css';
import { setError, showProgressBar } from '../../../store/actions/ui';
import { useDispatch } from 'react-redux';
// import PINField from '../../PINField/PINField';
import { auth } from '../../../firebase/Firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';



const Email = (props) => {
    const {
        handleChangeStatus,
        getTokenAndContinue,
        userLoginInfo,
        userInfoUpdate,
        children,
    } = props;
    const dispatch = useDispatch();	
    const [pass, setPass] = useState();

    const handleSignIn = (password,e) => {
        e.preventDefault();
        fireBaseLogin(userLoginInfo.email, password??pass);
    };

    // const IDToken = () => {
    // 	auth.onAuthStateChanged(function (user) {
    // 		if (user) {
    // 			user.getIdToken().then(function (idToken) {
    // 				setUserLoginInfo((prev) => ({
    // 					...prev,
    // 					token: idToken,
    // 				}));
    // 			});
    // 		}
    // 	});
    // };

    const fireBaseLogin = (login, password) => {
        if (!login || !password) {
            dispatch(setError({ message: 'Podaj ponownie login i hasło.' }));
            return;
        }
        dispatch(showProgressBar(true));

        signInWithEmailAndPassword(auth, login, password)
            .catch((error) => {
                let userMessage = '';
                switch (error.code) {
                case 'auth/user-not-found':
                    userMessage = 'Użytkownik nie posiada loginu';
                    break;
                case 'auth/wrong-password':
                    userMessage = 'Błędne hasło';
                    break;
                case 'auth/too-many-requests':
                    userMessage =
							'Dostęp tymczasowo zablokowany z powodu zbyt dużej liczby nieudanych prób logowania.';
                    break;
                default:
                    userMessage = error.message;
                    break;
                }
                dispatch(setError({ message: userMessage }));
                dispatch(showProgressBar(false));
            })
            .then((result) => {
                if (result) {
                    //IDToken();
                    getTokenAndContinue(result, result.credential);
                }
                dispatch(showProgressBar(false));
            });
    };

    // const fireBaseSignUp = () => {
    // 	auth
    // 		.createUserWithEmailAndPassword(
    // 			userLoginInfo.email,
    // 			userLoginInfo.password
    // 		)
    // 		.catch((error) => {
    // 			if (error.code === 'auth/weak-password') {
    // 				dispatch(setError({ message: error.message }));
    // 			}
    // 		})
    // 		.then((result) => {

    // 			if (result) {
    // 				getTokenAndContinue(result);
    // 			}

    // 		});
    // };

    return (
        <div className='login-password-container'>
            <form onSubmit={(e) => handleSignIn(null,e)}>
                <div className='login-password-login'>
                    <div className='form-group'>
                        <label>Email</label>
                        <input
                            type='email'
                            className='form-control'
                            placeholder='Email'
                            name='email'
                            value={userLoginInfo.email}
                            onChange={(event) =>
                                userInfoUpdate({ email: event.currentTarget.value })
                            }
                            autoFocus={userLoginInfo.email ? false : true}
                        />
                    </div>
                </div>
                <div className='login-password-pass'>
                    <div className='form-group'>
                        <input type='password'
                            className='form-control'                        
                            name='pass'                                                
                            autoFocus={userLoginInfo.email ? false : true}
                            placeholder='Podaj hasło' 
                            onChange={(val)=>setPass(val.currentTarget.value)}></input>
                    </div>
                </div>

                {/* <div className='form-group'>
						<div className='custom-control custom-checkbox'>
							<input
								type='checkbox'
								className='custom-control-input'
								id='customCheck1'
							/>
							<label className='custom-control-label' htmlFor='customCheck1'>
								Remember me
							</label>
						</div>
					</div> */}

                {/* <button
				className='btn btn-danger btn-block'
				onClick={() => handleCreatePass()}>
				Nie mam hasła
			</button> */}
                {children}
                <div className='login-password-pass-btn'>
                    <button
                        className='btn btn-primary btn-block submit-button'
                        onClick={(e) => handleSignIn(null,e)}>
				Zaloguj
                    </button>
                    {props.showBackBtn ?
                        <div className='login-password-back'>
                            <button
                                className='btn btn-primary btn-block cancel-button'
                                onClick={() => handleChangeStatus(status.NONE)}>
					Powrót
                            </button>
                        </div>
                        : null
                    }
                </div>
            </form>
        </div>
    );
};

export default Email;
