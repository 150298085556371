import React from 'react';

import './Card.css';

const Card = (props) => {
    return (
        <div className='card' onClick={props.onClick}>
            <div className='card-title'>{props.title}</div>
            <div className={'card-content ' + props.colorClass} style={props.style}>
                {props.children}
                test
            </div>
        </div>
    );
};

export default Card;
