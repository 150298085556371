import React from 'react';
import {  Spinner } from 'react-bootstrap';

import './ButtonWithSpinner.css';

const ButtonWithSpinner = ({id, show, inProgress, onClick, disabled, className, children, spinnerVariant}) => {
    return (
        <div className='buttonWithSpinner-container'>
            {show ? (
                (
                    <>
                        <button
                            id={id}
                            key={id}
                            className={className}
                            onClick={onClick}
                            disabled={disabled}
                        >
                            { inProgress ? (
                                <Spinner
                                    id={id+'-spinner'}
                                    key={id+'-spinner'}
                                    className="toggle-btn-spinner"
                                    animation="border"
                                    role="status"
                                    size="sm"
                                    variant={spinnerVariant??'light'}
                                />
                            ) :children}
                        </button>
                    </>
                )
            ) : null}
        </div>
    );
};

export default ButtonWithSpinner;