import { useEffect } from 'react';
import { setActiveMenu } from '../../../store/actions/ui';
import { useDispatch } from 'react-redux';

const ActiveMenuMonitor = (props) => {
    const dispatch = useDispatch();	    
    
    
    /* eslint-disable */
    useEffect(() => {        
        dispatch(setActiveMenu(props.menuId));    
        return () => {            
        }
    }, [])
    
    return null;
}

export default ActiveMenuMonitor
