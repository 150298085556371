import React from 'react';
import SocialSearchResult from './SocialSearchResult/SocialSearchResult';

import './SocialSearchResults.css';

const SocialSearchResults = (props) => {
    
    const renderResults = () => {
        const results = [];
        props.custSearchResults.map((res) =>
            results.push(
                <SocialSearchResult key={res.Id} data={res} />
            )
        );
        return results;
    };

    return <div className='socialsearch-results-container'>{renderResults()}</div>;
};

export default SocialSearchResults;
