//Libs
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ButtonWithSpinner from '../../components/UI/ButtonWithSpinner/ButtonWithSpinner';
import Input from '../../components/UI/Input/Input';
import { setError } from '../../store/actions/ui';
import { searchPartner } from '../../store/lrnAPI/customers';
import { isEmailValid, isPhoneNumberValid } from '../../utils/regexPatterns';

//CSS
import './CustomerSearch.css';

//Components

const CustomerSearch = ({onPartnerIdFound}) => {
    const dispatch = useDispatch();

    const [form, setForm] = useState({});
    const [searchInProgress, setSearchInProgress] = useState(false);
    const [resultTxt, setResultTxt] = useState();
    const [searchEnabled, setSearchEnabled] = useState(false);

    const isFormValid = () => { 
        return (isPhoneNumberValid(form.PhoneNo) || isEmailValid(form.Email)) && form.FirstName && form.LastName ;  
    };

    const onPartnerSearchClick = () => { 
        console.log('form', form);
        setSearchInProgress(true);
        dispatch(searchPartner(form))
            .then((resp)=>{
                setSearchInProgress(false);
                if(resp.status == 200)
                {
                    setResultTxt();
                    onPartnerIdFound({name:form.FirstName+ ' ' + form.LastName, id: parseInt(resp.data)});                    
                }
                if(resp.status == 204)
                {
                    setResultTxt('Niestety nie udało się znaleźć osoby. Pamiętaj, aby dodać partnera upewnij się, że ma on lub ona aktywne konto w naszym systemie.');
                }
            })
            .catch((err)=>{
                setSearchInProgress(false);
                dispatch(setError(err));
            });
    };

    useEffect(() => {
        setSearchEnabled(isFormValid());
    }, [form.PhoneNo, form.Email, form.FirstName, form.LastName]);
    

    return (
        <div className='customer-search-container'>
            
            <div className='customer-search-inputs-row'>
                <Input type={'text'} onChange={(val)=>setForm({...form,FirstName:val})}>Imię</Input>
                <Input type={'text'} onChange={(val)=>setForm({...form,LastName:val})}>Nazwisko</Input>
            </div>
            <div className='customer-search-inputs-row'>
                <Input type={'email'} 
                    onChange={(val)=>setForm({...form,Email:val})}
                    validationFunction={isEmailValid}                    
                >Email</Input>            
            </div>
            <div className='customer-search-inputs-row'>
                <Input type={'tel'} 
                    onChange={(val)=>setForm({...form,PhoneNo:val})}
                    validationFunction={isPhoneNumberValid}
                    invalidText='Podaj numer z prefiksem +48'                  
                >Nr. telefonu</Input>
            </div>
            
            <ButtonWithSpinner id={'customer-search-inputs-btn'} 
                key={'customer-search-inputs-btn'} 
                className='btn'
                inProgress={searchInProgress}
                show={true}
                spinnerVariant='dark'
                onClick={onPartnerSearchClick}
                disabled={!searchEnabled}
            >Szukaj</ButtonWithSpinner>
            
            <div className='customer-search-result'>
                {resultTxt}
            </div>
        </div>
    );
};

export default CustomerSearch;