import React, { useEffect, useState } from 'react';
import { Spinner, Table } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setError } from '../../store/actions/ui';
import {getShortDate} from '../../utils/helpers/dateTimeHelper';
import { loadDepositHistory } from '../../store/lrnAPI/custEntries';


import './DepositsHistory.css';

const DepositsHistory = () => {
    
    const dispatch = useDispatch();
    
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
  
    const loadData = () => { 
        setLoading(true);
   
        
        dispatch(loadDepositHistory())

            .then((r)=>{
                setList(r.data);
                setLoading(false);
               
            })
            .catch((e)=>{
                dispatch(setError(e));
                setLoading(false);
        
            });    
    };


    const renderList = () => { 
        const res = [];
        console.log('list', list);
        if(list && list.length > 0)
        {
            list.map((item)=>{
                console.log('item', item);
                res.push(<tr id={'depositsHistory-item-'+ item.Id}
                    key={'depositsHistory-item-'+ item.Id}
                >
                    
                    <td>
                        <div className='depositsHistory-list-item-col depositsHistory-list-item-border '>
                            {getShortDate(item.ChargeDate)}
                        </div>
                    </td>
                    <td>
                        <div className='depositsHistory-list-item-col depositsHistory-list-item-border '>
                            {(!item.ScheduleName?'Brak':item.ScheduleName) + ' (' + getShortDate(item.EventDate) + ')'} 
                        </div>
                    </td>
                    <td>
                        <div className='depositsHistory-list-item-col depositsHistory-list-item-border'>
                            
                            <div className={item.Amount > 
                                0 
                                ? 'depositsHistory-list-item-green' 
                                : 'depositsHistory-list-item-red'}>  
                                <b>{item.Amount} PLN</b> 
                            </div> 
                        </div>         
                    </td>            
                </tr>      
                     
                );
            });
            return res;
        }
        else
        {
            return false;
        }

    };

    useEffect(() => {
        loadData();
    }, []);
    

    return (
        <div className='depositsHistory-container'>
            <div className='depositsHistory-title app-text-lg '>
                Historia kaucji
            </div>
            <div className='depositsHistory-list'>
                {loading ? 
                    <Spinner
                        id={'depositsHistory-spinner'}
                        animation="border"
                        role="status"
                    />
                    : null
                }
                <Table>
                    <thead>
                        <th>Data pobrania</th>
                        <th>Grupa (data)</th>
                        <th>Kwota</th>
                        <th>Odrobienie do</th>
                    </thead>
                    <tbody>
                        {renderList()}    
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default DepositsHistory;