import { setEventBeingChanged, setSignupsArray } from '../actions/events';
import { getCustomerProposals, sendRequest } from '../actions/lrnAPI';
import { logout, setError, setMessage, setWarning, showProgressBar } from '../actions/ui';
import PropTypes from 'prop-types';
import CreateCustContractsEntriesRequest from '../../models/requests/CreateCustContractsEntriesRequest';


export const createScheduleSignup = (request, token, eventIdBeingChanged) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            if (!request) {
                dispatch(setError({ message: 'Missing request data' }));
                return false;
            }
            dispatch(showProgressBar(true));
            if (eventIdBeingChanged)
                dispatch(setEventBeingChanged(eventIdBeingChanged));

            sendRequest('CustContractsEntries/createsignup', request, token, 'post')
                .then((response) => {					
                    switch (response.status) {
                    case 200:
                        dispatch(
                            setMessage({
                                timestamp: new Date().toISOString(),
                                message:
										'Zapis wykonany. Szczegóły możesz sprawdzić w Kalendarzu lub Zapisach.',
                            })
                        );
                        if (request.EntryType === 2) {
                            dispatch(
                                getCustomerProposals(
                                    request.CustomerId,
                                    request.PrefferedStartDate,
                                    token
                                )
                            );
                        }
                        break;
                    default:
                        dispatch(setError(response));
                        break;
                    }
                    dispatch(showProgressBar(false));
                    if (eventIdBeingChanged)
                        dispatch(setEventBeingChanged(eventIdBeingChanged, true));
                    resolve(response.data);
                })
                .catch((err) => {					
                    switch (err.status) {
                    case 302:
                        dispatch(setWarning('Zapis na zajęcia już istnieje.'));
                        break;
                    case 406:
                        dispatch(setError({message:err.data}));
                        break;
                    default:
                        dispatch(setError(err));
                        break;
                    }
                    dispatch(showProgressBar(false));
                    if (eventIdBeingChanged)
                        dispatch(setEventBeingChanged(eventIdBeingChanged, true));
                    reject(err);
                });
        });
    };
};
createScheduleSignup.prototype = {
    request: PropTypes.instanceOf(CreateCustContractsEntriesRequest),
};


export const updateCustomerSignup = (signupId, data) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            if (!signupId) {
                dispatch(setError({ message: 'Missing event id' }));
                reject();
                return false;
            }

            dispatch(showProgressBar(true));

            const state = getState();
            const apiToken = state?.uiStore?.apiToken;
            const endPoint = 'CustContractsEntries/'+signupId+'/change';
            //'+data.fieldName+'/'+data.value;
        
            let dataUpd;
            if(data.IntVal)
                dataUpd = { [data.FieldName]: parseInt(data.IntVal)};
            if(data.StrVal){
                dataUpd = { [data.FieldName]: data.StrVal};
            }
                

            return sendRequest(endPoint, data, apiToken, 'patch')
                .then((response) => {					
                    switch (response.status) {
                    case 200:
                        dispatch(setSignupsArray(state.eventsStore.signups.map((item) => item.Id === signupId
                            ? {
                                ...item,
                                ...dataUpd,
                            } : item)));
                        dispatch(showProgressBar(false));
                        dispatch(setMessage({
                            timestamp: new Date().toISOString(),
                            message: 'Zmiana zapisana',
                        }));
                        resolve();
                        break;
                    default:
                        dispatch(showProgressBar(false));
                        dispatch(setError(response));
                        reject();
                        break;
                    }
                
                })
                .catch((err) => {					
                    switch (err.status) {
                    default:
                        dispatch(showProgressBar(false));
                        dispatch(setError(err));
                        reject();
                        break;
                    }
                });
       
        });

    };
};

export const getCustomerSignups = (custId, apiToken, id) => {
    return (dispatch) => {
        //save token to storage
        if (!custId) {
            dispatch(setError({ message: 'Missing customer Id to get Signups' }));
            return false;
        }
        dispatch(showProgressBar(true));

        let endPoint = 'CustContractsEntries/customer/' + custId;
        if(id)
            endPoint = endPoint + '/'+id;

        sendRequest(
            endPoint,
            null,
            apiToken,
            'get'
        )
            .then((response) => {
                dispatch(showProgressBar(false));
                switch (response.status) {
                case 200:
                    dispatch(setSignupsArray(response.data));
                    break;
                case 401:
                    dispatch(logout());
                    break;
                default:
                    dispatch(setError(response));
                    break;
                }
            })
            .catch((err) => {
                dispatch(showProgressBar(false));
                switch (err.status) {
                case 401:
                    dispatch(logout());
                    break;
                default:
                    break;
                }
                dispatch(setError(err));
            });
    };
};

export const getCustomerSignupByScheduleId = (custId, scheduleId) => {
    return (dispatch, getState) => {
    
        if (!custId || !scheduleId) {
            dispatch(setError({ message: 'Missing custId or scheduleId' }));
            return false;
        }

        const state = getState();
        const apiToken = state.uiStore.apiToken;

        let endPoint = 'CustContractsEntries/customer/' + custId + '/schedule/'+scheduleId;

        return sendRequest(endPoint, null, apiToken, 'get');
    };
};
