import React from 'react';
import './MySignupsItemDetails.css';
import SandGlassSvg from '../../Icons/resources/svg/SandGlassSvg';
//import HandWithCoin from '../../../Icons/resources/svg/HandWithCoin';
import Wallet from '../../Icons/resources/svg/Wallet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons';

const MySignupsItemDetails = (props) => {
    return (
        <div className='signup-event-details-container'>
            <div className='signup-event-details-date'>
                <div className='signup-event-details-date-icon'>
                    <SandGlassSvg />
                </div>
                <div className='signup-event-details-date-text'>{props.dateText}</div>
            </div>
            {props.paymentText ? <div className='signup-event-details-amount'>
                <div className='signup-event-details-amount-icon'>
                    <FontAwesomeIcon icon={faCalendarCheck} size='sm'/>
                </div>
                <div className='signup-event-details-amount-text'>
                    {props.paymentText}
                </div>
            </div> : null}
            <div className='signup-event-details-payment'>
                <div className='signup-event-details-payment-icon'>
                    <Wallet />
                </div>
                <div className='signup-event-details-payment-text'>
                    {props.paymentTypeText}
                </div>
            </div>
        </div>
    );
};

export default MySignupsItemDetails;
