import React, { useEffect } from 'react';
import './SocialSearch.css';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../../components/UI/Input/Input';
import { setCustSearchResults } from '../../../store/actions/customers';
import { searchContacts } from '../../../store/actions/lrnAPI';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import SocialSearchResults from './SocialSearchResults/SocialSearchResults';
import { useState } from 'react';
import { analytics } from '../../../firebase/Firebase';
import { logEvent } from 'firebase/analytics';

const SocialSearch = () => {
    const dispatch = useDispatch();	

    const [currSearchTxt, setCurrSearchTxt] = useState();	
    //const [searchBtnActive, setsearchBtnActive] = useState(false)

    const apiToken = useSelector((store) => store.uiStore.apiToken);
    const custSearchResults = useSelector(
        (store) => store.customerStore.custSearchResults
    );
    const userId = useSelector((store) => store.customerStore.customerData.id);

	



    const onUserIdChange = (input) => {
        if (input.length > 2) {
            //setsearchBtnActive(true);
            search(input);
        } else {
            dispatch(setCustSearchResults([]));
            //setsearchBtnActive(false);
        }
    };

    const logSearchPhrase = () => {		
        logEvent(analytics, 'search', {
            screen_name: 'social_contact',
            search_term: currSearchTxt,
        });
    };

    const search = (searchString) => {		
        setCurrSearchTxt(searchString);
        dispatch(searchContacts(searchString, apiToken, userId));
    };

    /* eslint-disable */
	useEffect(() => {
		dispatch(setCustSearchResults([]));
		return () => {};
	}, []);
	//const sendFriendRequest = () =>{};
	return (
		<div className='socialsearch-container'>
			<div className='socialsearch-bar'>
				<Input
					placeholder='Nazwa użytkownika'
					onChange={onUserIdChange}
					onBlur={logSearchPhrase}
					icon={faSearch}
					waitForTypingDelay={400}
					></Input>
			</div>

			{/* <div className='socialsearch-filters-label'>Zaawansowane</div> */}
			<div className='socialsearch-filters'></div>
			{/* <div className='socialsearch-filters-button'>
				<button disabled={!searchBtnActive} className='confirm' onClick={search}>
					Szukaj
				</button>
			</div> */}

			<div className='socialsearch-results'>
				<SocialSearchResults custSearchResults={custSearchResults} />
			</div>
		</div>
	);
};

export default SocialSearch;
