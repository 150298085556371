import React from 'react';
import './RadioButton.css';
const RadioButton = (props) => {
    return (
        <div className='radiobtn-containner'>
            <div className='radiobtn-item'>
                <input
                    type='radio'					
                    id={props.name+props.id}
                    name={props.name}
                    value={props.value}					
                    onChange={(e) => props.onChange(e)}					
                />
                <label htmlFor={props.name+props.id}> {props.children}</label>
            </div>			
        </div>
    );
};

export default RadioButton;
