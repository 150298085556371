import React from 'react';

const BorderSvg = () => <svg width="309" height="285" viewBox="0 0 309 285" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
        <path d="M20 39.024L20 123.851L20.916 124.221C34.2448 129.592 33.7134 143.636 20 148.467L20 241.976C20 253.587 32.9654 263 48.9591 263H260.041C276.035 263 289 253.587 289 241.976V149.933C274.121 144.633 274.062 129.249 288.9 123.889L289 123.851V39.024C289 27.4127 276.035 18 260.041 18L48.9591 18C32.9654 18 20 27.4127 20 39.024Z" fill="white"/>
    </g>
    <path opacity="0.1" d="M32 137.5L275 136.5" stroke="#383336" strokeDasharray="5 5"/>
    <defs>
        <filter id="filter0_d" x="0" y="0" width="309" height="285" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset dy="2"/>
            <feGaussianBlur stdDeviation="10"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
    </defs>
</svg>;




export default BorderSvg;
