import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../../components/UI/Input/Input';
import SocialContact from './SocialContact/SocialContact';
import {
    customerContactsInviteResponse,
    customerSocialScoreSet,
    getCustomerContacts,
} from '../../../store/actions/lrnAPI';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import './SocialContacts.css';
import { setCustContacts } from '../../../store/actions/customers';

const SocialContacts = () => {
    const dispatch = useDispatch();
    const contacts = useSelector((state) => state.customerStore.contacts);
    const invites = useSelector((state) => state.customerStore.invites);
    const userId = useSelector((store) => store.customerStore.customerData.id);
    const apiToken = useSelector((store) => store.uiStore.apiToken);

    /* eslint-disable */
	const [pageSize] = useState(50);
	const [searchString, setSearchString] = useState();

	const searchInputChange = (searchString) => {
		setSearchString(searchString);
		if (searchString.length > 2 || searchString.length === 0) {
			loadCustContacts(searchString);
		} else {
			dispatch(setCustContacts());
		}
	};

	const loadCustContacts = (searchString) => {
		dispatch(
			getCustomerContacts(userId, apiToken, pageSize, searchString, true)
		);
		dispatch(
			getCustomerContacts(userId, apiToken, pageSize, searchString, false)
		);
	};

	const onInviteAcceptClick = (inviteId) => {
		dispatch(
			customerContactsInviteResponse(userId, apiToken, inviteId, true)
		).then(() => {
			loadCustContacts();
		});
	};
	const onInviteDeclineClick = (inviteId) => {
		dispatch(
			customerContactsInviteResponse(userId, apiToken, inviteId, false)
		).then(() => {
			loadCustContacts();
		});
	};

	const onScoreClick = (custId, catNo, contactsIdx, contactItem) => {
		dispatch(
			customerSocialScoreSet(
				userId,
				apiToken,
				custId,
				catNo,
				contactsIdx,
				contactItem
			)
		);
	};

	const searchStringTooShortResult = () => {
		let list = [];
		if (searchString && searchString.length < 3) {
			list.push(
				<div key='noData' className='app-text-muted'>
					Wpisz dokładniejsze wyszukiwanie
				</div>
			);
		}
		return list;
	};

	const renderContacts = () => {
		let list = [];
		if (!contacts) {
			return searchStringTooShortResult();
		}
		contacts.map((r, idx) =>
			list.push(
				<SocialContact
					key={r.Id}
					data={r}
					update={r.reduxUpdate}
					onSocialScoreClick={(cat) =>
						onScoreClick(r.CustId, cat, idx, r)
					}></SocialContact>
			)
		);
		return list;
	};

	const renderInvites = () => {
		let list = [];
		if (!invites) return searchStringTooShortResult();
		invites.map((r) =>
			list.push(
				<SocialContact
					key={r.Id}
					data={r}
					onAcceptClick={onInviteAcceptClick}
					onDeclineClick={onInviteDeclineClick}
					invite></SocialContact>
			)
		);
		return list;
	};

	const loadedContactsMessage = () => {
		if (contacts && contacts.length === pageSize) {
			return (
				<div className='social-contacts-section-sub'>
					Załadowano część kontaktów ({pageSize})
				</div>
			);
		}
		return null;
	};

	const loadedInvitesMessage = () => {
		if (invites && invites.length === pageSize) {
			return (
				<div className='social-contacts-section-sub'>
					Załadowano część kontaktów ({pageSize})
				</div>
			);
		}
		return null;
	};

	/* eslint-disable */
	useEffect(() => {
		loadCustContacts();
	}, []);

	useEffect(() => {
		renderContacts();
	}, [contacts]);

	return (
		<div className='social-contacts-container'>
			<div className='social-contacts-search'>
				<Input
					placeholder='Szukaj'
					onChange={searchInputChange}
					icon={faSearch}
				/>
			</div>
			<div className='social-contacts-data'>
				<div className='social-contacts-invites'>
					<div className='social-contacts-section app-bg'>
						Zaproszenia
						{loadedInvitesMessage()}
					</div>
					{renderInvites()}
				</div>
				<div className='social-contacts-list'>
					<div className='social-contacts-section app-bg'>
						Kontakty
						{loadedContactsMessage()}
					</div>
					{renderContacts()}
				</div>
			</div>
		</div>
	);
};

export default SocialContacts;
