import React from 'react';
import './StatusPill.css';
import { Badge } from 'react-bootstrap';
import { statusDicEvents, statusDicSignups} from '../../../models/Statuses';



const StatusPill = (props) => {	


    const selectDic = () =>{
        if(props.itemType === 'event') return statusDicEvents;
        if(props.itemType === 'signup')return statusDicSignups;
    };

    const getVariant = (statusId) => {
        if(statusId)return  selectDic().find(e=> e.id===statusId).variant;
    };
    const getText = (statusId) => {
        if(statusId)return  selectDic().find(e=> e.id===statusId).text;
    };
    const getTextColor = (statusId) => {
        if(statusId)return  selectDic().find(e=> e.id===statusId).color;
    };

    return (
        <div className='statusPill-container'>
            <Badge pill bg={getVariant(props.statusId)} text={getTextColor(props.statusId)} 
                onClick={props.onClick}>
                {getText(props.statusId)}{' '}
            </Badge>
        </div>
    );
};

export default StatusPill;
