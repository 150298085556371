import React from 'react';
import './DataViewOptions.css';

const DataViewOptions = (props) => {
    return (
        <div className='dataviewoptions-container'>
            <div className='dataviewoptions-title'></div>
            <div className='dataviewoptions-options'>
                <div className='dataviewoptions-options-item'>
                    <input
                        type='radio'
                        id='1'
                        name='viewType'
                        value='event'
                        checked={props.activeViewId === 1 ? true : false}
                        onChange={()=>props.onChangeDataView(1)}
                    />
					Wydarzenia
                </div>
                <div className='dataviewoptions-options-item'>
                    <input
                        type='radio'
                        id='2'
                        name='viewType'
                        value='day'
                        checked={props.activeViewId === 2 ? true : false}
                        onChange={()=>props.onChangeDataView(2)}
                    />
					Dni
                </div>
                <div className='dataviewoptions-options-item'>
                    <input
                        type='radio'
                        id='3'
                        name='viewType'
                        value='level'
                        checked={props.activeViewId === 3 ? true : false}
                        onChange={()=>props.onChangeDataView(3)}
                    />
					Poziomy
                </div>
            </div>
        </div>
    );
};

export default DataViewOptions;
