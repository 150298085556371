import React from 'react';
import MyAchievements from '../../containers/MyAchievements/MyAchievements';
import './MyAchievementsPage.css';
const MyAchievementsPage = () => {
    return (
        <div className='myachievementspage-container'>
            <MyAchievements/>		
        </div>
    );
};

export default MyAchievementsPage;
