import React from 'react';

import './CalendarEventContainer.css';
import StatusPill from '../../UI/StatusPill/StatusPill';
import { useNavigate } from 'react-router';


const CalendarEventContainer = (props) => {

    const navigate = useNavigate();

    const className = [
        'cal-event-containter',
        props.bgColorClass,
        props.onClick && 'clickable',
    ].filter((item) => !!item).join(' ');


    const goToMySignupEntry = () => {         
        if(props.signupId)
            navigate('/myevents/'+props.signupId);
    };

    return (
        <div
            id={props.id}
            key={props.id}
            onClick={props.onClick}
            className={className}>
            <div className='cal-event-edge-left'></div>
            <div className='cal-event-left'>
                <div className='cal-event-container-left'>
                    <div className='cal-event-time-text'>{props.dayName + ' ' +props.time}</div>
                    <div className='cal-event-time-status'>
                        <StatusPill statusId={props.statusId} itemType='event'/>
                    </div>
                </div>
            </div>
            <div className='cal-event-center clickable' onClick={goToMySignupEntry}>
                <div className='cal-event-center-title'>{props.name + ' ' + props.level} </div>
                
                <div className='cal-event-desc'>
                    <div className='cal-event-desc-line'>
                        {props.nameDetails}
                    </div>
                    <div className='cal-event-desc-line'>
                        {props.teachers} <span className='cal-event-desc-mark'>•</span> {props.room}
                    </div>
                    
                </div>
            </div>
            <div className='cal-event-right'>{props.children}</div>
            <div className='cal-event-bottom'>{props.details}</div>

        </div>
    );
};

export default CalendarEventContainer;
