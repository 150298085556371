// Libs
import { getAgreement, isAccepted, updateDocWithResponse } from './agreements/agreementsRepository';

const privacyPolicyDBCollName = '_privacypolicies';
const termsDBCollName = '_termsofuse';
const mailingAgreementsCollectionName = '_mailingagreements';

//Get data
export const getTermsOfUse = async (apiConfigId) => {
    return getAgreement(apiConfigId, termsDBCollName);
};

export const getPrivacyPolicy = async (apiConfigId) => {
    return getAgreement(apiConfigId, privacyPolicyDBCollName);
};

export const getMailingAgreement = async (apiConfigId) => {
    return getAgreement(apiConfigId, mailingAgreementsCollectionName);
};

//Operations
//ToU
export const acceptTermsOfUse = async (userId, agreementId, apiConfigId) => {
    await updateDocWithResponse(userId, agreementId, apiConfigId+termsDBCollName, true);
};
export const declineTermsOfUse = async (userId, agreementId, apiConfigId) => {
    await updateDocWithResponse(userId, agreementId, apiConfigId+termsDBCollName, false);
};

//Policy
export const acceptPrivacyPolicy = async (userId, agreementId, apiConfigId) => {
    await updateDocWithResponse(userId, agreementId, apiConfigId+privacyPolicyDBCollName, true);
};

export const declinePrivacyPolicy = async (userId, agreementId, apiConfigId) => {
    await updateDocWithResponse(userId, agreementId, apiConfigId+privacyPolicyDBCollName, false);
};

//Mailing
export const acceptMailing = async (userId, agreementId, apiConfigId) => {
    await updateDocWithResponse(userId, agreementId, apiConfigId+mailingAgreementsCollectionName, true);
};
export const declineMailing = async (userId, agreementId, apiConfigId) => {
    await updateDocWithResponse(userId, agreementId, apiConfigId+mailingAgreementsCollectionName, false);
};

//Flags
export const isTermsOfUseAccepted = (userId, apiConfigId) => {
    return isAccepted(apiConfigId+termsDBCollName, userId);
};

export const isPrivacyPolicyAccepted = (userId, apiConfigId) => {
    return isAccepted(apiConfigId+privacyPolicyDBCollName, userId);
};

export const isMailingAccepted = (userId, apiConfigId) => {
    return isAccepted(apiConfigId+mailingAgreementsCollectionName, userId);
};
