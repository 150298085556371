import React, { useState, useEffect, useRef } from 'react';


import 'react-phone-number-input/style.css';
//import Countdown from 'react-countdown';
import './Phone.css';
import { setError } from '../../../store/actions/ui';
import { useDispatch } from 'react-redux';
import { auth } from '../../../firebase/Firebase';
import { RecaptchaVerifier, signInWithPhoneNumber, PhoneAuthProvider } from 'firebase/auth';


const InputPhone = ({ getTokenAndContinue, userLoginInfo, userInfoUpdate, needToUpdate, setNeedToUpdate }) => {
    //for testing purposes. this turns CAPTHA Off
    //firebase.auth().settings.appVerificationDisabledForTesting = true;
	
    auth.languageCode = 'pl';//firebase.auth().useDeviceLanguage();

    const isUnmounted = useRef(false);
    const [sendOTP, setSendOTP] = useState(false);
    const [otp, setOtp] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const dispatch = useDispatch();
    const [formErrors, setformErrors] = useState({phoneNumber: false});

    /* eslint-disable */
	useEffect(() => {
		// window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('loginBtn', {
		// 	'size': 'invisible',
		// 	'callback': function(response) {
		// 	  // reCAPTCHA solved, allow signInWithPhoneNumber.
		// 	  handleLogin();
		// 	}
		//   });
		window.recaptchaVerifier = new RecaptchaVerifier(
			'recaptcha-container',
			{
				size: 'normal',
				callback: (response) => {
					// reCAPTCHA solved, allow signInWithPhoneNumber.
					// ...
					setIsButtonDisabled(false);
				},
				'expired-callback': (response) => {
					// Response expired. Ask user to solve reCAPTCHA again.
					// ...
					setIsButtonDisabled(true);
					alert('Recaptcha Exprie Please try agian');
				},
			},
			auth
		);
		window.recaptchaVerifier.render().then(function (widgetId) {
			window.recaptchaWidgetId = widgetId;
		});
		
	}, [needToUpdate]);

	useEffect(() => {
		isUnmounted.current = false;
		return () => {
			isUnmounted.current = true;
		};
	}, [needToUpdate]);

	const handlePhoneChange = (event) => {
		let phoneNumber = event.target.value;
		if(phoneNumber.substring(0,1) !== '+' )
		{
			phoneNumber = '+48' + phoneNumber;
		}

		let phoneNoRegex = new RegExp(/\+48[0-9]{9}/);
		if(!phoneNoRegex.test(phoneNumber)) setformErrors((prev) => ({ ...prev, phoneNumber: true }));
		else setformErrors((prev) => ({ ...prev, phoneNumber: false }));

		userInfoUpdate({ phoneNumber });
	};
	const handleOTPChange = (event) => {
		setOtp(event.target.value);
	};
	const handleLogin = () => {
		const appVerifier = window.recaptchaVerifier;
		const real_Phone_number = userLoginInfo.phoneNumber.includes('+')
			? userLoginInfo.phoneNumber
			: '+' + userLoginInfo.phoneNumber;
		
			signInWithPhoneNumber(auth, real_Phone_number, appVerifier)
			.then((confirmationResult) => {
				if (!isUnmounted.current) {
					setSendOTP(true);
				}
				window.confirmationResult = confirmationResult;
			})
			.catch((err) => {
				dispatch(setError({ message: err.message }));
			});
	};

	const handleOTPCheck = () => {
		window.confirmationResult
			.confirm(otp)
			.then(function (result) {
				// User signed in successfully.
				var credential = PhoneAuthProvider.credential(window.confirmationResult.verificationId, otp);				
				getTokenAndContinue(result, credential);

				// ...
			})
			.catch(function (error) {
				dispatch(setError({ message: error.message }));
				// User couldn't sign in (bad verification code?)
				// ...
			});
	};

	const resetrecaptchaVerifier = () =>{
		  grecaptcha.reset(window.recaptchaWidgetId);
	}

	const handleSubmit = (event) => {
		event.preventDefault();
	};
	if (sendOTP === false) {
		return (
			<div className='firebaseui-spa'>
				<div id='firebaseui-contianer'>
					<div className='mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-phone-sign-in-start'>
						<form onSubmit={handleSubmit}>
							<div className='firebaseui-card-header'>
								<h1 className='firebaseui-title'>Podaj nr telefonu</h1>
							</div>
							<div className='firebaseui-card-content'>
								<div className='firebaseui-relative-wrapper'>
									<div className='firebaseui-phone-number'>
										<div
											className='mdl-textfield mdl-js-textfield mdl-textfield--floating-label firebaseui-textfield firebaseui-phone-input-wrapper is-upgraded'
											data-upgraded=',MaterialTextfield'>
											<input
												value={userLoginInfo.phoneNumber}
												onChange={(event) => handlePhoneChange(event)}
												placeholder='Numer telefonu'
												type='tel'
												name='phoneNumber'
												className='mdl-textfield__input firebaseui-input firebaseui-id-phone-number'
											/>
											{formErrors.phoneNumber && (
												<p>Wprowadź nr tel. wraz z kodem kraju</p>
											)}
										</div>
									</div>
									<div className='firebaseui-error-wrapper'>
										<p className='firebaseui-error firebaseui-text-input-error firebaseui-hidden firebaseui-phone-number-error firebaseui-id-phone-number-error' />
									</div>
									<div id='recaptcha-container' />
									<div className='firebaseui-recaptcha-wrapper'>
										<div className='firebaseui-recaptcha-container' />

										<div className='firebaseui-error-wrapper firebaseui-recaptcha-error-wrapper'>
											<p className='firebaseui-error firebaseui-hidden firebaseui-id-recaptcha-error' />
										</div>
									</div>
								</div>
							</div>
							<div className='firebaseui-card-actions'>
								<div className='firebaseui-form-actions'>
									<button
									id='loginBtn'
										onClick={handleLogin}
										disabled={isButtonDisabled || !userLoginInfo.phoneNumber}
										type='submit'
										// className='firebaseui-id-submit firebaseui-button mdl-button mdl-js-button mdl-button--raised mdl-button--colored'
										className='btn btn-primary btn-block submit-button'
										data-upgraded=',MaterialButton'>
										Zaloguj
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	} else {
		const renderer = ({ seconds, completed }) => {
			if (completed) {
				// Render a completed state
				return (
					<p
						onClick={resetrecaptchaVerifier}
						className='firebaseui-id-resend-link firebaseui-link'>
						Wyślij kod ponownie
					</p>
				);
			} else {
				// Render a countdown
				return (
					<span className='firebaseui-id-resend-countdown'>
						<p>Wyślij kod ponownie za {seconds} </p>
					</span>
				);
			}
		};
		return (
			<div className='firebaseui-spa'>
				<div id='firebaseui-contianer'>
					<div className='mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-phone-sign-in-finish'>
						<form onSubmit={handleSubmit}>
							<div className='firebaseui-card-header'>
								<h1 className='firebaseui-title'>
									Weryfikacja numeru telefonu
								</h1>
							</div>
							<div className='firebaseui-card-content'>
								<p className='firebaseui-text'>
									Wprowadź 6-cyfrowy kod z wiadomości SMS
								</p>
								<div
									className='firebaseui-textfield mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-upgraded'
									data-upgraded=',MaterialTextfield'>
									<input
										value={otp}
										onChange={(event) => handleOTPChange(event)}
										type='number'
										name='phoneConfirmationCode'
										className='mdl-textfield__input firebaseui-input firebaseui-id-phone-confirmation-code'
									/>
								</div>
								<div className='firebaseui-error-wrapper'>
									<p className='firebaseui-error firebaseui-text-input-error firebaseui-hidden firebaseui-id-phone-confirmation-code-error' />
								</div>
							</div>
							<div className='firebaseui-card-actions'>
								<div className='firebaseui-form-actions'>
									<button
										onClick={() => {
											setSendOTP(false);
											setNeedToUpdate(prev => !prev);
										}
										}
										className='cancel-button  btn firebaseui-button mdl-button mdl-js-button    '
										data-upgraded=',MaterialButton'>
										Anuluj
									</button>
									<button
										disabled={!otp || otp.toString().length !==6}
										onClick={handleOTPCheck}
										type='submit'
										className='firebaseui-id-submit firebaseui-button mdl-button mdl-js-button mdl-button--raised mdl-button--colored'
										data-upgraded=',MaterialButton'>
										Kontynuj
									</button>
								</div>
							</div>
						</form>
						{/* temporarly switch off resend functionality */}
						{/* <div className='firebaseui-resend-container'>
							<Countdown date={Date.now() + 20000} renderer={renderer} />
						</div> */}
					</div>
				</div>
			</div>
		);
	}
};
export default InputPhone;
