import React from 'react';
import NewScheduleDetails from '../../../components/NewScheduleDetails/NewScheduleDetails';


const NewSchedulesPage = () => {
    return (
        <div>
            <NewScheduleDetails/>
        </div>
    );
};

export default NewSchedulesPage;
