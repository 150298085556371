import moment from 'moment';

export const SET_SELECTED_DATE = 'SET_SELECTED_DATE';

function LocalDate(locale) {
    this.locale = locale;
}

LocalDate.prototype.getMonthName = function (date) {
    return date.toLocaleString(this.locale, { month: 'long' });
};

export function monthAsString(date) {
    var localDate = new LocalDate('pl');
    return localDate.getMonthName(date);
}

export function monthAsStringShort(date) {
    var localDate = new LocalDate.parse(date);
    return localDate.getMonthName(date).substring(1, 3);
}

export function dayAsStringShort(dateOrInt) {
    var res = '';
    var type = typeof dateOrInt;
    if (!isNaN(Date.parse(new Date(dateOrInt)))) {
        //console.log('date type');
        var d = new Date(dateOrInt);
        res = daysOfWeekDefinition.filter((x) => x.id === dayIdAdjustment(d.getDay()))[0].shortName;
    }
    if (type === 'number') {
        res = daysOfWeekDefinition.filter((x) => x.id === dayIdAdjustment(dateOrInt))[0].shortName;
    }
    return res;
}

export function dayIdAdjustment(dateOrInt) {
    var res = '';
    var type = typeof dateOrInt;
    if (!isNaN(Date.parse(new Date(dateOrInt)))) {		
        var d = new Date(dateOrInt);
        const dayId = d.getDay();
        res = dayId === 0 ? 7 : dayId;
    }
    if (type === 'number') {
        res = dateOrInt === 0 ? 7 : dateOrInt;
    }
    return res;
}

export const getShortDate = (date) => {
    const parsedDate = new Date(date);
    return (
        parsedDate.toLocaleDateString('pl', { day: 'numeric' }) +
		' ' +
		parsedDate.toLocaleDateString('pl', { month: 'short' })
    );
    // + " " + parsedDate.toLocaleDateString("pl", { year: 'numeric' })
};

export const getShortDateDMY = (date) => {
    const parsedDate = new Date(date);
    return (
        parsedDate.toLocaleDateString('pl', { day: 'numeric' }) +
		' ' +
		parsedDate.toLocaleDateString('pl', { month: 'short' }) +
		' ' +
		parsedDate.toLocaleDateString('pl', { year: 'numeric' })
    );
};

export const getDateForInputField = (date) => {
    const parsedDate = new Date(date);
    const res = moment(parsedDate).format('YYYY-MM-DD');
    return (res);
};

export const getDateTimeString = (date) => {
    const parsedDate = new Date(date);
    const res = moment(parsedDate).format('YYYY-MM-DD HH:mm');
    return (res);
};

export const getOnlyDateString = (date) => {
    const parsedDate = new Date(date);
    parsedDate.setMinutes(-parsedDate.getTimezoneOffset());
    return new Date(parsedDate).toISOString().slice(0, 10);
};

export function startOftheWeek(date) {
    var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
    return new Date(date.setDate(diff));
}

export function removeTimeZone(date) {	
    date.setTime(date.getTime() - (date.getTimezoneOffset()*60*1000));
    return date;
}

export const addHours = (date, h) => {
    var copiedDate = new Date(date);
    copiedDate.setTime(copiedDate.getTime() + h * 60 * 60 * 1000);
    return copiedDate;
};


export const addMinutes = (date, m) => {
    var copiedDate = new Date(date);
    copiedDate.setTime(copiedDate.getTime() + m * 60 * 1000);
    return copiedDate;
};

export const addToDate = (date, d, unit) => {
    return moment(date).add(d,unit).toDate();
};

export const addMonths = (date, m) => {
    var copiedDate = new Date(date);
    copiedDate.setMonth(copiedDate.getMonth() + m);
    return copiedDate;
};

export const setSelectedDate = (date) => {
    return { type: SET_SELECTED_DATE, date: date };
};

export const isDateAfterOrSameDateOnly = (dateCompare, dateCompareTo) =>{
    const res = moment(dateCompare).startOf('day').isSameOrAfter(moment(dateCompareTo).startOf('day'));
    return res;
};

export const isDateAfterDateOnly = (dateCompare, dateCompareTo) =>{
    const res = moment(dateCompare).startOf('day').isAfter(moment(dateCompareTo).startOf('day'));
    return res;
};


export const getDaysOfWeek = () => {
    const days = [
        'Niedziela',
        'Poniedziałek',
        'Wtorek',
        'Środa',
        'Czwartek',
        'Piątek',
        'Sobota',
    ];
    return days;
};

export const daysOfWeekShort = ['Nie', 'Pon', 'Wt', 'Śr', 'Czw', 'Pi', 'So'];

export const daysOfWeekDefinition = [
    { id: 7, shortName: 'Nie', name: 'Niedziela' },
    { id: 1, shortName: 'Pon', name: 'Poniedziałek' },
    { id: 2, shortName: 'Wt', name: 'Wtorek' },
    { id: 3, shortName: 'Śr', name: 'Środa' },
    { id: 4, shortName: 'Czw', name: 'Czwartek' },
    { id: 5, shortName: 'Pią', name: 'Piątek' },
    { id: 6, shortName: 'Sob', name: 'Sobota' },
];
