import React, { useEffect, useState } from 'react';
import './NewSchedules.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getNewSchedules } from '../../store/actions/lrnAPI';
import {
    getUniquValues,
    createSelectOptionsArray,
    convertArrayToObj,
} from '../../store/actions/utils';
import CategoryItemsList from '../../components/CategoryItemsList/CategoryItemsList';
import Filters from '../../components/Filters/Filters';
import DataViewOptions from '../../components/DataViewOptions/DataViewOptions';
import { analytics } from '../../firebase/Firebase';
import { logEvent } from 'firebase/analytics';
import { remoteConfigReader_getString } from '../../store/actions/firebase';
import { SCHEDULE_FULLSCHEDULE_LINK } from '../../firebase/remoteConfig/remoteConfigParams';
import { getItemFromCustSignup, LOG_EVENT_ITEM_LIST_IDS_NEW_CLASSES_SEARCH_DANCE, LOG_EVENT_ITEM_LIST_IDS_NEW_CLASSES_SEARCH_DAY, LOG_EVENT_ITEM_LIST_IDS_NEW_CLASSES_SEARCH_LEVEL, LOG_EVENT_ITEM_LIST_IDS_NEW_CLASSES_SEARCH_TEACHER, LOG_EVENT_ITEM_LIST_IDS_NEW_CLASSES_SELECTED } from '../../analytics/events';


const NewSchedules = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();	

    const userId = useSelector((store) => store.customerStore.customerData.id);
    const apiToken = useSelector((store) => store.uiStore.apiToken);
    const newSchedules = useSelector((state) => state.eventsStore.newSchedules);
    const [searchParams, setSearchParams] = useSearchParams();
    const [viewType, setViewType] = useState(1);
    const [eventFilter, setEventFilter] = useState(searchParams.get('event'));
    const [dayFilter, setDayFilter] = useState(searchParams.get('day'));
    const [levelFilter, setLevelFilter] = useState(searchParams.get('level'));
    const [techniqueFilter, setTechniqueFilter] = useState(searchParams.get('technique'));
    const [typeFilter, setTypeFilter] = useState(searchParams.get('type'));
    const [teacherFilter, setTeacherFilter] = useState(searchParams.get('teacher'));
    const [serviceFilter, setServiceFilter] = useState(searchParams.get('service'));

    const [fullScheduleLink] = useState(dispatch(remoteConfigReader_getString(SCHEDULE_FULLSCHEDULE_LINK)));
    

    const loadData = () => {
        dispatch(getNewSchedules(userId, apiToken));
    };

    const getEventTypes = () => {
        const res = getUniquValues(
            applySignupsFilters(newSchedules).sort(
                (a, b) => a.EventName - b.EventName
            ),
            'EventName'
        );
        return res;
    };
    const getLevels = () => {
        return getUniquValues(
            applySignupsFilters(newSchedules).sort((a, b) => a.LevelId - b.LevelId),
            'Level'
        );
    };
    const getDays = () => {
        const list = applySignupsFilters(newSchedules).sort(
            (a, b) => a.DayId - b.DayId
        );
        return getUniquValues(list, 'Day');
    };

    const getTechniques = () => {
        const list = applySignupsFilters(newSchedules).sort(
            (a, b) => a.Cat1Id - b.Cat1Id
        );
        return getUniquValues(list, 'Cat1Name');
    };

    const getTypes = () => {
        const list = applySignupsFilters(newSchedules).sort(
            (a, b) => a.Cat2Id - b.Cat2Id
        );
        return getUniquValues(list, 'Cat2Name');
    };

    const getTeachers = () => {
        const teachers = [];
        const list = applySignupsFilters(newSchedules).sort(
            (a, b) => a.teacher - b.teacher
        );
        list.forEach(e => {

            teachers.push({teacher:e.Teacher1Long});
            teachers.push({teacher:e.Teacher2Long});
        });
        return getUniquValues(teachers, 'teacher');
    };

    const getServices = () => {
        
        const list = applySignupsFilters(newSchedules).sort(
            (a, b) => a.ServiceName - b.ServiceName
        );
        
        return getUniquValues(list, 'ServiceName');
    };

    const getCategoriesArray = () => {
        switch (viewType) {
        case 1:
            return getEventTypes();
        case 2:
            return getDays();
        case 3:
            return getLevels();
        case 4:
            return getTechniques();
        case 5:
            return getTypes();
        case 6:
            return getTeachers();
        case 7:
            return getServices();
        default:
            break;
        }
    };

    const getCategoriesGroupField = () => {
        switch (viewType) {
        case 1:
            return 'EventName';
        case 2:
            return 'Day';
        case 3:
            return 'Level';
        default:
            break;
        }
    };

    const applySignupsFilters = () => {
        var filteredSignups = newSchedules;

        if (eventFilter && eventFilter !== '') {
            filteredSignups = filteredSignups.filter(
                (s) => s.EventName === eventFilter
            );
        }
        if (dayFilter && dayFilter !== '') {
            filteredSignups = filteredSignups.filter((s) => s.Day === dayFilter);
        }
        if (levelFilter && levelFilter !== '') {
            filteredSignups = filteredSignups.filter((s) => s.Level === levelFilter);
        }
        if (techniqueFilter) {
            filteredSignups = filteredSignups.filter((s) => s.Cat1Name === techniqueFilter);
        }
        if (typeFilter) {
            filteredSignups = filteredSignups.filter((s) => s.Cat2Name === typeFilter);
        }
        if (teacherFilter) {
            filteredSignups = filteredSignups.filter((s) => (s.Teacher1Long === teacherFilter || s.Teacher2Long === teacherFilter));
        }
        if (serviceFilter) {
            filteredSignups = filteredSignups.filter((s) => (s.ServiceName === serviceFilter));
        }

        return filteredSignups;
    };

    const onChangeDataView = (viewId) => {
        setViewType(viewId);
    };

    const applyEventsFilter = (val) => {
        
        if (val && val.length > 0) {            
            logEvent(analytics, 'view_item', {
                items: [
                    {
                        item_list_id: LOG_EVENT_ITEM_LIST_IDS_NEW_CLASSES_SEARCH_DANCE.id,
                        item_list_name: LOG_EVENT_ITEM_LIST_IDS_NEW_CLASSES_SEARCH_DANCE.name,
                        item_id: val[0].value,
                        item_name: val[0].value,
                        item_category: val[0].value,
                    },
                ],
            });            
            searchParams.set('event', val[0].value);
            setEventFilter(val[0].value);
        } else {
            searchParams.set('event', '');
            setEventFilter('');
        }

        setSearchParams(searchParams, {
            replace: true,
        });
    };

    const applyDayFilter = (val) => {
        if (val && val.length > 0) {
            logEvent(analytics, 'view_item', {
                items: [
                    {
                        item_list_id: LOG_EVENT_ITEM_LIST_IDS_NEW_CLASSES_SEARCH_DAY.id,
                        item_list_name: LOG_EVENT_ITEM_LIST_IDS_NEW_CLASSES_SEARCH_DAY.name,
                        item_id: val[0].value,
                        item_name: val[0].value,
                        item_category2: val[0].value,
                    },
                ],
            });
            searchParams.set('day', val[0].value);
            setDayFilter(val[0].value);
        } else {
            searchParams.set('day', '');
            setDayFilter('');
        }

        setSearchParams(searchParams, {
            replace: true,
        });
    };

    const applyLevelFilter = (val) => {
        if (val && val.length > 0) {
            logEvent(analytics, 'view_item', {
                items: [
                    {
                        item_list_id: LOG_EVENT_ITEM_LIST_IDS_NEW_CLASSES_SEARCH_LEVEL.id,
                        item_list_name: LOG_EVENT_ITEM_LIST_IDS_NEW_CLASSES_SEARCH_LEVEL.name,
                        item_id: val[0].value,
                        item_name: val[0].value,
                        item_category3: val[0].value,
                    },
                ],
            });
            searchParams.set('level', val[0].value);
            setLevelFilter(val[0].value);
        } else {
            searchParams.set('level', '');
            setLevelFilter('');
        }

        setSearchParams(searchParams, {
            replace: true,
        });
    };

    const applyTechniqueFilter = (val) => {
        if (val && val.length > 0) {           
            searchParams.set('technique', val[0].value);
            setTechniqueFilter(val[0].value);
        } else {
            searchParams.set('technique', '');
            setTechniqueFilter('');
        }

        setSearchParams(searchParams, {
            replace: true,
        });
    };

    const applyTypeFilter = (val) => {
        if (val && val.length > 0) {           
            searchParams.set('type', val[0].value);
            setTypeFilter(val[0].value);
        } else {
            searchParams.set('type', '');
            setTypeFilter('');
        }

        setSearchParams(searchParams, {
            replace: true,
        });
    };

    const applyTeacherFilter = (val) => {        
        if (val && val.length > 0) {           
            logEvent(analytics, 'view_item', {
                items: [
                    {
                        item_list_id: LOG_EVENT_ITEM_LIST_IDS_NEW_CLASSES_SEARCH_TEACHER.id,
                        item_list_name: LOG_EVENT_ITEM_LIST_IDS_NEW_CLASSES_SEARCH_TEACHER.name,
                        item_id: val[0].value,
                        item_name: val[0].value,
                        item_category4: val[0].value,
                    },
                ],
            });
            searchParams.set('teacher', val[0].value);
            setTeacherFilter(val[0].value);
        } else {
            searchParams.set('teacher', '');
            setTeacherFilter('');
        }

        setSearchParams(searchParams, {
            replace: true,
        });
    };

    const applyServiceFilter = (val) => {
        if (val && val.length > 0) {           
            searchParams.set('service', val[0].value);
            setServiceFilter(val[0].value);
        } else {
            searchParams.set('service', '');
            setServiceFilter('');
        }

        setSearchParams(searchParams, {
            replace: true,
        });
    };

    const onItemClick = (id) => {
        createAnalyticsAddToCart(id);
        navigate('/newschedules/' + id);
    };

    const logFiltersTxtTyping = (searchText) => {
        if (searchText && searchText !== '') {
            logEvent(analytics, 'search', {
                screen_name: 'new_classes',
                search_term: searchText,
            });
        }
    };

    const createAnalyticsAddToCart = (newSchedId) => {
        const selectedSched = newSchedules.filter((x) => x.Id === newSchedId)[0];
        if (selectedSched) {
            logEvent(analytics, 'select_item', {
                item_list_id: LOG_EVENT_ITEM_LIST_IDS_NEW_CLASSES_SELECTED.id,
                item_list_name: LOG_EVENT_ITEM_LIST_IDS_NEW_CLASSES_SELECTED.name,
                items: [
                    getItemFromCustSignup(selectedSched,0, LOG_EVENT_ITEM_LIST_IDS_NEW_CLASSES_SELECTED,0,1)                    
                ],
                value: 1,
            });
        }
    };

    /* eslint-disable */
	useEffect(() => {
		loadData();
		return () => {};
	}, []);

	return (
		<div className='newevents-container'>
			<div className='newevents-filters'>
				<Filters
					eventTypes={createSelectOptionsArray(getEventTypes())}
					days={createSelectOptionsArray(getDays())}
					levels={createSelectOptionsArray(getLevels())}
                    types={createSelectOptionsArray(getTypes())}
                    techniques={createSelectOptionsArray(getTechniques())}
                    teachers={createSelectOptionsArray(getTeachers())}
                    services={createSelectOptionsArray(getServices())}
					selectEventType={applyEventsFilter}
					selectDay={applyDayFilter}
					selectLevel={applyLevelFilter}
                    selectTechnique={applyTechniqueFilter}
                    selectType={applyTypeFilter}
                    selectTeacher={applyTeacherFilter}
                    selectService={applyServiceFilter}
                    day={dayFilter}
                    level={levelFilter}
                    event={eventFilter}
                    type={typeFilter}
                    technique={techniqueFilter}
                    teacher={teacherFilter}
                    service={serviceFilter}
					onTextTypedIn={(text) => logFiltersTxtTyping(text)}
					waitForTypingDelay={500}></Filters>
			</div>
			<div className='newevents-viewoptions'>
				<DataViewOptions
					onChangeDataView={onChangeDataView}
					activeViewId={viewType}
				/>
			</div>
			<div className='newevents-links'>
                {fullScheduleLink ?
				<a
					href={fullScheduleLink}
					target='_blank'
					rel='noreferrer noopener'>
					<button className='btn main'>Przejdź do pełnego grafiku zajęć</button>
				</a>
                : null
                }
			</div>
			<CategoryItemsList
				listType='newSchedules'
				categories={convertArrayToObj(getCategoriesArray())}
				items={applySignupsFilters()}
				itemsGroupBy={getCategoriesGroupField()}
				itemsToCatLinkKey={'name'}
				onItemClick={(id) => onItemClick(id)}></CategoryItemsList>
		</div>
	);
};

export default NewSchedules;
