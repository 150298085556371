import React from 'react';
import { Fragment } from 'react';
import './LoadingBox.css';

const LoadingBox = (props) => {
    return (
        <Fragment>
            {props.loaded ? (
                props.children
            ) : (
                <div className='placeholder-container'>
                    <div className='placeholder-content'>
                        <div className='placeholder-content_item'></div>
                        <div className='placeholder-content_item'></div>
                        <div className='placeholder-content_item'></div>
                        <div className='placeholder-content_item'></div>
                        <div className='placeholder-content_item'></div>
                        <div className='placeholder-content_item'></div>
                        <div className='placeholder-content_item'></div>
                        <div className='placeholder-content_item'></div>
                        <div className='placeholder-content_item'></div>
                        <div className='placeholder-content_item'></div>
                        <div className='placeholder-content_item'></div>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default LoadingBox;
