import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { auth, analytics } from '../../firebase/Firebase';

import {
    setError,
    setSignedIn,
    checkAuthenticated,
    showProgressBar,
} from '../../store/actions/ui';
import EmailLink from './EmailLink/EmailLink';
import { status } from './Utils';
import SignupForm from './SignupForm/SignupForm';
import './Auth.css';
import InputPhone from './Phone/Phone';
import { login } from '../../store/actions/lrnAPI';
import { setAuthCredential } from '../../store/actions/customers';
import Email from './Email/Email';
import { remoteConfigReader_getBoolean } from '../../store/actions/firebase';
import VersionInfo from '../VersionInfo/VersionInfo';
import { GoogleAuthProvider, signInWithPopup, getAdditionalUserInfo } from 'firebase/auth';
import { logEvent } from 'firebase/analytics';

//Components
import googleLogo from '../Icons/resources/btn_google_light_normal_ios.png';
import { useLocation } from 'react-router';

const Auth = () => {
    const dispatch = useDispatch();
    const { pathname = '', search = '' } = useLocation();

    const isSignedInToFirebase = useSelector(
        (state) => state.uiStore.firebaseSignedIn
    );
    const isSignedInToApi = useSelector((state) => state.uiStore.apiSignedIn);
    const isSignupRequired = useSelector((state) => state.uiStore.signupRequired);
    //const authPublicKeys = useSelector((state) =>state.settingsStore.authPublicKeys);
    const firebaseToken = useSelector((state) => state.uiStore.firebaseToken);
    const configurationLoaded = useSelector(
        (state) => state.uiStore.configurationLoaded
    );

    const loginEmailEnabled = dispatch(
        remoteConfigReader_getBoolean('login_email_enabled')
    );
    const loginGmailEnabled = dispatch(
        remoteConfigReader_getBoolean('login_gmail_enabled')
    );
    const loginPhoneEnabled = dispatch(
        remoteConfigReader_getBoolean('login_phone_enabled')
    );
    const loginPassEnabled = dispatch(
        remoteConfigReader_getBoolean('login_password_enabled')
    );

    const [authStatus, setAuthStatus] = useState(status.NONE);
    const [isRegisterFlow, setIsRegisterFlow] = useState(false);

    const [userLoginInfo, setUserLoginInfo] = useState({
        email: '',
        password: '',
        phoneNumber: '',
        token: '',
        firstName: '',
        lastName: '',
    });

    const [needToUpdate, setNeedToUpdate] = useState(false);

    const userInfoUpdate = (info) => {
        setUserLoginInfo((prev) => ({
            ...prev,
            ...info,
        }));
    };

    const googleLogin = () => {
        dispatch(showProgressBar(true));
        const provider = new GoogleAuthProvider();

        signInWithPopup(auth ,provider)
            .then((result) => {                
                if (result) {
                    const credential = GoogleAuthProvider.credentialFromResult(result);					
                    const details = getAdditionalUserInfo(result);
                    setUserLoginInfo((prev) => ({
                        ...prev,
                        email: result.user.email,
                        firstName: details.profile.given_name,
                        lastName: details.profile.family_name,
                        token: credential.idToken,
                    }));
                    setAuthStatus(status.SIGNUP);
                    getTokenAndContinue(result, result.credential);
                    dispatch(showProgressBar(true));
                }
            })
            .catch((error) => {
                dispatch(showProgressBar(false));
                dispatch(setError({ message: error.message }));
            });
    };

    const getTokenAndContinue = (authResult, credential) => {
        if (!credential) {
            if (status.EMAIL) {
                credential = { signInMethod: 'backend' };
            } else {
                credential = { signInMethod: 'unknown' };
            }
        }

        dispatch(setAuthCredential(credential));
        authResult.user
            .getIdToken()
            .then(function (idTokenFromCred) {
                userInfoUpdate({ email: userLoginInfo.email, token: idTokenFromCred });
                dispatch(setSignedIn(true, false, idTokenFromCred, null));
                dispatch(login(idTokenFromCred));
            })
            .catch((err) => {
                dispatch(setError('Problem z weryfikacją ' + err));
                handleChangeStatus(status.NONE);
            });
    };

    const handleChangeStatus = (changeStatus) => {
        logLoginEvent(changeStatus);
        setAuthStatus(changeStatus);
    };

    const goBackFromRegisterFlow = () => { 
        handleChangeStatus(status.NONE);
        setIsRegisterFlow(false);
    };

    const logLoginEvent = (loginType) => {
        logEvent(analytics, 'login', { method: loginType });
    };

    const getEmailFromCookies = () => {
        let cookie = document.cookie
            .split('; ')
            .find((row) => row.startsWith('dsa_userEmail='));
        if (cookie) {
            const email = cookie.split('=')[1];
            userInfoUpdate({ email });
        } else {
            return '';
        }
    };

    const renderComponent = () => {
        if (!isSignedInToFirebase) {
            switch (authStatus) {
            case status.EMAIL:
                return (
                    <Email
                        handleChangeStatus={handleChangeStatus}
                        authStatus={authStatus}
                        userInfoUpdate={userInfoUpdate}
                        getTokenAndContinue={getTokenAndContinue}
                        userLoginInfo={userLoginInfo}
                    />
                );
            case status.EMAILLINK:
                return (
                    <EmailLink
                        handleChangeStatus={handleChangeStatus}
                        authStatus={authStatus}
                        userInfoUpdate={userInfoUpdate}
                        getTokenAndContinue={getTokenAndContinue}
                    />
                );
            case status.PHONE:
                return (
                    <div className='phoneNumber_section'>
                        <InputPhone
                            handleChangeStatus={handleChangeStatus}
                            userInfoUpdate={userInfoUpdate}
                            getTokenAndContinue={getTokenAndContinue}
                            userLoginInfo={userLoginInfo}
                            needToUpdate={needToUpdate}
                            setNeedToUpdate={setNeedToUpdate}
                        />
                        <button
                            className='btn btn-primary cancel auth-phone-btn'
                            onClick={() => handleChangeStatus(status.NONE)}>
								Powrót
                        </button>
                    </div>
                );
            case status.NONE:
            default:
                return (
                    <div>
                        <div className='auth-text-header'>
                            {!isRegisterFlow ? 'Zaloguj się' : 'Zarejestruj się używając:'}
                        </div>
                        <div className='auth-buttons'>
                            {!isRegisterFlow && (                            
                                <div className='auth-buttons-main'>
                                    {loginPassEnabled && (
                                        <Email
                                            handleChangeStatus={handleChangeStatus}
                                            authStatus={authStatus}
                                            userInfoUpdate={userInfoUpdate}
                                            getTokenAndContinue={getTokenAndContinue}
                                            userLoginInfo={userLoginInfo}
                                        />
                                    )}      
                                    
                                    <div className='auth-buttons-separator-lines'>
                                        <button
                                            className='btn btn-primary submit-button '
                                            onClick={() => setIsRegisterFlow(true)}
                                        >
                                        Zarejestruj się
                                        </button>
                                    
                                    </div>
                                </div>         
                            )}      
                            <div className=' auth-buttons-separator'/>
                            <div className='auth-buttons-other'>
                                {!isRegisterFlow && (
                                    <div className='auth-buttons-other-label'> lub zaloguj się za pomocą</div>)
                                }
                                <div className='auth-buttons-other-btn'>                                
                                    {loginPhoneEnabled ? (
                                        <button
                                            className='btn btn-primary submit-button'
                                            onClick={() => handleChangeStatus(status.PHONE)}>
										Telefon
                                        </button>
                                    ) : null}
                                </div>
                                <div className='auth-buttons-other-btn'>
                                    {loginEmailEnabled ? (
                                        <button
                                            className='btn btn-primary submit-button'
                                            onClick={() => handleChangeStatus(status.EMAILLINK)}>
										Link Email
                                        </button>
                                    ) : null}
                                </div>
                                <div className='auth-buttons-other-btn '>
                                    {loginGmailEnabled ? (
                                        <button
                                            className='btn main submit-button light'
                                            onClick={() => googleLogin()}>
                                            <div className='button-children-aligned'>
                                                <div className='auth-buttons-other-btn-img'>
                                                    <img src={googleLogo}></img>
                                                </div> 
                                                <div className='auth-buttons-other-btn-text'>
                                                 Google
                                                </div>
                                            </div>
                                        </button>
                                        
                                    ) : null}
                                </div>
                                {isRegisterFlow && (
                                    <div className='auth-buttons-other-btn '>
                                        <button
                                            className='btn btn-primary cancel auth-phone-btn'
                                            onClick={goBackFromRegisterFlow}>
								Powrót
                                        </button>
                                    </div>)}
                            </div>
                        </div>
                    </div>
                );
            }
        } else {
            if (isSignedInToFirebase && isSignedInToApi) {
                return null;
            }
            if (!isSignedInToApi && isSignupRequired) {
                return (
                    <SignupForm
                        handleChangeStatus={handleChangeStatus}
                        authStatus={authStatus}
                        userLoginInfo={userLoginInfo}
                        setUserLoginInfo={setUserLoginInfo}
                    />
                );
            } else {
                return (
                    <div>
						Logowanie do bazy danych ...						
                        <div>
                            <button onClick={loginToAPI}>Ponów próbę</button>
                        </div>
                    </div>
                );
            }
        }
    };

    const loginToAPI = () => {		
        dispatch(checkAuthenticated());
        if (isSignedInToFirebase && !isSignedInToApi) {			
            if (firebaseToken) dispatch(login(firebaseToken));
        }
    };

    /* eslint-disable */
	useEffect(() => {
		getEmailFromCookies();
		if (configurationLoaded) loginToAPI();
		return () => {};
	}, [configurationLoaded,isSignedInToFirebase]);

    useEffect(() => {
        if ((pathname && pathname !== '/') || search) {
            localStorage.setItem('dsa:internal:url', `${pathname}${search}`);
        }
    }, [pathname, search]);

	return (
		<div className='auth-container'>			
			{renderComponent()}
			<VersionInfo />
		</div>
	);
};

export default Auth;
