import React, { useState } from 'react';
import './EventDetails.css';
import Select from 'react-dropdown-select';
import StatusPill from '../UI/StatusPill/StatusPill';

import useNewSchedulePayUResponse from '../../hooks/useNewSchedulePayUResponse';

import { quantityMapping } from '../../utils/dataMappings';
import moment from 'moment';

const EventDetails = ({
    event,
    date,
    level,
    teachers,
    validTillTxt,
    statusId,
    paymentTypes,
    paymentType,
    onPaymentTypeChange,
    noOfEntriesOnPassLeft,
    onPreferredStartDateChange
}) => {
    const [selectedPaymentType, setSelectedPaymentType] = useState(paymentType);
    const [preferredStartDate, setPreferredStartDate] = useState(event.PreferredStartDate);

    const onChange = (data) => {
        const item = data[0];

        if (!item) {
            return;
        }
        setSelectedPaymentType(item.value);
        onPaymentTypeChange(data[0]);
    };

    const onChangePreferredStartDate = (inputVal) => { 
        const newDate = inputVal.target.value;
        setPreferredStartDate(newDate);
        onPreferredStartDateChange(newDate);
    };

    useNewSchedulePayUResponse();

    return (
        <section className="event-details">
            <div className="event-details-title">Szczegóły zapisu do grupy:</div>
            <div className="event-details-row">
                <div className="event-details-label">
                    Id
                </div>
                <div className="event-details-value">
                    {event.Id}
                </div>
            </div>
            <div className="event-details-row">
                <div className="event-details-label">
                    Data
                </div>
                <div className="event-details-value">
                    {date}
                </div>
            </div>
            <div className="event-details-row">
                <div className="event-details-label">
                    Poziom
                </div>
                <div className="event-details-value">
                    {level}
                </div>
            </div>
            <div className="event-details-row">
                <div className="event-details-label">
                    Instruktorzy
                </div>
                <div className="event-details-value">
                    {teachers}
                </div>
            </div>

            <div className="event-details-row">
                <div className="event-details-label">
                    Liczba dostępnych wejść: 
                </div>
                <div className="event-details-value">
                    {quantityMapping(noOfEntriesOnPassLeft)}
                </div>
            </div>
            <div className="event-details-row">
                <div className="event-details-label">
                    Zapis ważny do: 
                </div>
                <div className="event-details-value">
                    {validTillTxt}
                </div>
            </div>
            <div className="event-details-row">
                <div className="event-details-label">
                    Status
                </div>
                <div className="event-details-value">
                    <StatusPill statusId={statusId} itemType='signup' />
                </div>
            </div>
            <div className="event-details-row">
                <div className="event-details-label">
                    Cennik: 
                </div>
                <div className="event-details-value">
                    {(event.PriceTypeId??'') + '. ' + (event.PriceType??'')}
                </div>
            </div>
            <div className="event-details-row">
                <div className="event-details-label">
                    Typ płatności
                </div>
                <div className="event-details-select">
                    <Select
                        name='paymentType'
                        placeholder='Typ płatności'
                        options={paymentTypes}
                        onChange={onChange}
                        values={paymentTypes.filter(({ value }) => String(value) === String(selectedPaymentType))}
                    />
                </div>
            </div>
            <div className="event-details-row">
                <div className="event-details-label">
                    Data rozpoczęcia
                </div>
                <div className="event-details-select">
                    <input
                        id='eventDetailsPrefStartDate'
                        type={'date'}
                        name='preferredStartDate'
                        onChange={(val)=>onChangePreferredStartDate(val)}
                        value={moment(preferredStartDate).format('yyyy-MM-DD')}

                    />
                </div>
            </div>
        </section>
    );
};

export default EventDetails;
