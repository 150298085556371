import { sendRequest } from '../actions/lrnAPI';
import { setError } from '../actions/ui';
import { customersEndpoint } from './lrnAPIEndpoints';

export const searchPartner = (request) => {
    return (dispatch, getState) => {
        
        if (!request) {
            dispatch(setError({ message: 'Missing request' }));
            return false;
        }
        const state = getState();
        const apiToken = state.uiStore.apiToken;
            
        const endpoint = customersEndpoint + '/findPartner';
        return sendRequest(endpoint, request, apiToken, 'post');                
        
    };
};
