import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import ToggleButton from '../../UI/ToggleButton/ToggleButton';

import './EventTool.css';

const EventTool = ({
    id,
    allowAction,
    buttonType,
    toggleChecked,
    onChange,
    buttonText,
    inProgressId,
    title,
    eventToolDetails,
}) => {
    const renderSpinner = () => {
        return <Spinner animation="border" role="status" size="sm" />;
    };

    const renderButtons = () => {
        if (allowAction) {
            return (
                <div className="event-tool-btn">
                    {buttonType === 'btn' ? (
                        <Button
                            id={'event-tool-btn-' + id}
                            key={'event-tool-btn-' + id}
                            readOnly={!allowAction}
                            checked={toggleChecked}
                            onClick={onChange}
                        >
                            {buttonText}
                        </Button>
                    ) : (
                        <ToggleButton
                            id={'event-tool-toggle-' + id}
                            key={'event-tool-toggle-' + id}
                            readOnly={!allowAction}
                            checked={toggleChecked}
                            onChange={onChange}
                            height={25}
                            width={50}
                        ></ToggleButton>
                    )}
                </div>
            );
        } else {
            return null;
        }
    };

    return (
        <div className="event-tool-container">
            {allowAction ? <div className="event-tool-label">{title}</div> : null}
            <div className="event-tool">
                {inProgressId === id ? renderSpinner() : renderButtons()}
                <div className="event-tool-details">{eventToolDetails}</div>
            </div>
        </div>
    );
};

export default EventTool;
