import { sendRequest } from '../actions/lrnAPI';
import { setError, setInfo } from '../actions/ui';

export const getPurchaseOptions = (cceId, disc) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {            
            if (!cceId) {
                dispatch(setError({ message: 'Missing event id' }));
                return false;
            }
            const state = getState();
            const apiToken = state?.uiStore?.apiToken;
            const custId = state?.customerStore.customerData.id;

            if(!apiToken || !custId)
            {
                dispatch(setError({ message: 'Missing apiToken or custId' }));
                return false;
            }

            const endpoint = 'purchase/options/'+custId+'/'+cceId+'/'+disc  ;
            sendRequest(endpoint, null, apiToken, 'get')
                .then((response) => {		

                    switch (response.status) {
                    case 200:
                        resolve(response.data);
                        break;	
                    case 204:
                        resolve([]);
                        break;	
                    case 208:
                        dispatch(setInfo(response.data.message));
                        reject();
                        break;					
                    default:
                        dispatch(setError(response));
                        reject();
                        break;
                    }
                })
                .catch((err) => {				
                    dispatch(setError(err));
                    reject();
                });
        });
    };
};
