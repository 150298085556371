import React from 'react';
import MyNextEventCard from '../../components/Cards/MyNextEventCard/MyNextEventCard';
import AccountDetailsCard from '../../components/Cards/AccountDetailsCard/AccountDetailsCard';
import ActiveMenuMonitor from '../../components/Nav/ActiveMenuMonitor/ActiveMenuMonitor';
import NewsMessages from '../../components/NewsMessages/NewsMessages';
import './Dashboard.css';

const Dashboard = () => {	    
    return (
        <div className='dashboard-container'>            
            <ActiveMenuMonitor menuId={1}/>
            <div className='dashboard-item'>            
                <NewsMessages/>			
            </div>
            <div className='dashboard-item'>
                <AccountDetailsCard />
            </div>
            <div className='dashboard-item'>
                <MyNextEventCard />			
            </div>
			
        </div>
    );
};
export default Dashboard;
