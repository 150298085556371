import React from 'react';
import ActiveMenuMonitor from '../../../components/Nav/ActiveMenuMonitor/ActiveMenuMonitor';
import SocialContacts from '../../../containers/Social/SocialContacts/SocialContacts';

const SocialContactsPage = () => {
    return (
        <div>
            <ActiveMenuMonitor menuId={7} />
            <SocialContacts/>
        </div>
    );
};

export default SocialContactsPage;
