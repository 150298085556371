import React from 'react';
import ActiveMenuMonitor from '../../../components/Nav/ActiveMenuMonitor/ActiveMenuMonitor';
import DepositsHistory from '../../../containers/DepositsHistory/DepositsHistory';

const DepositsPage = () => {
    return (
        <div className='deposits-page-container'>
            <ActiveMenuMonitor menuId={5}/>
            <DepositsHistory/>
        </div>
    );
};

export default DepositsPage;