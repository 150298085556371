import React, { useState } from 'react';
import ConfirmButton from '../../../UI/ConfirmButton/ConfirmButton';


const CancelEventButton = ({ children, executeActionFunction  }) => {
    const [confirmShow, setConfirmShow] = useState(false);
    const onClickCancel = () => {
        setConfirmShow(true);
    };

    const executeAction = () => {
        executeActionFunction();
    };
    const cancelAction = () => {
        setConfirmShow(false);
    };
    

    return (
        <div className='cancelButton-container'>
            {!confirmShow ?
                <button className="second" onClick={onClickCancel}>
                    {children}
                </button>
                :
                <div><ConfirmButton onConfirm={executeAction} onCancel={cancelAction}/></div>
            }
        </div>
    );
};

export default CancelEventButton;
