import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Input.css';
import { Form, FormGroup } from 'react-bootstrap';

const Input = ({id,children, placeholder, onBlur, icon, onChange, waitForTypingDelay, type, 
    validationFunction, invalidText}) => {
    
    const [value, setValue] = useState('');
    const [isValid, setIsValid] = useState(true);
    
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            onChange(value);            
            if(validationFunction && value)
                setIsValid(validationFunction(value));
        }, waitForTypingDelay ?? 0);

        return () => clearTimeout(delayDebounceFn);
    }, [value]);


    return (
        <div className='input-container'>
            <FormGroup controlId={id} >
                <Form.Label>{children}</Form.Label>
                <Form.Control
                    className='input-value'
                    type={type}
                    placeholder={placeholder}
                    onChange={(e) => {
                        setValue(e.target.value);
                    }}
                    onBlur={onBlur}
                    value={value}
                    isInvalid={!isValid}
                />
                <Form.Control.Feedback type={'invalid'} >{invalidText}</Form.Control.Feedback>

                {icon ? (
                    <div className='input-icon'>
                        <FontAwesomeIcon icon={icon} />{' '}
                    </div>
                ) : null}
            </FormGroup>
        </div>
    );
};

export default Input;
