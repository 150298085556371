import React from 'react';
import ActiveMenuMonitor from '../../../components/Nav/ActiveMenuMonitor/ActiveMenuMonitor';
import SocialSearch from '../../../containers/Social/SocialSearch/SocialSearch';
import './SocialSearchPage.css';

const SocialSearchPage = () => {
    return (
        <div className='social-search-page-container'>
            <ActiveMenuMonitor menuId={7}/>
            <SocialSearch/>
        </div>
    );
};

export default SocialSearchPage;
