import React from 'react';

const ConfirmButton = ({onConfirm, onCancel}) => {
    return <div>
        <button className='danger' onClick={onConfirm}>Tak</button>
        <button className='cancel' onClick={onCancel}>Nie</button>
    </div>;
};

export default ConfirmButton;
