import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';


import './DataButton.css';

const DataButton = (props) => {
    return (
        <div className='data-button-container' onClick={props.onClick}>
            <div className='data-button-text'>{props.text} </div>
            <div className='data-button-data'>{props.data}</div>
            <div className='data-button-function'>
                <FontAwesomeIcon icon={faPlus}/>
            </div>

        </div>
    );
};

export default DataButton;
