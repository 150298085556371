import React from 'react';
import './CardInfo.css';

const CardInfo = (props) => {
    return (
        <div className='cardinfo-container'>
            {props.onClickClose ? (
                <div className='cardinfo-container-icon-close'></div>
            ) : null}
                
            <div className='cardinfo-container-title'>{props.title}</div>
            <div className='cardinfo-container-text'>{props.text}</div>
            {props.onClickCancel ? (
                <div className='cardinfo-container-buttons-cancel'></div>
            ) : null}
            {props.onClickOk ? (
                <div className='cardinfo-container-buttons-ok'></div>
            ) : null}
        </div>
    );
};

export default CardInfo;
