import React from 'react';
import './SocialUserProfilePage.css';
import ActiveMenuMonitor from '../../../components/Nav/ActiveMenuMonitor/ActiveMenuMonitor';
import SocialUserProfile from '../../../containers/Social/SocialUserProfile/SocialUserProfile';

const SocialUserProfilePage = () => {
    return (
        <div className='social-userprofile-page-container'>
            <ActiveMenuMonitor menuId={6} />
            <SocialUserProfile />
        </div>
    );
};

export default SocialUserProfilePage;
