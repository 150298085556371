import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setNewsMessages } from '../../store/actions/messaging';
import { storage, database } from '../../firebase/Firebase';
import { convertObjToKeyVal } from '../../store/actions/utils';
import CardInfo from '../UI/CardInfo/CardInfo';
import { setError } from '../../store/actions/ui';
import './NewsMessages.css';
import CardInforGraphic from '../UI/CardInforGraphic/CardInforGraphic';
import {
    ref as refDb,
    onValue,
    query,
    orderByChild,
    startAt,
} from 'firebase/database';
import { ref as refStorage, getDownloadURL } from 'firebase/storage';

const NewsMessages = () => {
    const userId = useSelector((store) => store.customerStore.customerData.id);
    const userGender = useSelector(
        (state) => state.customerStore.customerData.gender
    );
    const userGenderLong = userGender === 'M' ? 'men' : 'women';
    const messages = useSelector((store) => store.messagingStore.newsmessages);
    const apiConfigId = useSelector((store) => store.firebaseStore.selectedAPIConfigId);

    const dispatch = useDispatch();

    const [gmessages, setgmessages] = useState([]);
    
    const rootPath = apiConfigId+'/newsmessages';

    const connectToDb = () => {
        if (userId) {
            const now = new Date();
            const root = query(
                refDb(database, rootPath),
                startAt(now.getTime()), orderByChild('dateTill')
            );

            onValue(root, function (snapshot) {
                let messages = snapshot.val();
                const array = convertObjToKeyVal(messages);

                const filtered = array.filter(
                    (x) =>
                        x.value.recipient === 'all' || x.value.recipient === userGenderLong
                );
                dispatch(setNewsMessages(filtered));
            });
        }
    };

    const getPictureUrl = (filePath) => {
        return new Promise((resolve, reject) => {
            // Create a reference to the file we want to download

            if (!filePath || filePath === '') reject(null);

            var picRef = refStorage(storage, filePath);
            // Get the download URL

            getDownloadURL(picRef)
                .then((url) => {
                    // Insert url into an <img> tag to "download"
                    resolve(url);
                })
                .catch((error) => {
                    dispatch(setError({ message: error }));
                    reject();
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    // switch (error.code) {
                    // 	case 'storage/object-not-found':
                    // 		// File doesn't exist
                    // 		break;
                    // 	case 'storage/unauthorized':
                    // 		// User doesn't have permission to access the object
                    // 		break;
                    // 	case 'storage/canceled':
                    // 		// User canceled the upload
                    // 		break;

                    // 	// ...

                    // 	case 'storage/unknown':
                    // 		// Unknown error occurred, inspect the server response
                    // 		break;
                    // }
                });
        });
    };

    const getMessages = () => {
        // setmsgTags([]);
        let elArr = [];
        messages.forEach((el) => {
            if (!el.value.filePath) {
                let cardEl = (
                    <CardInfo
                        key={el.key}
                        title={el.value.title}
                        text={el.value.message}
                    />
                );
                elArr.push(cardEl);
                //setmsgTags((arr) => [...arr, cardEl]);
            }
        });
        return elArr;
    };

    const getgMessages = () => {
        setgmessages([]);
        messages.forEach((el) => {
            if (el.value.filePath) {
                let cardEl = (
                    <CardInforGraphic
                        key={el.key}
                        link={el.value.link}
                        title={el.value.title}
                        filePath={el.value.filePath}
                        picLoadingFun={loadMessagesPictures}
                    />
                );
                setgmessages((prevArray) => [...prevArray, cardEl]);
            }
        });
    };

    const loadMessagesPictures = (path) => {
        return new Promise((resolve, reject) => {
            getPictureUrl(path)
                .then((url) => {
                    resolve(url);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    };

    /* eslint-disable */
	useEffect(() => {
		connectToDb();
		return () => {};
	}, []);

	/* eslint-disable */
	useEffect(() => {
		getgMessages();
		return () => {};
	}, [messages]);

	return (
		<div className='newsmesagges-containter'>
			{gmessages}
			{messages ? getMessages() : null}
		</div>
	);
};

export default NewsMessages;
