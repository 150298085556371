import React from 'react';
import { getShortDate } from '../../../../store/actions/calendar';


const EventToolDepositInfo = ({charge, newDate, selectedDate}) => {
    return (
        <div className='event-tool-details-deposit'>
            {charge ? 'Kaucja: ' + charge : null}
            {charge ? <br /> : null}
            {getShortDate(newDate) !== getShortDate(selectedDate)
                ? 'Do: ' + getShortDate(newDate)
                : null}
        </div>
    );
};

export default EventToolDepositInfo;