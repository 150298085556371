import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AchievementScore from '../../components/AchievementScore/AchievementScore';
import SocialScore from '../../components/SocialScore/SocialScore';
import {
    getCustomerAchievementsDetails,
} from '../../store/actions/lrnAPI';

import './MyAchievements.css';

const MyAchievements = () => {
    const dispatch = useDispatch();
    const achievements = useSelector(
        (state) => state.customerStore.customerAchievements
    );
    const userId = useSelector((store) => store.customerStore.customerData.id);
    const scores = useSelector(
        (store) => store.customerStore.customerDetails.SocialScores
    );
    const apiToken = useSelector((store) => store.uiStore.apiToken);
    const loadAchievements = () => {
        dispatch(getCustomerAchievementsDetails(userId, apiToken));
    };

    /* eslint-disable */
	useEffect(() => {
		loadAchievements();
	}, []);

	return (
		<div className='myachievements-container'>
			{achievements.length && (
				<div className='myachievements-dances'>
					<div className='myachievements-title'>
						Dance styles
					</div>
					{achievements.map((a) => (
						<AchievementScore
							key={a.EventId}
							name={a.EventName}
							stars={a.LevelId - 1}
						/>
					))}
				</div>
			)}
			<div className='myachievements-title'>Oceny <span className='myachievements-subtitle'>Total</span></div>
			<div>
				<SocialScore
					label='Technika'
					scores={scores?.ScoreCategory1}
					sentByCount={scores.ScoreCategoryCount1}
				/>
				<SocialScore
					label='Flow'
					scores={scores?.ScoreCategory2}
					sentByCount={scores.ScoreCategoryCount2}
				/>
				<SocialScore
					label='Emocje'
					scores={scores?.ScoreCategory3}
					sentByCount={scores.ScoreCategoryCount3}
				/>
			</div>
		</div>
	);
};

export default MyAchievements;
