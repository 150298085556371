import React from 'react';

const MessagesPage = () =>{
    return(
        <div>
            Messages
        </div>
    );
};


export default MessagesPage;