import { logEvent } from 'firebase/analytics';
import { setEventBeingChanged, setEvetnsArray, setEvetnsArrayElement } from '../actions/events';
import { sendRequest } from '../actions/lrnAPI';
import { logout, setError, setInfo, showProgressBar } from '../actions/ui';
import { analytics } from '../../firebase/Firebase';
import { action_postpone_register, action_postpone_removed } from '../../analytics/events';
import { addMinutes, removeTimeZone } from '../actions/calendar';


export const addEventFeePayment = (custId, request) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {            
            if (!custId || !request) {
                dispatch(setError({ message: 'Missing custId or request' }));
                return false;
            }
            const state = getState();
            const apiToken = state.uiStore.apiToken;
            
            const endpoint = 'CustEvents/customer/'+custId+'/fee/create';
            sendRequest(endpoint, request, apiToken, 'post')
                .then((response) => {		
                    switch (response.status) {
                    case 200:
                        resolve(response.data);
                        break;	
                    case 208:
                        dispatch(setInfo(response.data.message));
                        break;					
                    default:
                        dispatch(setError(response));
                        break;
                    }
                })
                .catch((err) => {				
                    dispatch(setError(err));
                    reject();
                });
        });
    };
};

export const getCustomerEvents = (
    custId,
    selectedDate,
    apiToken,
    withinHours
) => {
    return (dispatch) => {
        //save token to storage
        if (!custId) {
            dispatch(setError({ message: 'Missing customer Id to get Events' }));
            return false;
        }
        dispatch(showProgressBar(true));
        let startDateUTC = removeTimeZone(new Date(selectedDate));
        let startDate = new Date(selectedDate);
        let endDate = new Date(selectedDate);
        if (!withinHours) {
            startDate.setHours(0, 0, 0);
            removeTimeZone(startDate);
            endDate.setHours(23, 59, 0);
            removeTimeZone(endDate);
        } else {
            startDate = new Date(addMinutes(startDateUTC, -30 * withinHours));
            endDate = new Date(addMinutes(startDateUTC, 30 * withinHours));
        }

        const model = {
            DateFrom: startDate.toISOString(),
            DateTo: endDate.toISOString(),
            IncludeReplacements: false,
        };

        sendRequest('CustEvents/customer/' + custId, model, apiToken)
            .then((response) => {
                dispatch(showProgressBar(false));
                switch (response.status) {
                case 200:
                    dispatch(setEvetnsArray(response.data));
                    break;
                case 401:
                    dispatch(logout());
                    break;
                default:
                    dispatch(setError(response));
                    break;
                }
            })
            .catch((err) => {
                dispatch(showProgressBar(false));
                switch (err.status) {
                case 401:
                    dispatch(logout());
                    break;
                default:
                    break;
                }
                dispatch(setError(err));
            });
    };
};

export const getCustomerEvent = (id) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) =>{
        //save token to storage
            if (!id) {
                dispatch(setError({ message: 'Missing customer Id to get Events' }));
                return false;
            }
            const state = getState();
            const apiToken = state.uiStore.apiToken;

            
            sendRequest('CustEvents/' + id, null, apiToken, 'get')
                .then((response) => {
                    switch (response.status) {
                    case 200:
                        resolve(response.data);
                        break;
                    case 204:
                        resolve();
                        break;
                    default:
                        dispatch(setError(response));
                        reject();
                        break;
                    }
                })
                .catch((err) => {
                    switch (err.status) {
                    default:
                        break;
                    }
                    dispatch(setError(err));
                    reject();
                });
        });
    };
};


export const postponeEvent = (eventId, apiToken) => {
    return (dispatch) => {
        return new Promise((resolve, reject)=>{
        //save token to storage
            if (!eventId) {
                dispatch(setError({ message: 'Missing customer Event Id' }));
                return false;
            }
            dispatch(setEventBeingChanged(eventId));

            sendRequest('CustEvents/postpone/' + eventId, null, apiToken, 'get')
                .then((response) => {
                    dispatch(setEventBeingChanged(eventId, true));
                    switch (response.status) {
                    case 200:
                    //reload events
                    //change status in id to postponed antycypating change
                        dispatch(getCustomerEvent(eventId))
                            .then((rec)=>{
                                if(rec)
                                {
                                    dispatch(setEvetnsArrayElement(rec, eventId));
                                }
                                else
                                {
                                    dispatch(setInfo('Odśwież dane'));
                                }
                                resolve();
                            });
                        logEvent(analytics,
                            action_postpone_register.name,
                            action_postpone_register.param
                        );
                        break;
                    case 401:
                        dispatch(logout());
                        reject();
                        break;
                    default:
                        dispatch(setError(response));
                        reject();
                        break;
                    }
                    dispatch(setEventBeingChanged(eventId, true));
                })
                .catch((err) => {
                    switch (err.status) {
                    case 401:
                        dispatch(logout());
                        break;
                    default:
                        break;
                    }
                    reject();
                    dispatch(setEventBeingChanged(eventId, true));
                    dispatch(setError(err));
                });
        });
    };
};


export const removePostponeEvent = (
    eventId,
    custEntryId,
    apiToken,

) => {
    return (dispatch) => {
        return new Promise((resolve, reject)=>{
        //save token to storage
            if (!eventId) {
                dispatch(setError({ message: 'Missing customer Event Id' }));
                return false;
            }
            
            sendRequest(
                'CustEntries/delete/postpone/' + custEntryId,
                null,
                apiToken,
                'delete'
            )
                .then((response) => {
                    switch (response.status) {
                    case 200:
                    //reload events
                    //change status in id to postponed antycypating change
                        dispatch(getCustomerEvent(eventId))
                            .then((rec)=>{
                                if(rec)
                                {
                                    dispatch(setEvetnsArrayElement(rec, eventId));
                                }
                                else
                                {
                                    dispatch(setInfo('Odśwież dane'));
                                }
                                resolve();
                            });
                        logEvent(analytics,
                            action_postpone_removed.name,
                            action_postpone_removed.param
                        );
                        break;
                    default:
                        dispatch(setError(response));
                        reject();
                        break;
                    }
                })
                .catch((err) => {
                    switch (err.status) {
                    case 401:
                        dispatch(logout());
                        break;
                    default:
                        break;
                    }
                    reject();
                    dispatch(setError(err));
                });
        });
    };
};

export const confirmPresence = (custEventId) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {            
            if (!custEventId) {
                dispatch(setError({ message: 'Missing custEventId' }));
                return false;
            }
            const state = getState();
            const apiToken = state.uiStore.apiToken;
            
            const endpoint = 'CustEvents/confirm/'+custEventId;
            sendRequest(endpoint, null, apiToken, 'patch')
                .then((response) => {		
                    switch (response.status) {
                    case 200:
                        resolve(response.data);
                        break;	
                    case 208:
                        dispatch(setInfo(response.data.message));
                        break;					
                    default:
                        dispatch(setError(response));
                        break;
                    }
                })
                .catch((err) => {				
                    dispatch(setError(err));
                    reject();
                });
        });
    };
};