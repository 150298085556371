import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getString } from 'firebase/remote-config';
import { remoteConfig } from '../../../firebase/Firebase';
import { getPurchaseOptions } from '../../../store/lrnAPI/purchase';
import { PURCHASE_EARLY_TERM_DISCL } from '../../../firebase/configParameters';

import BuyEventButton from './BuyEventButton/BuyEventButton';
import CancelEventButton from './CancelEventButton/CancelEventButton';
import ToggleButton from '../../UI/ToggleButton/ToggleButton';

import './EventActions.css';
import { quantityMapping } from '../../../utils/dataMappings';
import { updateCustomerSignup, getCustomerSignups } from '../../../store/lrnAPI/custContractsEntries';
import { useNavigate } from 'react-router';
import ButtonWithSpinner from '../../UI/ButtonWithSpinner/ButtonWithSpinner';



const EventActions = ({ event }) => {
    const dispatch = useDispatch();
    const nav = useNavigate();
    
    // setBuyOptions([
    //     { Quantity: 4, Price: 40, Desc: '4 wejścia' },
    //     { Quantity: 8, Price: 80, Desc: '8 wejść' },
    //     { Quantity: 999, Price: 120, Desc: 'OPEN' },
    // ]);
    const [buyOptions, setBuyOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [disclaimer] = useState(getString(remoteConfig,PURCHASE_EARLY_TERM_DISCL));
    const [discCode, setDiscCode] = useState();
    const [selectedPurchaseOptionId, setSelectedPurchaseOptionId] = useState(0);


    const [optionsDisabled, setOptionsDisabled] = useState(true&&disclaimer);
    const [alreadyBought] = useState(false);
    const userId = useSelector((store) => store.customerStore.customerData.id);
    const apiToken = useSelector((store) => store.uiStore.apiToken);

    const renderOptions = () => {
        const res = [];
        buyOptions.map((purchaseOpt) => {            
            res.push(
                <div key={purchaseOpt.Id} className='event-actions-purchaseOptions-btn'> 
                    <BuyEventButton id={'purchaseOption-'+purchaseOpt.Id} key={purchaseOpt.Id} 
                        event={event} purchase={purchaseOpt}
                        disabled={optionsDisabled}
                        reloadFunction={reLoadData}
                        monthlyPayments={purchaseOpt.PeriodicalCardCharges}
                        selectedPurchaseOptionId = {selectedPurchaseOptionId}
                        setSelectedPurchaseOptionId = {setSelectedPurchaseOptionId}
                    >
                        {purchaseOpt.Desc + ' | '+ quantityMapping(purchaseOpt.Quantity)  +' | ' + purchaseOpt.Price + ' zł'}
                    </BuyEventButton>
                </div>
            );
        });
        return res;
    };

    const onDisclaimerClick = () => { 
        setOptionsDisabled(!optionsDisabled);    
    };

    const reLoadData = () => {
        dispatch(getCustomerSignups(userId, apiToken, event.Id));
    };

    const getBoughtMessage = () => { 
        if(event.PaymentAmount === 0)
            return <div className='app-text-label-stamp'>Czekamy na potwierdzenie płatności.</div>;
        else
            return <div className='app-text-label-stamp'>Karnet i płatności zarejestrowana. Dziękujemy!</div>;
    };
     
    const loadData = () => {
        setLoading(true);
        dispatch(getPurchaseOptions(event.Id, !discCode?'0':discCode)).then((d) => {   
            setBuyOptions(d);
            setLoading(false);
        });

    };

    const onSignOutFromClasses = () => { 
        dispatch(updateCustomerSignup(event.Id, {
            FieldName: 'StatusId',
            IntVal: 4
        }))
            .then(()=>{
                nav(-1);
            });
    };

    useEffect(() => {        
        loadData();
    }, [event]);

    return (
        <>
            {alreadyBought ?
                getBoughtMessage()
                :
                <div className='event-actions'>
                    {disclaimer ?
                        <div className='event-disclaimer' >
                            <div className='event-disclaimer-text' > {disclaimer} </div>
                            <div className='event-disclaimer-button' > 
                                <ToggleButton id='event-disclaimer-btn' checked={!optionsDisabled} onChange={onDisclaimerClick} />
                            </div>
                            <div className='event-disclaimer-instruction' > 
                    W celu aktywowania opcji zaznacz powyższą zgodę
                            </div>
                        </div>
                        :null
                    }
                    <div className='event-actions-discount'>
                        <input onChange={(val)=>setDiscCode(val.currentTarget.value)} placeholder='Kod rabatowy' />
                        <ButtonWithSpinner id={'event-actions-discount-btn'} 
                            className={'main'}
                            disabled={false}
                            onClick={loadData} 
                            show={true}>
                                Zastosuj
                        </ButtonWithSpinner>
                    </div>
                    {loading ? <Spinner animation='border' role='status' /> : 
                        <div className='event-actions-purchaseOptions'> 
                            <div className='event-actions-purchaseOptions-title'>Wybierz opcję zakupu karnetów</div>
                            {renderOptions()}
                        </div>
                    }
                    <div className='event-actions-signOut'> 
                        <div className='event-actions-signOut-btn'> 
                            <CancelEventButton executeActionFunction={onSignOutFromClasses} >Anuluj zapis na zajęcia</CancelEventButton>
                        </div>
                    </div>
                </div>
                
            }
        </>
    );
};

export default EventActions;
