import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedDate } from '../../store/actions/calendar';

import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';
import './CalendarDatePicker.css';

const CalendarDatePicker = () => {
    const selectedDate = useSelector((store) => store.calendarStore.selectedDate);

    const dispatch = useDispatch();

    const onChange = (selectedDate) => {
        dispatch(setSelectedDate(selectedDate));		
    };

    return (
        <Calendar
            className='app-bg'
            onChange={onChange}
            value={new Date(selectedDate)}
            calendarType='ISO 8601'
            oneWeekCalendar={true}
            locale='pl'
            showFixedNumberOfWeeks={false}
            view='month'
        />
    );
};

export default CalendarDatePicker;

