//Libs
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './SignupForm.css';

//Utils
import { status } from '../Utils';
import { logout } from '../../../store/actions/ui';

//Requests
import {
    checkUserNameExists,
} from '../../../store/actions/lrnAPI';

//Hooks
import { useForm } from 'react-hook-form';
import useAgreements from '../../../hooks/useAgreements';
import { REGEX_ONLY_LETTERS } from '../../../utils/regexPatterns';
import { registerNewUser } from '../../../store/lrnAPI/auth';


const SignupForm = (props) => {
    const { handleChangeStatus, userLoginInfo } = props;
    const { termsOfUse, privacyPolicy } = useAgreements();

    const firebaseToken = useSelector((state) => state.uiStore.firebaseToken);
    const firebaseTokenDecoded = useSelector(
        (state) => state.uiStore.firebaseTokenDecoded
    );
    const dispatch = useDispatch();
    const [userNameValidationTxt, setuserNameValidationTxt] = useState();
    const [gender, setGender] = useState('M');
    const { getValues, setValue, register, formState: { errors }, handleSubmit, watch } = useForm({
        mode: 'onChange',
    });

    const watchGender = watch('Gender');
    const watchPhoneNumber = watch('PhoneNumber');

    const onSubmit = (data) => {
        dispatch(registerNewUser(data));
    };

    const checkUserName = (userName) => {
        if (userName.length > 5) {
            dispatch(checkUserNameExists(userName)).then((res) => {
                if (res) {
                    setuserNameValidationTxt('Nazwa użytkownika już istnieje');
                } else {
                    setuserNameValidationTxt('');
                }
            });
        } else {
            setuserNameValidationTxt('');
        }
    };

    const validatePass = (val) => { 
        let res = false;
        if(val){
            if(val.length < 6) 
                res = false;
            else
                res = true;
        }
        else{
            res = true;
        }
        return res;
    };

    const returnToLogin = () => {
        handleChangeStatus(status.NONE);
        dispatch(logout());
    };

    const setPhoneNoPrefix = () => {
        const val = getValues().PhoneNumber;
        if (val || val === '')
            setValue('PhoneNumber', '+48');
    };

    useEffect(() => {
        setGender(getValues('Gender'));
    }, [watchGender]);

    useEffect(() => {
        if (/\+48[0-9]{9}/.test(watchPhoneNumber)) {
            // TODO: Make fetch request to check phone number
            console.log(watchPhoneNumber);
        }
    }, [watchPhoneNumber]);

    return (
        <div className='signup-container'>
            <h3>Zarejestruj się</h3>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='form-group'>
                    <label>Imię</label>
                    <input
                        name='FirstName'
                        type='text'
                        className='form-control'
                        placeholder='Imię'
                        defaultValue={userLoginInfo.firstName}
                        {...register('FirstName', {
                            required: true,
                            minLength: 2,
                            pattern: REGEX_ONLY_LETTERS,
                        })}
                    />
                    {errors.FirstName && <p>Wprowadź poprawną wartość</p>}
                </div>

                <div className='form-group'>
                    <label>Nazwisko</label>
                    <input
                        name='LastName'
                        type='text'
                        className='form-control'
                        placeholder='Nazwisko'
                        defaultValue={userLoginInfo.lastName}
                        {...register('LastName', {
                            required: true,
                            minLength: 2,
                            pattern: REGEX_ONLY_LETTERS,
                        })}
                    />
                    {errors.LastName && <p>Wprowadź poprawną wartość</p>}
                </div>

                <div className='form-group'>
                    <label>Email</label>
                    <input
                        name='Email'
                        type='email'
                        className='form-control'
                        placeholder='Email'
                        defaultValue={firebaseTokenDecoded.payload.email ?? null}
                        readOnly={firebaseTokenDecoded.payload.email ?? null ? true : false}
                        {...register('Email', {
                            required: true,
                            /* eslint-disable */
							pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
						})}
					/>
					{errors.Email && <p>Wprowadź poprawną wartość</p>}
				</div>

				<div className='form-group'>
					<label>Nazwa użytkownika</label>
					<input
						name='UserName'
						type='text'
						className='form-control'
						{...register('UserName', {
							required: true,
							minLength: 6,
							/* eslint-disable */
							pattern: /^[A-Za-z0-9]+$/,
							validate: val => checkUserName(val)
						})}
					/>
					<div className='text-help'>
						Nazwa użytkownika, pozwoli innym użytkownikom wyszukać Cię i dodać
						do swoich kontaktów.
					</div>
					{errors.UserName && (
						<p>Wprowadź poprawną wartość (6 znaków - litery (bez polskich znaków) + cyfry)</p>
					)}
					{userNameValidationTxt && (
						<p>Użytkownik o takiej nazwie już istnieje</p>
					)}
				</div>

				<div className='form-group'>
					<label>Telefon</label>
					<input
						name='PhoneNumber'
						inputMode="tel"
						pattern="\+48[0-9]{9}"
						type="tel"
						className='form-control'
						placeholder='Nr telefonu'
						defaultValue={firebaseTokenDecoded.payload.phone_number ?? null}
						readOnly={
							firebaseTokenDecoded.payload.phone_number ?? null ? true : false
						}
						onFocus={!firebaseTokenDecoded.payload.phone_number ? setPhoneNoPrefix : null}
						{...register('PhoneNumber', { required: true, pattern: /\+48[0-9]{9}/ })}
					/>
					{errors.PhoneNumber && (
						<p>Wprowadź wartość w formacie +48[9 cyfr] </p>
					)}
				</div>

				<div className='form-group'>
					<label>Płeć</label>
					<select
						id='Gender'
						name='Gender'
						className='form-control'
						placeholder='Płeć'
						{...register('Gender', { required: true, maxLength: 1 })}>
						<option value=''>Wybierz</option>
						<option value='M'>Mężczyzna</option>
						<option value='K'>Kobieta</option>
					</select>
					{errors.Gender && <p>Wprowadź poprawną wartość</p>}
				</div>
                <div className='form-group'>
                    <label>Hasło (opcjonalne)</label>
					<div className='form-group'>
                        <input type='password'
                            className='form-control form-control-sm'
                            name='Password'                                                
                            placeholder='Podaj hasło' 
							{...register('Password', {
								validate:(val) => validatePass(val)
							})}
							>	
							</input>
							{errors.Password && <p>Wprowadź min. 6 znaków</p>}
                    </div>
                </div>
				<div className='form-group'>
					<div className='signup-checkbox-container'>
						<input
							id='Terms'
							type='checkbox'
							name='Terms'
							className='signup-checkbox-input'
							{...register('Terms', { required: true })}
						/>
						<label htmlFor='Terms' className='form-check-label'>
							Potwierdzam, że
							{gender == 'M' ? ' zapoznałem ' : ' zapoznałam '}
							się z{' '}
							{termsOfUse ?
								<a href={termsOfUse.url} target='_blank'>
									Regulaminem
								</a>
								: <div>Regulaminem</div>
							}
							{' '}i akceptuję ich treść.
						</label>
					</div>
					{errors.Terms && <p>To pole jest wymagane</p>}
				</div>
				<div className='form-group'>
					<div className='signup-checkbox-container'>
						<input
							id='PrivacyPolicy'
							type='checkbox'
							name='PrivacyPolicy'
							className='signup-checkbox-input'
							{...register('PrivacyPolicy', { required: true })}
						/>
						<label htmlFor='PrivacyPolicy' className='signup-checkbox-label'>
							Potwierdzam, że
							{gender == 'M' ? ' zapoznałem ' : ' zapoznałam '}
							się z{' '}
							{privacyPolicy ?
								<a href={privacyPolicy.url} target='_blank'>
									Polityka prywatności
								</a>
								: <span>Polityka prywatności</span>
							}
							{' '}i akceptuję ich treść.
						</label>
					</div>
					{errors.PrivacyPolicy && <p>To pole jest wymagane</p>}
				</div>
				<input
					name='Token'
					type='text'
					className='form-control'
					defaultValue={firebaseToken}
					hidden={true}
					{...register('Token', { required: true })}
				/>
				{errors.Token && <p>Brak potwierdzonego email lub telefonu</p>}
				<button
					className='btn btn-primary btn-block submit-button'
					onClick={() => handleSubmit()}>
					Zarejestruj
				</button>
			</form>
			<div className='registered-container'>
				<div className='forgot-password text-right' onClick={returnToLogin}>
					Posiadasz już konto? Zaloguj ponownie.
				</div>
			</div>
		</div>
	);
};

export default SignupForm;
