import React from 'react';
import NewSchedules from '../../containers/NewSchedules/NewSchedules';
import ActiveMenuMonitor from '../../components/Nav/ActiveMenuMonitor/ActiveMenuMonitor';

const NewSchedulesPage = () => {
    return (
        <div>
            <ActiveMenuMonitor menuId={4}/>
            <NewSchedules/>
        </div>
    );
};

export default NewSchedulesPage;
