import { sendRequest } from '../actions/lrnAPI';
import { setError, setInfo } from '../actions/ui';
import CreatePaymentRequest from '../../models/requests/CreatePaymentRequest';


export const createDepositPayment = (custId, apiToken) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {            
            if (!custId) {
                dispatch(setError({ message: 'Missing customer Id to create Deposit payment' }));
                reject();
                return false;
            }
		
            const endpoint = 'payu/order/deposit/create/' + custId ;
            sendRequest(endpoint, null, apiToken, 'post')
                .then((response) => {		
                    switch (response.status) {
                    case 200:
                        resolve(response.data);
                        break;	
                    case 208:
                        dispatch(setInfo(response.data.message));
                        reject();
                        break;					
                    default:
                        dispatch(setError(response));
                        reject();
                        break;
                    }
                    reject();
                })
                .catch((err) => {				
                    dispatch(setError(err));
                    reject();
                });
        });
    };
};

export const addPaymentWithPayU = (cceId, req) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {            
            if (!cceId) {
                dispatch(setError({ message: 'Missing event id' }));
                return false;
            }
            const state = getState();
            const apiToken = state?.uiStore?.apiToken;
            const custId = state?.customerStore.customerData.id;

            if(!apiToken || !custId || !req)
            {
                dispatch(setError({ message: 'Missing apiToken or custId or request' }));
                reject();
                return false;
            }

            if(!(req instanceof CreatePaymentRequest))
            {
                dispatch(setError({ message: 'Wrong request type' }));
                reject();
                return false;
            }

            const endpoint = 'payu/order/payment/create/'+custId  ;
            sendRequest(endpoint, req, apiToken, 'post')
                .then((response) => {		
                    switch (response.status) {
                    case 200:
                        resolve(response.data);
                        break;	                   
                    default:
                        dispatch(setError(response));
                        reject();
                        break;
                    }
                })
                .catch((err) => {                    
                    dispatch(setError(err));
                    reject();
                });
        });
    };
};
