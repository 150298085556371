import { sendRequest } from '../actions/lrnAPI';
import { setError } from '../actions/ui';


const endpoint = 'CustEntries';

export const loadPaymentsWithActions = () => {
    return (dispatch, getState) => {
        
        const state = getState();
        const apiToken = state.uiStore.apiToken;
        const custId = state.customerStore.customerData.id;

        if (!apiToken || !custId) {
            dispatch(setError({ message: 'Missing api token or cust Id' }));
            return false;
        }    
        const url = endpoint + '/payments/requiredaction/'+custId;
        return sendRequest(url, null, apiToken, 'get');          
        
    };
};

export const updatePaymentStatus = (ceId, statusCode, refReqId, ) => {
    return (dispatch, getState) => {
        
        const state = getState();
        const apiToken = state.uiStore.apiToken;

        if (!apiToken) {
            dispatch(setError({ message: 'Missing api token' }));
            return false;
        }    
        const url = endpoint + '/update/payment/status';
        var data = {'CustEntryId':ceId, 'StatusCode': statusCode, 'RefReqId': refReqId};
        return sendRequest(url, data, apiToken, 'post');          
        
    };
};  

export const loadDepositHistory = () => {
    return (dispatch, getState) => {
        
        const state = getState();
        const apiToken = state.uiStore.apiToken;
        const custId = state.customerStore.customerData.id;

        if (!apiToken || !custId) {
            dispatch(setError({ message: 'Missing api token or cust Id' }));
            return false;
        }    
        const url = endpoint + '/deposit/'+custId+'/list';
        return sendRequest(url, null, apiToken, 'get');          
        
    };
};