import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { getSignupQuantityText } from '../../../components/CategoryItemsList/helpers/categoryItemsCalcs';
import EventActions from '../../../components/EventDetails/EventActions/EventActions';
import EventDetails from '../../../components/EventDetails/EventDetails';
import { getShortDateDMY } from '../../../store/actions/calendar';
import { updateCustomerSignup, getCustomerSignups } from '../../../store/lrnAPI/custContractsEntries';
import { isDateAfterOrSameDateOnly } from '../../../utils/helpers/dateTimeHelper';
import './MySignupPage.css';

const MySignupPage = () => {
    const dispatch = useDispatch();
    const userId = useSelector((store) => store.customerStore.customerData.id);
    const apiToken = useSelector((store) => store.uiStore.apiToken);
    const signups = useSelector((state) => state.eventsStore.signups);
    //const loading = useSelector((state) => state.uiStore.inProgress);
    const { id } = useParams();
    const navigate = useNavigate();

    const event = useMemo(
        () => signups.find(({ Id }) => Number(Id) === Number(id)),
        [id, signups],
    );

    const onGoBack = () => {
        //TODO navigate -1 does not pass state
        //Navigation back is now necessary from myevents and calendar event
        navigate(-1, {
            state: {
                focusedItemId: event.Id,
            },
        });
        // navigate('/myevents', {
        //     state: {
        //         focusedItemId: event.Id,
        //     },
        // });
    };

    const onPaymentTypeChange = (data) => {
        dispatch(updateCustomerSignup(event.Id, {
            FieldName: 'PaymentTypeId',
            IntVal: data.value
        }));
    };

    const onPreferredStartDateChange = (date) => {
        dispatch(updateCustomerSignup(event.Id, {
            FieldName: 'PreferredStartDate',
            StrVal: date
        }));
    };
    
    const getValidTillText = () => { 
        if(isDateAfterOrSameDateOnly(event.ValidTill, new Date()))
        {
            return getShortDateDMY(event.ValidTill);
        }
        else
        {
            return '-';
        }
    };

    useEffect(() => {        
        if (!event) {
            dispatch(getCustomerSignups(userId, apiToken));
        }
    }, [apiToken, dispatch, userId, event]);

    if (!event) {
        return null;    
    }

    return (
        <div className='mySignup-container'>
            <div className="header">
                <svg
                    onClick={onGoBack}
                    className="header-arrow"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="21"
                    viewBox="0 0 12 21"
                    fill="none"
                >
                    <path fillRule="evenodd" clipRule="evenodd" d="M9.53714 20.0827L0.292152 10.9458C-0.097384 10.5612 -0.097384 9.93981 0.292152 9.5542L9.53714 0.417333C10.0995 -0.139111 11.0144 -0.139111 11.5777 0.417333C12.14 0.973776 12.14 1.87687 11.5777 2.43332L3.66913 10.2505L11.5777 18.0657C12.14 18.6231 12.14 19.5262 11.5777 20.0827C11.0144 20.6391 10.0995 20.6391 9.53714 20.0827Z" fill="white"/>
                </svg>
                <span className="header-title">
                    {event.EventName} {event.Level} {event.EventDetails}
                </span>                
            </div>
            <div className="my-signup-page">
                <EventDetails
                    event={event}
                    date={`${event.Day}, ${event.EventHour}`}
                    level={event.Level}
                    teachers={event.InstructorsNames}
                    statusId={event.StatusId}
                    paymentTypes={event.PaymentTypes}
                    paymentType={event.PaymentTypeId}
                    onPaymentTypeChange={onPaymentTypeChange}
                    onPreferredStartDateChange={onPreferredStartDateChange}
                    validTillTxt={getValidTillText()}	
                    noOfEntriesOnPassLeft={getSignupQuantityText(event)??'-'}
                />
                <div className="my-signup-page-actions">
                    {(event.StatusId !== 4 && event.StatusId !== 6) ?
                        <EventActions event={event} />
                        : null 
                    }
                </div>
            </div>
        </div>
    );
};

export default MySignupPage;
