//libs
import React, { useEffect, useState } from 'react';
import './FeedbackForm.css';
import { database } from '../../firebase/Firebase';
import { ref, push } from 'firebase/database';

//components
import RadioButtons from '../../components/UI/RadioButtons/RadioButtons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faAngry,
    faFrown,
    faMeh,
    faSmile,
    faLaughBeam,
} from '@fortawesome/free-solid-svg-icons';
import VersionInfo from '../../components/VersionInfo/VersionInfo';

//hooks
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { setError, setMessage } from '../../store/actions/ui';

const FeedbackForm = () => {
    const dispatch = useDispatch();
    // const userGender = useSelector(
    // 	(state) => state.customerStore.customerData.gender
    // );
    const userId = useSelector((state) => state.customerStore.customerData.id);
    const apiConfigId = useSelector((store) => store.firebaseStore.selectedAPIConfigId);

    const [sendBtnEnabled, setSendBtnEnabled] = useState(false);

    const { register, watch, handleSubmit } = useForm({
        mode: 'onChange',
    });
    const watchFeedbackText = watch('feedbackText', false);
    const rootPath = apiConfigId+'/feedback';

    const saveGrade = (grade) => {
        if (grade) {
            let createDate = new Date().getTime();
            let payload = { createDate, userId, grade };

            const gradesRef = ref(database, rootPath+'/grades');
            push(gradesRef, payload)
                .then(()=>{
                    dispatch(setMessage({message:'Dziękujemy za ocenę.'}));
                })
                .catch((e) => {
                    dispatch(setError(e));
                });
        } else {
            dispatch(
                setError(
                    'Coś poszło nie tak i nie wszystkie dane zostały zapisany, odśwież stronę i spróbuj ponownie.'
                )
            );
        }
    };

    const saveComment = (data, e) => {
        if (data.feedbackText) {
            let createDate = new Date().getTime();
            let comment = data.feedbackText;
            let payload = { createDate, userId, comment };

            const commentRef = ref(database, rootPath+'/comments');
            push(commentRef, payload)
                .then(() => {
                    dispatch(setMessage({ message: 'Dziękujemy za komentarz!' }));
                    e.target.reset();
                    setSendBtnEnabled(false);
                })
                .catch((e) => {
                    dispatch(setError(e));
                });            
        } else {
            dispatch(
                setError(
                    'Coś poszło nie tak i nie wszystkie dane zostały zapisany, odśwież stronę i spróbuj ponownie.'
                )
            );
        }
    };

    const options = [
        { value: 'angry', label: <FontAwesomeIcon icon={faAngry} size='2x' /> },
        { value: 'sad', label: <FontAwesomeIcon icon={faFrown} size='2x' /> },
        { value: 'neutral', label: <FontAwesomeIcon icon={faMeh} size='2x' /> },
        { value: 'happy', label: <FontAwesomeIcon icon={faSmile} size='2x' /> },
        { value: 'extra', label: <FontAwesomeIcon icon={faLaughBeam} size='2x' /> },
    ];

    useEffect(() => {
        if(watchFeedbackText && watchFeedbackText.length > 5)
            setSendBtnEnabled(true);
        else
            setSendBtnEnabled(false);
    }, [watchFeedbackText]);
    

    return (
        <div className='feedback-container'>
            <div className='feedback-options'>
                <RadioButtons
                    selectList={options}
                    id='feedbackOptions'
                    name='feedbackOptions'
                    title='Powiedz nam co sądzisz o naszej aplikacji'
                    onChange={(e) => saveGrade(e.target.value)}					
                />
            </div>
            <div className='feedback-form'>
                <form onSubmit={handleSubmit(saveComment)}>
                    <div className='feedback-text'>
                        <label>Sugestie, komentarze: </label>
                        <textarea
                            name='feedbackText'
                            className='feedback-text-input app-boxes-shadow'
                            {...register('feedbackText', {
                                required: true,
                                minLength: 5,
                            })}></textarea>
                    </div>
                    <button
                        className='confirm'
                        type='submit'
                        disabled={!sendBtnEnabled}>
                        {' '}
						Wyślij
                    </button>
                </form>
            </div>
            <div className='feedback-footer'>
                <div><VersionInfo/></div>
            </div>
        </div>
    );
};

export default FeedbackForm;
