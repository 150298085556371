import React from 'react';
import FeedbackForm from '../../containers/FeedbackForm/FeedbackForm';
import './FeedbackPage.css';

const FeedbackPage = () => {
    return (
        <div className='feedbackpage-containter'>
            <FeedbackForm/>
        </div>
    );
};

export default FeedbackPage;
