import React from 'react';
import Star from './Star/Star';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStarHalf } from '@fortawesome/free-solid-svg-icons';

import './AchievementScore.css';

const AchievementScore = (props) => {
    const starsPrint = () => {
        const stars = [];
        let n = 0;
        if (props.stars === 0) {
            stars.push(<FontAwesomeIcon key={n} icon={faStarHalf} />);
        } else {
            for (n = 1; n <= props.stars; n++) {
                stars.push(<Star key={n} />);
            }
        }
        return stars;
    };

    return (
        <div className='achievementscore-containter'>
            <div className='achievementscore-name'>{props.name}</div>
            {props.score > 0 ? (
                <div
                    className={
                        'achievementscore-score achievementscore-score-cat' +
						props.category
                    }>
                    {props.score}
                </div>
            ) : (
                <div className={'achievementscore-stars'}>{starsPrint()}</div>
            )}
        </div>
    );
};

export default AchievementScore;
