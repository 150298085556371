import { sendRequest } from '../actions/lrnAPI';
import { setError } from '../actions/ui';


const endpoint = 'salesPayments/';

export const getRegularPayments = () => {
    return (dispatch, getState) => {
    
        

        const state = getState();
        const apiToken = state.uiStore.apiToken;
        const custId = state.customerStore.customerData.id;

        if (!custId || !apiToken) {
            dispatch(setError({ message: 'Missing custId or token' }));
            return false;
        }
        let endPoint = endpoint + custId;
        return sendRequest(endPoint, null, apiToken, 'get');
    };
};

export const removePayment = (id) => {
    return (dispatch, getState) => {
        const state = getState();
        const apiToken = state.uiStore.apiToken;
        const custId = state.customerStore.customerData.id;

        if (!id) {
            dispatch(setError({ message: 'Missing Id' }));
            return false;
        }

        if (!custId || !apiToken) {
            dispatch(setError({ message: 'Missing custId or token' }));
            return false;
        }
        let endPoint = endpoint +id;
        return sendRequest(endPoint, null, apiToken, 'delete');
    };
};