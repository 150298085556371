import { auth } from '../../firebase/Firebase';
import { setCustDetails } from './customers';
import { customerDetailsUpdate } from './lrnAPI';
import { setError } from './ui';
import { FacebookAuthProvider, signInWithPopup, updatePassword } from 'firebase/auth';

export const facebookAuth = () => {
    return () => {
        var provider = new FacebookAuthProvider();
        auth.languageCode = 'pl';

        signInWithPopup(auth, provider)
            // eslint-disable-next-line no-unused-vars
            .then((result) => {				
                // The signed-in user info.
                // const user = result.user;

                // // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                // const credential = FacebookAuthProvider.credentialFromResult(result);
                // const accessToken = credential.accessToken;
            })
            .catch((error) => {
                // Handle Errors here.
                // const errorCode = error.code;
                // const errorMessage = error.message;
                // // The email of the user's account used.
                // const email = error.email;
                // AuthCredential type that was used.
                const credential = FacebookAuthProvider.credentialFromError(error);
				

                // An error happened.
                if (error.code === 'auth/account-exists-with-different-credential') {
                    // Step 2.
                    // User's email already exists.
                    // The pending Facebook credential.
                    //var pendingCred = error.credential;
                    // The provider account's email address.
                    //var email = error.email;
                    // Get sign-in methods for this email.

                    window.FB.api(
                        '/me',
                        {
                            fields: 'last_name,id,first_name,name',
                            access_token: credential.accessToken,
                        },
                        function () {}
                    );
                }
            });
    };
};

export const updateFirebasePassword = (password) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {			
            if (!password) {
                dispatch(setError({ message: 'Missing password' }));
                reject();
            }
            const state = getState();
            const customerDetails = state?.customerStore?.customerDetails ?? {};
            updatePassword(auth.currentUser,
                password)
                .then(() => {
                    dispatch(customerDetailsUpdate('Password', password))
                        .then(() => {
                            dispatch(
                                setCustDetails({
                                    ...customerDetails,
                                    PasswordIsSet: true,
                                })
                            );
                            resolve();
                        })
                        .catch((error) => {
                            dispatch(setError(error));
                            reject();
                        });
                })
                .catch((error) => {
                    dispatch(setError(error));
                    reject();
                });
        });
    };
};
