import React from 'react';
import './ConfirmModal.css';

const ConfirmModal = ({onConfirm, onCancel, title, message, children}) => {
    return (
        <div className='confirmModal-container'>
            <div className='confirmModal-top'>
                <div className='confirmModal-title'>
                    {title}
                </div>
                <div className='confirmModal-message'>
                    {message} {children}
                </div>
            </div>
            <div className='confirmModal-bottom'>
                <div className='confirmModal-buttons'>
                    <div className='confirmModal-cancel-btn' onClick={onCancel}>
						Nie
                    </div>
                    <div className='confirmModal-confirm-btn' onClick={onConfirm}>
						Tak
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfirmModal;