//Libs
import React from 'react';

//CSS
import './ListElementContainer.css';

//Components


const ListElementContainer = ({children, color}) => {
    return (
        <div className={'listElementContainer-container '}>
            <div className={'listElementContainer-edge listElementContainer-edge-' + color}>
                {children}
            </div>
        </div>
    );
};

export default ListElementContainer;