//Libs
import React, { useEffect, useState } from 'react';
import 'firebase/remote-config';
import { useSelector, useDispatch } from 'react-redux';

//CSS
import './CalendarDisplay.css';

//Actions
import {
    getCustomerProposals,
    createEventFromProposal,
} from '../../../store/actions/lrnAPI';
import {
    postponeEvent,
    removePostponeEvent,
    getCustomerEvents,
    confirmPresence
} from '../../../store/lrnAPI/custEvents';

import {
    getOnlyDateString,
    dayAsStringShort,
} from '../../../store/actions/calendar';
import CreatePaymentRequest from '../../../models/requests/CreatePaymentRequest';
import { addPaymentWithPayU } from '../../../store/lrnAPI/payU';
import { setError } from '../../../store/actions/ui';

//Components
import CalendarEventContainer from '../CalendarEventContainer/CalendarEventContainer';
import CalendarDatePicker from '../../CalendarDatePicker/CalendarDatePicker';
import EventTool from '../../common/EventTool/EventTool';
import Card from '../../UI/Card/Card';
import Tabs from '../../Tabs/Tabs';
import EventToolDepositInfo from '../../common/EventTool/EventToolDepositInfo/EventToolDepositInfo';
import ButtonWithSpinner from '../../UI/ButtonWithSpinner/ButtonWithSpinner';

import { getSignUpsStatusId } from '../../../models/Statuses';

const CalendarDisplay = () => {
    const dispatch = useDispatch();

    const events = useSelector((state) => state.eventsStore.events);
    const proposals = useSelector((state) => state.eventsStore.proposals);
    const selectedDate = useSelector((store) => store.calendarStore.selectedDate);
    const userId = useSelector((store) => store.customerStore.customerData.id);
    const apiToken = useSelector((store) => store.uiStore.apiToken);

    const [tabs] = useState([
        { id: 1, name: 'Moje' },
        { id: 2, name: 'Propozycje' },
    ]);
    const [activeTab, setActiveTab] = useState(1);
    const [postponeInProgressEventId, setPostponeInProgressEventId] = useState(
        '0',
    );
    const [paymentInProgressEventId, setPaymentInProgressEventId] = useState('0');
    const [confirmPresenceInProgressEventId, setConfirmPresenceInProgressEventId] = useState('0');

    // eslint-disable-next-line no-unused-vars
    const onChangeEventToolBtn = (id, val, custEntryId) => {
    //api function

        if (!id) dispatch(setError({ message: 'Event Id not available' }));
        if (val === undefined)
            dispatch(setError({ message: 'Change value not known' }));

        setPostponeInProgressEventId(id);
        if (val) {
            dispatch(postponeEvent(id, apiToken, userId, selectedDate))
                .then(() => {
                    setPostponeInProgressEventId('0');
                })
                .catch(() => {
                    setPostponeInProgressEventId('0');
                });
        } else {
            dispatch(
                removePostponeEvent(
                    id,
                    custEntryId,
                    apiToken,
                    userId,
                    getOnlyDateString(selectedDate),
                ),
            )
                .then(() => {
                    setPostponeInProgressEventId('0');
                })
                .catch(() => {
                    setPostponeInProgressEventId('0');
                });
        }
    };
    const onAdditionalFeeClick = (custEvent) => {
        return new Promise((resolve, reject) => {
            //call API to create charge and initiate PayU payment
            setPaymentInProgressEventId(custEvent.Id);
            const req = new CreatePaymentRequest(
                custEvent.AdditionalFee,
                '[SYSTEM] Dopłata do wejścia',
                custEvent.CustContractEntryId,
                custEvent.ScheduleId,
                null,
                custEvent.Id,
            );

            dispatch(addPaymentWithPayU(userId, req))
                .then((url) => {
                    if (url && url !== '') {
                        console.log('url', url);
                        window.open(url, '_self');
                    } else {
                        setPaymentInProgressEventId(0);
                        resolve();
                    }
                })
                .catch(() => {
                    setPaymentInProgressEventId(0);
                    reject();
                });
        });
    };

    const isActionAllowed = (event) => {
    //proposal
        if (event.StatusId === getSignUpsStatusId('proposal'))
            return event.IsAllowedToCreateReplacement;
        //active
        if (
            event.StatusId === getSignUpsStatusId('active') ||
      event.StatusId === getSignUpsStatusId('payment_needed')
        )
            return event.IsAllowedToPostpone;
        //postponed
        if (
            event.StatusId === getSignUpsStatusId('postponed_todo') ||
      event.StatusId === getSignUpsStatusId('postponed_noa')
        )
            return event.IsAllowedToCancelPostpone;
    };

    const registerReplacement = (event) => {
        dispatch(createEventFromProposal(event, userId, apiToken, selectedDate));
    };

    const onConfirmPresence = (eventId) => { 
        setConfirmPresenceInProgressEventId(eventId);
        dispatch(confirmPresence(eventId))
            .then(()=>{
                setConfirmPresenceInProgressEventId(0);
            })
            .catch((err)=>{
                dispatch(setError(err));
                setConfirmPresenceInProgressEventId(0);
            });
    };

    const renderList = (list) => {
        return (
            <div className="cal-content">
                {list.map((event) => (
                    <CalendarEventContainer
                        id={event.Id}
                        key={event.Id}
                        newDate={event.NewEventDate}
                        time={event.EventHour}
                        dayName={dayAsStringShort(event.NewEventDate)}
                        name={event.EventName}
                        level={event.Level}
                        status={event.Status}
                        statusId={event.StatusId}
                        signupId={event.CustContractEntryId}
                        teachers={event.InstructorsNames}
                        room={event.Room}
                        charge={event.DepositCharge}
                        allowPostpone={event.IsAllowedToPostpone}
                        bgColorClass="cal-event-bg-dark"
                        fee={event.AdditionalFee}
                        nameDetails={
                            event.ServiceName + ' ' + event.Cat1 + ' ' + event.Cat2
                        }
                        // onClickPostpone={() => onClickPostpone(event.Id)}
                    >
                        {event.StatusId === 9?
                            <EventTool
                                title={'Obecność'}
                                id={event.Id}
                                statusId={event.StatusId}
                                onChange={onConfirmPresence}
                                inProgressId={confirmPresenceInProgressEventId}
                            />
                            :
                            <EventTool
                                title={activeTab === 1 ? 'Nieobecność' : 'Zapis'}
                                id={event.Id}
                                allowAction={isActionAllowed(event)}
                                statusId={event.StatusId}
                                toggleChecked={
                                    event.StatusId === getSignUpsStatusId('postponed_todo') ||
                event.StatusId === getSignUpsStatusId('postponed_noa')
                                        ? true
                                        : false
                                }
                                onChange={
                                    event.StatusId === 1
                                        ? () => registerReplacement(event)
                                        : (val, mouseEvent) =>
                                            onChangeEventToolBtn(
                                                event.Id,
                                                val,
                                                event.CustEntryId,
                                                mouseEvent,
                                            )
                                }
                                eventToolDetails={
                                    <EventToolDepositInfo
                                        charge={event.DepositCharge}
                                        newDate={event.NewEventDate}
                                        selectedDate={selectedDate}
                                    />
                                }
                                inProgressId={postponeInProgressEventId}
                            />
                        }
                        {event.AdditionalFee ? (
                            <EventTool
                                title={'Dopłata'}
                                id={event.Id}
                                buttonType="btn"
                                allowAction={true}
                                onChange={() => onAdditionalFeeClick(event)}
                                buttonText={event.AdditionalFee + ' zł'}
                                inProgressId={paymentInProgressEventId}
                            />
                        ) : null}
                        <ButtonWithSpinner key={event.Id} />
                    </CalendarEventContainer>
                ))}
                {list.length === 0 ? <Card title="Brak wydarzeń"></Card> : null}
            </div>
        );
    };

    const loadEvents = () => {
        if (activeTab === 1)
            dispatch(
                getCustomerEvents(userId, getOnlyDateString(selectedDate), apiToken),
            );
        else if (activeTab === 2)
            dispatch(
                getCustomerProposals(userId, getOnlyDateString(selectedDate), apiToken),
            );
    };

    /* eslint-disable */
  useEffect(() => {
    loadEvents()
    return () => {}
  }, [activeTab, selectedDate])

  return (
    <div className="cal-containter">
      <div className="cal-head">
        <CalendarDatePicker />
      </div>
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      ></Tabs>
      {renderList(activeTab === 1 ? events : proposals)}
    </div>
  )
}

export default CalendarDisplay
