import React, { useEffect, useState } from 'react';
import './Filters.css';
import Select from 'react-dropdown-select';

const Filters = (props) => {

    const [typedText, settypedText] = useState('');
    
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {						
            props.onTextTypedIn(typedText);
        }, props.waitForTypingDelay ?? 0);

        return () => clearTimeout(delayDebounceFn);
    }, [typedText]);

    return (
        <div className='filters-container'>
            <div className='filters-bar'></div>
            <div className='filters-content'>
                <div className='filters-content-inline'>
                    <div className='filters-content-filter'>
                        <Select
                            clearable
                            placeholder='Rodzaj'
                            options={props.services}
                            onChange={(values) => props.selectService(values)}
                            handleKeyDownFn={(val) => settypedText(val.state.search)}						
                            onClearAll={props.onDropdownClose}
                            values={props.services.filter(({ value }) => value === props.service)}
                            noDataLabel="Brak danych"
                        />
                    </div>
                    <div className='filters-content-filter'>
                        <Select
                            clearable
                            placeholder='Zajęcia'
                            options={props.eventTypes}
                            onChange={(values) => props.selectEventType(values)}
                            handleKeyDownFn={(val) => settypedText(val.state.search)}						
                            onClearAll={props.onDropdownClose}
                            values={props.eventTypes.filter(({ value }) => value === props.event)}
                            noDataLabel="Brak danych"
                        />
                    </div>
                    
                </div>
                <div className='filters-content-filter'>
                    <Select
                        clearable
                        placeholder='Nauczyciel'
                        options={props.teachers}
                        onChange={(values) => props.selectTeacher(values)}
                        handleKeyDownFn={(val) => settypedText(val.state.search)}						
                        onClearAll={props.onDropdownClose}
                        values={props.eventTypes.filter(({ value }) => value === props.teacher)}
                        noDataLabel="Brak danych"
                    />
                </div>
                <div className='filters-content-inline'>
                    <div className='filters-content-filter'>
                        <Select
                            clearable
                            placeholder='Dzień'
                            options={props.days}
                            onChange={(values) => props.selectDay(values)}
                            values={props.days.filter(({ value }) => value === props.day)}
                            noDataLabel="Brak danych"
                        />
                    </div>

                    <div className='filters-content-filter'>
                        <Select
                            clearable
                            placeholder='Poziom'
                            options={props.levels}
                            onChange={(values) => props.selectLevel(values)}
                            values={props.levels.filter(({ value }) => value === props.level)}
                            noDataLabel="Brak danych"
                        />
                    </div>
                </div>
                <div className='filters-content-inline'>
                    {props.types ?
                        <div className='filters-content-filter'>
                            <Select
                                clearable
                                placeholder='Typ zajęć'
                                options={props.types}
                                onChange={(values) => props.selectType(values)}
                                values={props.types.filter(({ value }) => value === props.type)}
                                noDataLabel="Brak danych"
                            />
                        </div>
                        : null}
                    {props.techniques ?
                        <div className='filters-content-filter'>
                            <Select
                                clearable
                                placeholder='Technika'
                                options={props.techniques}
                                onChange={(values) => props.selectTechnique(values)}
                                values={props.techniques.filter(({ value }) => value === props.technique)}
                                noDataLabel="Brak danych"
                            />
                        </div>
                        :null}
                </div>
            </div>
        </div>
    );
};

export default Filters;
