import React, { useState, Fragment } from 'react';
import { status } from '../Utils';
import './EmailLink.css';
import { setError, setSignedIn } from '../../../store/actions/ui';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { auth } from '../../../firebase/Firebase';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { login } from '../../../store/actions/lrnAPI';
import { setAuthCredential } from '../../../store/actions/customers';
import { sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink, EmailAuthProvider } from 'firebase/auth';

const EmailLink = (props) => {
    const { handleChangeStatus } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const emailVerifiedParam = searchParams.get('emailVerified');
    const emailParam = searchParams.get('email');
    
    const appUrl = useSelector((store) => store.firebaseStore.selectedAPIConfig.appUrl);

    const {
        register,
        formState: { errors },
        getValues,
        handleSubmit,
    } = useForm({
        mode: 'onChange',
    });

    const [emailVerified, setemailVerified] = useState(false);
    const [confirmBtnActive, setconfirmBtnActive] = useState(true);

    const [linkSent, setlinkSent] = useState(false);

    const dispatch = useDispatch();

    //TODO duplicated code as this page can be accessed directly not only via auth
    const getTokenAndContinue = (authResult, credential) => {
        dispatch(setAuthCredential(credential));
        authResult.user
            .getIdToken()
            .then(function (idTokenFromCred) {
                dispatch(setSignedIn(true, false, idTokenFromCred, null));
                dispatch(login(idTokenFromCred));
                //TODO navigate to the originally requested page with url params
                navigate('/');
            })
            .catch((err) => {
                dispatch(setError({message:'Problem z weryfikacją ' + err}));
                handleChangeStatus(status.NONE);
                navigate('/');
            });
    };

    const handleSignIn = () => {      
        if(appUrl){
            setconfirmBtnActive(false);
            if (emailVerifiedParam) {
                signInAfterEmailVerified();
            } else {
                firebaseLogin();
            }
        }
        else{
            dispatch(setError({message:'Problem z pobraniem URL aplikacji'}));
        }
    };

    const getPassThroughSearchParams = () => { 
        let passThroughParams = searchParams.toString();
        return passThroughParams;
    };

    const getActionCodeSettings = () => {
        const email = getValues('email');

        const actionCodeSettings = {
            // URL you want to redirect back to. The domain (www.example.com) for this
            // URL must be whitelisted in the Firebase Console.
            url: appUrl + '/emaillink/?emailVerified=true&email=' + email + '&' + getPassThroughSearchParams(),
            // This must be true.
            handleCodeInApp: true,
            //dynamicLinkDomain: 'example.page.link'
        };
        return actionCodeSettings;
    };
    const firebaseLogin = () => {
        if (getValues('email')) {
            const actionCodes = getActionCodeSettings();
            console.log('first', actionCodes);
            sendSignInLinkToEmail(auth, getValues('email'), actionCodes)
                .then(function () {
                    // The link was successfully sent. Inform the user.
                    // Save the email locally so you don't need to ask the user for it again
                    // if they open the link on the same device.
                    localStorage.setItem('emailForSignIn', getValues('email'));
                    localStorage.setItem('passForSignIn', getValues('password'));
                    setlinkSent(true);
                })
                .catch(function (error) {
                    dispatch(setError(error));
                });
        } else {
            dispatch(setError({ message: 'Coś poszło nie tak, spróbuj ponownie.' }));
        }
    };

    const signInAfterEmailVerified = () => {
        // Confirm the link is a sign-in with email link.
        if (isSignInWithEmailLink(auth, window.location.href)) {
            // Additional state parameters can also be passed via URL.
            // This can be used to continue the user's intended action before triggering
            // the sign-in operation.
            // Get the email if available. This should be available if the user completes
            // the flow on the same device where they started it.
            var email = localStorage.getItem('emailForSignIn');

            if (!email) {
                // User opened the link on a different device. To prevent session fixation
                // attacks, ask the user to provide the associated email again. For example:
                email = emailParam;
            }

            if (email) {
                // The client SDK will parse the code from the link for you.

                signInWithEmailLink(auth, email, window.location.href)
                    .then(function (result) {
                        // Clear email from storage.
                        localStorage.removeItem('emailForSignIn');
                        localStorage.removeItem('passForSignIn');
                        // You can access the new user via result.user
                        // Additional user info profile not available via:
                        // result.additionalUserInfo.profile == null
                        // You can check if the user is new or existing:
                        // result.additionalUserInfo.isNewUser
                        var credential = EmailAuthProvider.credentialWithLink(
                            email,
                            window.location.href
                        );
                        getTokenAndContinue(result, credential);
                        //	firebaseSignUp(email);
                    })
                    .catch(function (error) {
                        // Some error occurred, you can inspect the code: error.code
                        // Common errors could be invalid email and invalid or expired OTPs.
                        dispatch(setError(error));
                    });
            } else {
                navigate('/');
                dispatch(
                    setError({
                        message: 'Wystąpił błąd podczas autoryzacji spróbuj ponownie',
                    })
                );
            }
        }
    };

    //auth via link only
    // const firebaseSignUp = (email) => {
    // 	auth
    // 		.createUserWithEmailAndPassword(email, userLoginInfo.password)
    // 		.catch((error) => {
    // 			dispatch(setError(error));
    // 		})
    // 		.then((result) => {
    // 			if (result) {
    // 				getTokenAndContinue(result);
    // 			}
    // 		});
    // };

    const cancel = () => {
        if (emailVerified) navigate('/');
        else handleChangeStatus(status.NONE);
    };

    /* eslint-disable */
	useEffect(() => {        
		//const currentPath = location.pathname;
		if (emailVerifiedParam) {
			setemailVerified(true);
			handleSignIn();
		} else {
			setemailVerified(false);
		}
		return () => {};
	}, [location]);

	return (
		<div className='login-container'>
			<form onSubmit={handleSubmit(handleSignIn)}>
				{
					!emailVerified ? (
						<div className='form-group'>
							<label>Email</label>
							<input
								name='email'
								type='email'
								className='form-control'
								placeholder='Email'
								onChange={(event) => onChangeHandler(event)}
								readOnly={linkSent}
								{...register('email', {
									required: true,
									/* eslint-disable */
									pattern:
										/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
								})}
							/>
							{errors.email && <p>Wprowadź poprawną wartość</p>}
							{linkSent && (
								<div className='auth-emaillink-info'>
									Na podany adres email wysłano link, użyj go, aby się zalogować
								</div>
							)}
						</div>
					) : null
					//(
					// 	<div className='form-group'>
					// 		<label>Password</label>
					// 		<input
					// 			name='password'
					// 			type='password'
					// 			className='form-control'
					// 			placeholder='Hasło'
					// 			onChange={(event) => onChangeHandler(event)}
					// 			ref={register({
					// 				required: true,
					// 				/* eslint-disable */
					// 				pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/,
					// 			})}
					// 		/>
					// 		{errors.password && (
					// 			<p>Hasło musi zawierać, 5 znaków, duże i małe litery oraz cyfry </p>
					// 		)}
					// 	</div>
					// )
				}

				{!emailVerified ? (
					<Fragment>
						<button
							type='submit'
							className='btn btn-primary btn-block submit-button'
							disabled={!confirmBtnActive}>
							Zaloguj
						</button>
						<button
							className='btn cancel'
							onClick={cancel}>
							Powrót
						</button>
					</Fragment>
				) : null}
			</form>
		</div>
	);
};

export default EmailLink;
