import React from 'react';
import './VersionInfo.css';


const VersionInfo = () => {
    return (
        <div className="version-info-container">Version 1.7.0.0</div>
    );
};

export default VersionInfo;
