// Libs
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Utils
import {
    getPrivacyPolicy,
    getTermsOfUse,
    acceptTermsOfUse,
    acceptPrivacyPolicy,
    declinePrivacyPolicy,
    declineTermsOfUse,
    isTermsOfUseAccepted,
    isPrivacyPolicyAccepted,
    getMailingAgreement,
    acceptMailing,
    declineMailing,
    isMailingAccepted
} from '../utils/agreements';



const useAgreements = () => {

    const [lastTermsOfUseId, setLastTermsOfUseId] = useState(0);
    const [lastTermsOfUse, setLastTermsOfUse] = useState(null);
    const [lastPrivacyPolicyId, setLastPrivacyPolicyId] = useState(0);
    const [lastPrivacyPolicy, setLastPrivacyPolicy] = useState(null);
    const [lastMailingAgreementId, setMailingAgreementId] = useState(0);
    const [lastMailingAgreement, setLastMailingAgreement] = useState(null);
  
    const userId = useSelector((store) => store.customerStore.customerData.id);
    const apiConfigId = useSelector((store) => store.firebaseStore.selectedAPIConfigId);

    const getTermsOfUseData = async () => {
        const { id, agreement } = await getTermsOfUse(apiConfigId);    

        setLastTermsOfUse(agreement);
        setLastTermsOfUseId(id);  
    };

    const getPrivacyPolicyData = async () => {
        const { id, agreement } = await getPrivacyPolicy(apiConfigId);

        setLastPrivacyPolicy(agreement);
        setLastPrivacyPolicyId(id);
    };

    const getMailingAgreementData = async () => {
        const { id, agreement } = await getMailingAgreement(apiConfigId);

        setLastMailingAgreement(agreement);
        setMailingAgreementId(id);
    };

    useEffect(() => {
        getTermsOfUseData();
        getPrivacyPolicyData();
        getMailingAgreementData();
    }, []);

    const onAcceptTermsOfUse = async () => {
        window.open(lastTermsOfUse.url, '_blank');
        await acceptTermsOfUse(userId, lastTermsOfUseId, apiConfigId);
        await getTermsOfUseData();
    };

    const onAcceptPrivacyPolicy = async () => {
        window.open(lastPrivacyPolicy.url, '_blank');
        await acceptPrivacyPolicy(userId, lastPrivacyPolicyId, apiConfigId);
        await getPrivacyPolicyData();
    };

    

    const onDeclinePrivacyPolicy = async () => {
        await declinePrivacyPolicy(userId, lastPrivacyPolicyId, apiConfigId);
        await getPrivacyPolicyData();
    };

    const onDeclineTermsOfUse = async () => {
        await declineTermsOfUse(userId, lastTermsOfUseId, apiConfigId);
        await getTermsOfUseData();
    };


    const onAcceptMailing = async () => {        
        await acceptMailing(userId, lastMailingAgreementId, apiConfigId);
        await getMailingAgreementData();
    };
    const onDeclineMailing = async () => {
        await declineMailing(userId, lastMailingAgreementId, apiConfigId);
        await getMailingAgreementData();
    };

    const termsOfUseAccepted = async () => {
        return isTermsOfUseAccepted(userId, apiConfigId);
    };
    const privacyPolicyAccepted = async () => {
        return isPrivacyPolicyAccepted(userId, apiConfigId);
    };
    const mailingAgreementAccepted = async () => {
        return isMailingAccepted(userId, apiConfigId);
    };
  
  
    return {
        isTermsOfUseAccepted: termsOfUseAccepted,
        isPrivacyPolicyAccepted: privacyPolicyAccepted,        
        onAcceptTermsOfUse,
        onAcceptPrivacyPolicy,
        onDeclinePrivacyPolicy,
        onDeclineTermsOfUse,
        termsOfUse: lastTermsOfUse,
        privacyPolicy: lastPrivacyPolicy,        
        isMailingAccepted: mailingAgreementAccepted,
        onAcceptMailing,
        onDeclineMailing,
        mailingAgreement: lastMailingAgreement
    };
};

export default useAgreements;
