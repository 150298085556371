class CreatePaymentRequest {
    constructor(
        Amount,        
        Comment,
        CustContractEntryId,
        ScheduleId,
        CustContractSalesEntryId,
        CustEventId,
        CreateCharge,
        SettleId,
        ExistingPaymentId,
        CardToken

    ) {
        this.Amount = Amount;        
        this.Comment = Comment;
        this.CustContractEntryId = CustContractEntryId;
        this.ScheduleId = ScheduleId;
        this.CustContractSalesEntryId = CustContractSalesEntryId;
        this.CustEventId = CustEventId;
        this.CreateCharge = CreateCharge;
        this.SettleId = SettleId;
        this.ExistingPaymentId = ExistingPaymentId;
        this.CardToken = CardToken;
    }

    obj() {
        const obj = {
            Amount: this.Amount,            
            Comment: this.Comment,
            CustContractEntryId: this.CustContractEntryId,
            ScheduleId: this.ScheduleId,
            CustContractSalesEntryId: this.CustContractSalesEntryId,
            CustEventId: this.CustEventId,
            CreateCharge: this.CreateCharge,
            SettleId: this.SettleId,
            ExistingPaymentId: this.ExistingPaymentId,
            CardToken: this.CardToken
        };
        return obj;
    }
}
export default CreatePaymentRequest;
