
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { setError, setMessage, setWarning } from '../store/actions/ui';
import { updatePaymentStatus } from '../store/lrnAPI/custEntries';

const useNewSchedulePayUResponse = () => {
  
    const dispatch = useDispatch();

    const [searchParams] = useSearchParams();
    const [statusCode] = useState(searchParams.get('statusCode'));
    const [refReqId] = useState(searchParams.get('refReqId'));
    const [custEntryPaymentId] = useState(searchParams.get('custEntryPaymentId'));

    useEffect(() => {
        if(statusCode)
        {
            switch (statusCode) {
            case 'SUCCESS':
                dispatch(updatePaymentStatus(custEntryPaymentId, statusCode,refReqId))
                    .then((resp)=>{
                        if(resp.status === 200)
                            dispatch(setMessage({message:'Aktualizacja statusu płatności przebiegła pomyślnie'}));
                        else
                            dispatch(setWarning('Napotkaliśmy trudności z aktualizacją statusu płatności.'));
                    })
                    .catch((err)=>{
                        dispatch(setError(err));
                    });
                break;
            case 'WARNING_CONTINUE_CVV':

                break;
            
            default:
                
                break;
            }
        }
    
    }, []);
    


    return {result:null};
};

export default useNewSchedulePayUResponse;