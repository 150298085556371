import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../components/UI/Modal/Modal';
import ToggleButton from '../../../components/UI/ToggleButton/ToggleButton';
import {
    facebookLogin,
    getNameAndUpdateInDB,
} from '../../../store/actions/facebook';
import {
    changeCustUserProfileSetting,
    customerDataSetFacebookName,
    loadCustUserProfile,
} from '../../../store/actions/lrnAPI';
import { setError, setMessage } from '../../../store/actions/ui';
import './SocialUserProfile.css';
import { analytics } from '../../../firebase/Firebase';
import { setCustDetails } from '../../../store/actions/customers';
import { logEvent } from 'firebase/analytics';
import useAgreements from '../../../hooks/useAgreements';
import { Spinner } from 'react-bootstrap';



const SocialUserProfile = () => {
    //hooks	
    const dispatch = useDispatch();
    const {
        isTermsOfUseAccepted,
        isPrivacyPolicyAccepted,
        onAcceptTermsOfUse,
        onAcceptPrivacyPolicy,
        onDeclinePrivacyPolicy,
        onDeclineTermsOfUse,
        termsOfUse,
        privacyPolicy,
        isMailingAccepted,
        onAcceptMailing,
        onDeclineMailing,
        mailingAgreement
    } = useAgreements();

    const apiToken = useSelector((store) => store.uiStore.apiToken);
    const userId = useSelector((store) => store.customerStore.customerData.id);
    const userProfile = useSelector((store) => store.customerStore.userProfile);
    const custDetails = useSelector(
        (state) => state.customerStore.customerDetails
    );
    const facebookLoggedIn = useSelector((store) => store.facebookStore.loggedIn);

    const [showFBModal, setshowFBModal] = useState(false);
    const [agreementsLoaded, setAgreementsLoaded] = useState(false);
    const [consentFields, setConsentFields] = useState([]);

    //Variables
    const fields = [
        {
            id: 'username',
            label: 'Nazwa użytk.',
            data: userProfile.ShareUserName,
        },
        { id: 'firstname', label: 'Imię', data: userProfile.ShareFirstName },
        { id: 'lastname', label: 'Nazwisko', data: userProfile.ShareLastName },
        {
            id: 'socialscores',
            label: 'Punkty',
            data: userProfile.ShareSocialScores,
        },
        {
            id: 'achievements',
            label: 'Osiągnięcia',
            data: userProfile.ShareAchievements,
        },
        {
            id: 'pictures',
            label: 'Zdjęcia',
            data: userProfile.SharePictures,
        },
        {
            id: 'facebook',
            label: 'Facebook',
            data: userProfile.ShareFacebookInfo,
            comment: 'Imię, Nazwisko, Nazwa, Email, zdjęcie profilowe',
        }
    ];

    //data loading
    const loadData = () => {
        dispatch(loadCustUserProfile(userId, apiToken));
    };

    const getFacebookNameFromAPI = () => {
        dispatch(getNameAndUpdateInDB(apiToken, userId))
            .then((res) => {
                dispatch(changeCustUserProfileSetting(userId, apiToken, 'facebook'));
                const custDetailsNew = { ...custDetails, FacebookName: res };
                dispatch(setCustDetails(custDetailsNew));
            })
            .catch(() => { });
    };

    const loadAgreements = () => {
        if (termsOfUse !== null 
            && privacyPolicy !== null
            && mailingAgreement !== null) {
            setAgreementsLoaded(true);
        }
    };

    const loadConsents = async () => {
        let fields = [];
        if (agreementsLoaded) {
            await isTermsOfUseAccepted().then(res => {
                fields.push(
                    {
                        id: 'terms', label: 'Regulamin', data: res, comment: termsOfUse?.ver,
                        onChangeFuncs: { onAccept: onAcceptTermsOfUse, onDecline: onDeclineTermsOfUse }
                    });

            });
            await isPrivacyPolicyAccepted().then(res => {
                fields.push(
                    {
                        id: 'policy', label: 'Polityka Prywatności', data: res, comment: privacyPolicy?.ver,
                        onChangeFuncs: { onAccept: onAcceptPrivacyPolicy, onDecline: onDeclinePrivacyPolicy }
                    });
            });
            await isMailingAccepted().then(res => {
                fields.push(
                    {
                        id: 'mailing', label: 'Korespondencja email', data: res, comment: '',
                        onChangeFuncs: { onAccept: onAcceptMailing, onDecline: onDeclineMailing }
                    });
            });
            return fields;
        }
        return fields;
    };

    //On change functions
    const onSettingChange = (field) => {
        if (!handleFacebookChange(field)) {
            dispatch(changeCustUserProfileSetting(userId, apiToken, field));
        }
    };

    const handleFacebookChange = (field) => {
        if (field === 'facebook') {
            if (userProfile.ShareFacebookInfo === false) {
                if (!facebookLoggedIn) {
                    setshowFBModal(true);
                } else {
                    getFacebookNameFromAPI();
                }
            } else {
                dispatch(customerDataSetFacebookName('', apiToken, userId)).then(() => {
                    const custDetailsNew = { ...custDetails, FacebookName: '' };
                    dispatch(setCustDetails(custDetailsNew));
                    dispatch(
                        setMessage({
                            message: 'Usunęliśmy Twoje dane z Facebook w naszym systemie.',
                        })
                    );
                    dispatch(changeCustUserProfileSetting(userId, apiToken, field));
                });
            }
            return true;
        }
        return false;
    };


    //Business logic
    const fbForwardLoginResponse = (ok) => {
        if (ok) {
            logEvent(analytics, 'login', { method: 'Facebook', value: 'accepted' });
            dispatch(facebookLogin())
                .then(() => {
                    getFacebookNameFromAPI();
                })
                .catch(() => {
                    logEvent(analytics, 'login', {
                        method: 'Facebook',
                        value: 'no_permissions',
                    });
                    dispatch(
                        setError({
                            message: 'Brak uprawnień do pobierania danych z Facebook.',
                        })
                    );
                });
        } else {
            logEvent(analytics, 'login', { method: 'Facebook', value: 'declined' });
        }
        setshowFBModal(false);
    };


    //UI renders
    const option = (id, data, label, comment, onChangeFunc) => {
        return <div key={id} className='social-userprofile-option'>
            <div className='social-userprofile-option-button'>
                <ToggleButton
                    id={id}
                    key={id}
                    checked={data}
                    onChange={onChangeFunc}
                    width={45}
                    height={20}
                    handleDiameter={10}
                    comment={comment}
                />
            </div>
            <div className='social-userprofile-option-label'>
                <label>{label}</label>
                <div className='app-text-title-sub'>{comment}</div>
            </div>
        </div>;
    };
    const renderOptions = () => {
        let options = [];
        if (userProfile.length === 0) return [];
        fields.map((o) =>
            options.push(
                option(o.id, o.data, o.label, o.comment, () => onSettingChange(o.id))
            )
        );
        return options;
    };

    const renderOptionsAgreements = () => {
        let options = [];        
        consentFields.map((o) =>
            options.push(
                option(o.id, o.data, o.label, o.comment, o.data ? o.onChangeFuncs.onDecline : o.onChangeFuncs.onAccept)
            )
        );

        return options;
    };

    //UI control

    /* eslint-disable */
	useEffect(() => {
		loadData();
	}, []);

	useEffect(() => {
		loadAgreements();
	}, [termsOfUse, privacyPolicy, mailingAgreement])

	useEffect(() => {
		loadConsents()
			.then(res => {                
				setConsentFields(res);
			})
	}, [agreementsLoaded,termsOfUse,privacyPolicy,mailingAgreement])


	return (
		<div className='social-userprofile-container'>
			<Modal
				show={showFBModal}
				message='W celu nadania uprawnień system przeniesie Cię na chwilę do Facebook'>
				<button
					className='confirm'
					onClick={() => fbForwardLoginResponse(true)}>
					Ok
				</button>
				<button
					className='cancel'
					onClick={() => fbForwardLoginResponse(false)}>
					Anuluj
				</button>
			</Modal>
			<div className='social-userprofile-title'>Udostępnij</div>
			<div className='app-text-title-sub'>
				*Poniższe dane będą widoczne dla użytkowników, których zaakceptujesz
				jako swoich znajomych.
			</div>
			<div className='social-userprofile-options'>
				<form id='social-userprofile-options'>{renderOptions()}</form>
			</div>
			<div className='social-userprofile-title'>Zgody</div>
			<div className='social-userprofile-options'>
				{consentFields && consentFields.length > 0 ? renderOptionsAgreements()
					: <Spinner
                        id='social-userprofile-agr-options-spinner'
						animation='border'
						role='status'
						size='sm'
					/>}
			</div>
		</div>
	);
};

export default SocialUserProfile;
