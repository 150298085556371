import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Items.css';

import { getShortDate, getShortDateDMY, dayAsStringShort } from '../../../store/actions/calendar';
import { getSignupQuantityText } from '../helpers/categoryItemsCalcs';

import NewSchedule from '../../NewSchedule/NewSchedule';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import MySignupsItem from '../../MySignupsItem/MySignupsItem';
import MySignupsItemDetails from '../../MySignupsItem/MySignupsItemDetails/MySignupsItemDetails';

const Items = (props) => {
    const navigate = useNavigate();
    const signupsList = () => {
        const list = [];		
        props.items.map((item) =>
            list.push(
                <MySignupsItem
                    onClick={() => navigate(`/myevents/${item.Id}`)}
                    id={'nySignupItem'+item.Id}
                    key={'nySignupItem'+item.Id}
                    validTill={item.ValidTill}
                    time={item.EventHour}
                    name={item.EventName}
                    nameDetails={item.EventDetails}
                    dayName={dayAsStringShort(item.DayId)}
                    level={item.Level}
                    status={item.Status}
                    statusId={item.StatusId}
                    teachers={item.InstructorsNames}                    
                    room={item.Room}
                    paymentStatus={item.paymentStatus}
                    bgColorClass='cal-event-bg-dark'
                    details={
                        <MySignupsItemDetails
                            dateText={getShortDateDMY(item.ValidTill)}							
                            paymentText={getSignupQuantityText(item)}
                            paymentTypeText={ item.PaymentType}
                        />}
                    startDate={getShortDate(item.StartDate)}
                    endDate={getShortDate(item.EndDate)}
                ></MySignupsItem>
            )
        );
        return list;
    };

    const newSchedulesList = () => {	
        const list = [];	
        props.items.map((item) =>
            list.push(
                <NewSchedule
                    id={'newScheduleItem'+item.Id}
                    key={'newScheduleItem'+item.Id}
                    name={item.EventName}
                    nameDetails={item.EventDetails}
                    time={item.EventHour}
                    dayName={dayAsStringShort(item.DayId)}
                    room=''
                    desc={item.Description}
                    levelId={item.LevelId}
                    level={item.Level}					
                    startDate={getShortDate(item.StartDate)}
                    endDate={getShortDate(item.EndDate)}
                    statusId={item.StatusId}
                    teachers={item.Teacher1 + ' & '+ item.Teacher2}										
                    bgColorClass='gray-background'
                    onClick={()=>props.onClick(item.Id)}	
                    actionTool={
                        <div><FontAwesomeIcon icon={faShoppingCart} />
                        ZAKUP
                        </div>}				
                ></NewSchedule>
            )
        );
        return list;
    };

    return (
        <div className='catlist-item-container'>
            <div id={'catlist-items-'+props.id } className='catlist-items'>
                {/* //TO DO it looks like this is rendered twice */}
                {props.listType === 'signups' ? signupsList(): props.listType === 'newSchedules' ? newSchedulesList() : null}
            </div>
        </div>
    );
};

export default Items;
