import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { customerContactsInviteResponse, customerContactsInviteSend } from '../../../../../store/actions/lrnAPI';
import './SocialSearchResult.css';

const SocialSearchResult = (props) => {
    const dispatch = useDispatch();
    const apiToken = useSelector((store) => store.uiStore.apiToken);
    const userId = useSelector((store) => store.customerStore.customerData.id);

    const [buttonType, setbuttonType] = useState(0);

    const sentInviteClick = () => {
        dispatch(customerContactsInviteSend(userId, apiToken, props.data.Id));
        setbuttonType(1);
    };

    const sentInviteChangeDeclinedClick = () => {
        dispatch(customerContactsInviteResponse(userId, apiToken, props.data.RelationshipId, true));
        setbuttonType(2);
    };
    const renderButton = () => {
        switch (buttonType) {
        case 0:
            return (
                <button onClick={sentInviteClick} className='main'>
						Wyślij zaproszenie
                </button>
            );
        case 1:
            return <button className='cancel'>Oczekuje</button>;
        case 2:
            return <button className='cancel'>Kontakt</button>;
        case 3:
            if (props.data.SocialRelationshipReceived) {
                return (
                    <button onClick={sentInviteChangeDeclinedClick} className='main'>
							Zaakceptuj
                    </button>
                );
            } else {
                return <button className='cancel'>Zaproszenie odrzucone</button>;
            }
        default:
            break;
        }
    };

    /* eslint-disable */
	useEffect(() => {
		setbuttonType(props.data.SocialRelationshipStatus);
		return () => {};
	}, []);

	return (
		<div key={props.data.Id} className='socialsearch-result-container'>
			<div className='socialsearch-result-text'>
				{props.data.FirstName +
					' ' +
					(props.data.UserName ? '(' + props.data.UserName + ')' : '')}
			</div>
			<div className='socialsearch-result-actions'>{renderButton()}</div>
		</div>
	);
};

export default SocialSearchResult;
