import React from 'react';
import AccountDetails from '../../components/AccountDetails/AccountDetails';
import ActiveMenuMonitor from '../../components/Nav/ActiveMenuMonitor/ActiveMenuMonitor';
import './AccountPage.css';

const AccountPage = () => {
   
    return (
        <div className='account-page-container'>
            <ActiveMenuMonitor menuId={6}/>
            <AccountDetails/>
        </div>
    );
};

export default AccountPage;
